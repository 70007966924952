import { Location, isPlatformServer } from "@angular/common";
import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    Input,
} from "@angular/core";
import {
    ActivatedRoute,
    ActivationEnd,
    NavigationEnd,
    Router,
} from "@angular/router";
import { Geolocation } from "@capacitor/geolocation";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import {
    NativeGeocoder,
    NativeGeocoderOptions,
    NativeGeocoderResult,
} from "@ionic-native/native-geocoder/ngx";
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { isSupported } from "firebase/analytics";
import { Subscription } from "rxjs";
import { buffer, filter, map, pluck } from "rxjs/operators";
import { CartModalPage } from "src/app/pages/cart-modal/cart-modal.page";
import { CustomFirebaseAnalyticsProvider } from "src/app/services/CustomFirebaseAnalyticsProvider";
import { GetLatLongService } from "src/app/services/get-lat-long";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { environment } from "../../../environments/environment";
import { LoginPage } from "../../pages/login/login.page";
import { AuthenticationService } from "../../services/authentication.service";
import { CartService } from "../../services/cart.service";
import { CommonService } from "../../services/common.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";
import { MenusComponent } from "../menus/menus.component";
import { URLS } from "./../../_config/api.config";
import { ApiService } from "./../../services/api-data.service";
import { TranslateConfigService } from "./../../services/translate-config.service";

declare var google;
declare var navigator;

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    @HostListener('window: popstate', ['$event'])
    onPopState(event: Event): void {
        event.preventDefault();
        this.isMenuVisible = false;
    }
    @ViewChild("cartElement", { static: false }) cartElement: ElementRef;
    currentLocation: any = "Select Location";
    supplierUrl = environment.supplierUrl;
    currentLocation_ar: any = "Select Location";
    isMenuVisible = false;
    breadCrumbTitle: any;
    breadCrumbPath: any;
    isMyAccount = false;
    isBreadCrumbLoading = false;
    isLoggedIn: boolean = false;
    isInfoDropdownOpen: boolean = false;
    breadcrumbsArray = [];
    notificationList: any = [];
    userName: any;
    routeData: any;
    routeUrl: any;
    product_url: any;
    category_id: any;
    toastShow = false;
    isLocationLoading = false;
    @Input() catName: any;
    @Input() prodName: any;
    @Input() catUrlName: any;
    @Input() prodUrlName: any;
    @ViewChild("child") private child: MenusComponent;
    @ViewChild("popover") popover;

    selectedLanguage: any;
    languageArray: any = [
        { code: "en", title: "English", dir: "ltr" },
        { code: "ar", title: "arabic", dir: "rtl" },
    ];

    inerfaceOption = { cssClass: "language-dropdown" };
    private subscription: Subscription = new Subscription();
    public isLoading = false;
    public isBreadcrumbLoading = false;
    public isShippingAddress = false;
    public menuIconShow = false;
    public isHomePage = false;
    currentLang = this.translate.currentLang;
    constructor(
        public getPlatformService: GetPlatformService,
        private nativeGeocoder: NativeGeocoder,
        private modalService: ModalService,
        private translateConfigService: TranslateConfigService,
        public translate: TranslateService,
        private router: Router,
        private toastService: ToastService,
        public commonService: CommonService,
        private navCtrl: NavController,
        private authService: AuthenticationService,
        private alertController: AlertController,
        private route: ActivatedRoute,
        private dataService: ApiService,
        public cartService: CartService,
        public location: Location,
        public CustomFirebaseAnalytics: CustomFirebaseAnalyticsProvider,
        public firebaseAnalytics: FirebaseAnalytics,
        @Inject(PLATFORM_ID) private platformId: Object,
        public getLatLongService: GetLatLongService
    ) {
        this.subscription = this.translateConfigService.selectedLocation.subscribe(
            (data) => {
                let selectedLanguage = this.translateConfigService.getDefaultLanguage();
                for (let i = 0; i < this.languageArray.length; i++) {
                    if (selectedLanguage == this.languageArray[i].code) {
                        this.selectedLanguage = this.languageArray[i];
                    }
                }
            }
        );

        this.commonService.eventObservable.subscribe((data) => {
            if (data.event == "profileData") {
                this.getCustomerDetailsAPI();
                setTimeout(() => {
                    const token = this.commonService.localStorageGet('accessToken');
                    if (token) {
                        this.cartService.getCart().then(data => { }).catch(err => console.log(err, " getCart"));
                    }
                }, 1000);
            }
            if (data.event == "updateProfile") {
                this.getCustomerDetailsAPI();
            }
            if (data.event == "detailsUpdate") {
                this.getCustomerDetailsAPI();
            }

            if (data.event == "logoutCall") {
                this.userName = null;
                this.myLocation();

            }
        });
        this.route.params.subscribe(params => {
            this.breadCrumb();
          });
    }

    ngOnInit() {
        this.routeUrl = this.router.url;
        if(this.routeUrl == '/en/home' || this.routeUrl =='/en' || this.routeUrl == '/ar/home' || this.routeUrl == '/ar'){
            this.isHomePage = true;
        }
        if (
            this.routeUrl.includes("product-listing") &&
            this.routeUrl.includes("product-detail")
        ) {
            this.routeData = this.route.snapshot.params;
            this.product_url = this.commonService.localStorageGet("product_url");
            this.category_id = +this.commonService.localStorageGet("category_id");
        }

        if (this.routeUrl.includes("account-details")) {
            this.menuIconShow = true;
        }

        setTimeout(() => {
            const token = this.commonService.localStorageGet("accessToken");

            if (token) {
                this.cartService.isloggedIn = true;
                this.getNotificationsCategory();
                this.getCustomerDetailsAPI();
                this.cartService.getCart().then(data => { }).catch(err => console.log(err, " getCart"));
            } else {
                this.cartService.isloggedIn = false;
                let selectedLanguage = this.translateConfigService.getDefaultLanguage();
                for (let i = 0; i < this.languageArray.length; i++) {
                    if (selectedLanguage == this.languageArray[i].code) {
                        this.selectedLanguage = this.languageArray[i];
                    }
                }
                this.myLocation();
            }
        }, 1000);

    }



    // reloadPage() {
    //     let url = this.router.url;
    //     let urlParts = url.split("/");
    //     // Replace the language segment with a new value
    //     urlParts[1] = this.commonService.localStorageGet("locale"); // Change "ar" to "en"
    //     let newUrl = urlParts.join("/");
    //     console.log(newUrl);
    //     // pls refer this if u want to replace the router
    //     // this.router.navigate([newUrl], {replaceUrl:true});

    //     this.navCtrl
    //         .navigateForward("/", { skipLocationChange: true, animated: false })
    //         .then(() => {
    //             this.navCtrl
    //                 .navigateForward([`/${newUrl}`], { animated: false })
    //                 .then(() => { });
    //         });
    // }

    getCustomerDetailsAPI() {
        this.isLoading = true;
        this.isShippingAddress = false;
        this.subscription.add(
            this.dataService.get(URLS.getCustomerDetails).subscribe(
                (data) => {
                    if (data["code"] == 200) {
                        this.isLoading = false;
                        if (data["data"]) {
                            this.commonService.localStorageSet(
                                "userInfo",
                                JSON.stringify(data["data"])
                            );
                            isSupported().then(() => {
                                this.firebaseAnalytics.setUserId(data["data"]["customer_id"]); //Google Analytics User ID event
                                this.CustomFirebaseAnalytics.trackEvent("UserName", data['data']['customer_name']);
                                this.CustomFirebaseAnalytics.trackEvent("LoginUser",
                                    JSON.stringify({
                                        UserId: data["data"]["customer_id"],
                                        UserEmail: data['data']['customer_email'],
                                        UserName: data['data']['customer_name'],
                                    }));
                            })
                            this.commonService.localStorageSet(
                                "locale",
                                data["data"]["language"].toLowerCase()
                            );
                            let selectedLanguage =
                                this.translateConfigService.getDefaultLanguage();
                            for (let i = 0; i < this.languageArray.length; i++) {
                                if (selectedLanguage == this.languageArray[i].code) {
                                    this.selectedLanguage = this.languageArray[i];
                                }
                            }
                        }
                        this.userName = data["data"]["customer_name"];
                        if (data["data"]["default_shipping_address"]) {
                            this.isShippingAddress = true;
                            let address = data["data"]["default_shipping_address"];
                            this.currentLocation = address.city_en + ", " + address.region_en;

                            this.currentLocation_ar =
                                address.city_ar + ", " + address.region_ar;
                            this.getLatLongService.getLatLongLocation(
                                address.city_en,
                                address.address_id,
                                true
                            );
                        } else if (data["data"]["city_id"]) {
                            this.isShippingAddress = false;
                            this.currentLocation =
                                data["data"].city_en + ", " + data["data"].region_en;
                            this.currentLocation_ar =
                                data["data"].city_ar + ", " + data["data"].region_ar;
                            this.getLatLongService.getLatLongLocation(
                                data["data"].city_en,
                                data["data"]["city_id"]
                            );
                        } else {
                            this.isShippingAddress = false;
                            this.myLocation();
                        }
                    } else {
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.isLoading = false;
                }
            )
        );
    }

    getLocation() {
        if (this.toastShow == true) {
            return;
        }
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: "geolocation" }).then((result) => {
                if (result.state === "denied") {
                    this.toastService.displayToast("Please enable location from browser settings or browser", null, "danger");
                    this.toastShow = true;
                    setTimeout(() => { this.toastShow = false }, 3000)
                } else {
                    this.myLocation(true);
                }
                result.addEventListener("change", () => {
                });
            });
        }
    }

    navigatetoChangeLocation() {

        if (this.isShippingAddress) {
            this.router.navigate(['/' + this.currentLang + "/home/account-details/my-addresses"]);
        } else {
            this.router.navigate(['/' + this.currentLang + "/home/account-details/setting"]);
        }
    }

    async goToProductListing() {
        await this.navCtrl.navigateBack(
            `/${this.currentLang}/product-listing/${this.routeData.category}`,
            {
                animated: false,
                state: {
                    breadcrumb: this.routeData.category,
                    url: `/${this.currentLang}/product-listing/${this.routeData.category}`,
                    id: this.category_id,
                    "ar_label": this.routeData.category
                },
            }
        );
    }

    outsideClick() {
        this.isMenuVisible = false;
    }

    async languageChanged(event) {
        await this.translateConfigService.setLanguage(this.selectedLanguage.code);
        await this.translateConfigService.setDir(this.selectedLanguage.dir);
        this.commonService.localStorageSet("locale", this.selectedLanguage.code);
        if (this.cartService.isloggedIn) {
            let selectedLanguage = this.commonService.localStorageGet("locale");
            const params = {
                language: selectedLanguage.toUpperCase(),
            };
            await this.subscription.add(
                this.dataService.post(URLS.languageChange, params).subscribe(
                    (data) => {
                        if (data["code"] == 200) {
                            if (this.cartService.isloggedIn) {
                                this.translateConfigService.locationChange();
                            }
                        } else {
                            this.toastService.displayToast(data["message"], null, "danger");
                        }
                    },
                    (error) => {
                        this.toastService.displayToast(error["message"], null, "danger");
                    }
                )
            );
        }

        const currentUrl = this.router.url.split('?')[0];
        const queryParams = { ...this.route.snapshot.queryParams };

        const urlSegments = currentUrl.split('/');

        urlSegments[1] = this.commonService.localStorageGet("locale");
        const newUrl = urlSegments.join('/');

        let decodedUrl = decodeURIComponent(newUrl);
        let updatedUrl = decodedUrl.trim().replace(/(?<=\/)[^a-zA-Z0-9-]|[^a-zA-Z0-9-/]|['™®©()\[\]]/g, '-').replace(/\s+/g, '-').replace(/-+/g, '-');

        this.router.navigate([updatedUrl], { queryParams, replaceUrl: true });
    }

    openModal(e) {
        const token = this.commonService.localStorageGet("accessToken");

        if (!this.getPlatformService.isDesktop) {
            this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/mobile-menu", {
                animated: false,
            });
        } else {
            if (token) {
                e.preventDefault();
                e.stopPropagation();
                this.isLoggedIn = true;
                this.isInfoDropdownOpen = !this.isInfoDropdownOpen;
            } else {
                this.isLoggedIn = false;
                let options: any = {
                    id: "loginModal",
                    backdropDismiss: false,
                };
                options["component"] = LoginPage;
                this.modalService.openModal(options);
            }
        }
    }

    openCartModal() {
        const token = this.commonService.localStorageGet("accessToken");
        if (token) {
            this.isLoggedIn = true;
            let options: any = {
                id: "cartModal",
                componentProps: { cartData: this.cartService.cartList },
            };
            options["component"] = CartModalPage;
            options["cssClass"] = "cart-Modal";
            options["showBackdrop"] = false;
            this.modalService.openModal(options, null, null, this.cartElement);
        } else {
            this.isLoggedIn = false;
            let options: any = {
                id: "loginModal",
                backdropDismiss: false,
            };
            options["component"] = LoginPage;
            this.modalService.openModal(options);
        }
    }

    async myLocation(isLocationClicked?) {
        if (isLocationClicked) {
            this.isLocationLoading = true;
        }
        const coordinates: any = await Geolocation.getCurrentPosition();
        let latitude = coordinates.coords.latitude;
        let longitude = coordinates.coords.longitude;
        let options: NativeGeocoderOptions = {
            useLocale: true,
            maxResults: 5,
        };

        this.nativeGeocoder
            .reverseGeocode(latitude, longitude, options)
            .then((result: NativeGeocoderResult[]) => {
                if (result[0]) {
                    let results: any = result[0];
                    let sublocality = results.subLocality
                        ? results.subLocality + ","
                        : "";
                    let locality = results.locality ? results.locality : "";
                    this.currentLocation = sublocality + locality;
                    this.currentLocation_ar = sublocality + locality;
                    setTimeout(() => {
                        this.isLocationLoading = false;
                    }, 1000);
                }
            })
            .catch((error: any) => {
                this.getGeoLocation(latitude, longitude, "reverseGeocode");
                setTimeout(() => {
                    this.isLocationLoading = false;
                }, 1000);
            });
    }

    // toggle menu visibility
    toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible;
        // this.child.notifyMe();
    }

    goToHome() {
        this.commonService.setEvent("emptySerach", null);
        this.navCtrl.navigateRoot('/' + this.currentLang + "/home", { animated: false });
    }

    async getGeoLocation(lat: number, lng: number, type?) {
        if (!isPlatformServer(this.platformId)) {
            if (navigator.geolocation) {
                let geocoder = await new google.maps.Geocoder();
                let latlng = await new google.maps.LatLng(lat, lng);

                let request = { latLng: latlng };
                await geocoder.geocode(request, (results, status) => {
                    if (results && results[0]) {
                        if (
                            results[0]["address_components"] &&
                            results[0]["address_components"].length
                        ) {
                            let location = results[0]["address_components"];
                            let state: any;
                            let city: any;
                            location.map((addr) => {
                                if (addr["types"].includes("administrative_area_level_1")) {
                                    state = addr["short_name"];
                                }
                                if (addr["types"].includes("administrative_area_level_3")) {
                                    city = addr["long_name"];
                                }
                            });
                            this.currentLocation = city + "," + state;
                            this.currentLocation_ar = city + "," + state;
                        }
                    }
                });
            }
        }
    }

    /////// Code for Breadcrumb Title and Nevigation Path //////////////
    breadCrumb() {
        this.isBreadCrumbLoading = true;
        this.isMyAccount = false;
        this.router.navigated;
        const navigationEnd = this.router.events.pipe(
            filter((val) => {
                if (val instanceof NavigationEnd) return true;
            })
        );
        this.router.events
            .pipe(
                filter((val) => {
                    if (val instanceof ActivationEnd) return true;
                }),
                pluck("snapshot"),
                pluck("data"),
                buffer(navigationEnd),
                map((data: any[]) => {
                    return data.reverse();
                })
            )
            .subscribe((val) => {
                let breadcrumbs = [];
                this.breadcrumbsArray = [];
                let data = val;
                if (this.router.getCurrentNavigation().extras.state) {
                    data.push(this.router.getCurrentNavigation().extras.state);
                    this.commonService.localStorageSet(
                        "breadcrumb",
                        JSON.stringify(this.router.getCurrentNavigation().extras.state)
                    );
                } else {
                    if (this.commonService.localStorageGet("breadcrumb")) {
                        let breadcrumbData = JSON.parse(
                            this.commonService.localStorageGet("breadcrumb")
                        );

                        let url: any = this.router.url;
                        if (this.router.url.includes("%")) {
                            url = url.replaceAll("%20", " ");
                        }
                        if (breadcrumbData.url == url) {
                            data.push(breadcrumbData);
                        }
                    }
                }
                data.map((el) => {
                    if (el && el["breadcrumb"]) {
                        if (!breadcrumbs.includes(el["breadcrumb"])) {
                            breadcrumbs.push(el["breadcrumb"]);
                            this.breadcrumbsArray.push(el);
                        }
                    }
                    this.breadcrumbsArray = [...this.breadcrumbsArray];
                });
                setTimeout(() => {
                    this.route.paramMap.subscribe(params => {
                        let product_url = this.commonService.localStorageGet("product_url");
                        if (!this.prodName && this.catName) {
                            const isBreadCrumbPresent = this.breadcrumbsArray.some(obj => obj.breadcrumb == this.catName);
                            if (!isBreadCrumbPresent) {
                                let obj = {
                                    breadcrumb: this.catName,
                                    title: this.breadcrumbsArray[this.breadcrumbsArray.length - 1].title,
                                    url: product_url
                                }
                                if (this.commonService.domDirectionLTR) {
                                    this.breadcrumbsArray.push(obj);
                                } else {
                                    this.breadcrumbsArray.forEach((element, index) => {
                                        if (element.id == params.get("id")) {
                                            this.breadcrumbsArray.splice(index, 1);
                                        }
                                    });
                                    this.breadcrumbsArray.push(obj);
                                }
                            }
                        }
                        if (this.prodName && this.catName) {
                            let product_id = +params.get("id");
                            let category_id = +this.commonService.localStorageGet("category_id")
                            this.breadcrumbsArray = this.breadcrumbsArray.splice(0, 1);
                            if (this.catName) {
                                let obj = {
                                    breadcrumb: this.catName,
                                    url: `/${this.currentLang}/product-listing/${this.catUrlName.replace(/ /g, "-")}/${+category_id}`
                                }

                                this.breadcrumbsArray.push(obj);
                            }
                            if (this.prodName) {
                                let obj = {
                                    breadcrumb: this.prodName,
                                    url: `${this.catName.replace(/ /g, "-")}/${this.prodUrlName.replace(/ /g, "-")}/${product_id}`
                                }
                                this.breadcrumbsArray.push(obj);
                            }

                        }
                    });
                    const uniqueKeys = {};
                    const updatedArray = this.breadcrumbsArray.filter(obj => {
                        if (!uniqueKeys[obj.url]) {
                            uniqueKeys[obj.url] = true;
                            return true;
                        }
                        return false;
                    });
                    this.breadcrumbsArray = updatedArray
                    this.breadcrumbsArray.forEach(el => {
                        if(el.title == "Home"){
                            el.url = `/${this.currentLang}/home`
                        }
                        else if (el.title == "Category"){
                           el.url = `/${this.currentLang}/home/category`
                        }
                        else if (el.title == "Product listing"){
                            el.url = `/${this.currentLang}/product-listing`
                         }
                    });
                    this.isBreadCrumbLoading = false;
                }, 2200);
            });


    }

    goToCustomerProfile() {
        if (!isPlatformServer(this.platformId)) {
            window.open(environment.supplierUrl);
        }
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: this.commonService.domDirectionLTR
                ? "Are you sure?"
                : "هل أنت متأكد؟",
            message: this.commonService.domDirectionLTR
                ? "You want to logout?"
                : "تريد الخروج؟",
            buttons: [
                {
                    text: this.translate.instant('no'),
                    role: "cancel",
                    handler: () => { },
                },
                {
                    text: this.translate.instant('yes'),
                    role: "confirm",
                    handler: () => {
                        this.logout();
                    },
                },
            ],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
    }

    logout() {
        this.authService.logout().subscribe(
            (data) => {
                if (data["code"] == 200 && data["data"]) {
                    this.commonService.localStorageClear();
                    this.commonService.setEvent("logoutCall", null);
                    this.cartService.isloggedIn = false;
                    this.navCtrl.navigateBack('/' + this.currentLang + "/home", { animated: false });
                    this.userName = null;
                } else {
                    this.cartService.isloggedIn = true;
                    this.toastService.displayToast(data["message"], null, "danger");
                }
            },
            (error) => {
                this.cartService.isloggedIn = false;
                this.toastService.displayToast(error["message"], null, "danger");
                this.navCtrl.navigateBack('/' + this.currentLang + "/home", { animated: false });
                this.commonService.setEvent("logoutCall", null);
                this.commonService.domDirectionLTR = true;
            }
        );
    }

    outSideDropdown() {
        this.isInfoDropdownOpen = false;
    }

    goToAccount() {
        this.navCtrl.navigateForward('/' + this.currentLang + "/home/account-details/my-account", {
            animated: false,
        });
    }

    gotToContactUs() {
        this.navCtrl.navigateForward('/' + this.currentLang + "/contact-us", { animated: false });
    }

    gotoNotification(mobile?) {
        let currentLang = this.translate.currentLang;
        if (mobile) {
            this.navCtrl.navigateForward('/' + this.currentLang + "/my-account/notification", {
                animated: false,
            });
        } else {
            this.navCtrl.navigateForward('/' + this.currentLang + "/home/account-details/notification", {
                animated: false,
            });
        }
    }
    gotoWishList(mobile?) {
        const token = this.commonService.localStorageGet("accessToken");
        if (token) {
            if (mobile) {
                this.navCtrl.navigateForward('/' + this.currentLang + "/tabs/my-wishlist", { animated: false });
            } else {
                this.navCtrl.navigateForward('/' + this.currentLang + "/home/account-details/my-wishlist", {
                    animated: false,
                });
            }
        } else {
            this.isLoggedIn = false;
            let options: any = {
                id: "loginModal",
                backdropDismiss: false,
            };
            options["component"] = LoginPage;
            this.modalService.openModal(options);
        }
    }

    getNotificationsCategory() {
        const params = {
            dropdown: true,
        };
        this.subscription.add(
            this.dataService.get(URLS.notificationListType, params).subscribe(
                (data) => {
                    if (data && data["code"] == 200) {
                        this.notificationList = data["data"].length ? data["data"] : [];
                        this.commonService.notificationCount =
                            this.notificationList && this.notificationList.length
                                ? this.notificationList[0]["total_unread"]
                                : null;
                    } else {
                        this.commonService.notificationCount = null;
                    }
                },
                (error) => {
                    this.commonService.notificationCount = null;
                }
            )
        );
    }

    toggleAccountMenu() {
        this.commonService.setEvent("menu-btn-clicked", null);
    }
}
