import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api-data.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
    selector: 'app-wishlist-filter-mobile',
    templateUrl: './wishlist-filter-mobile.component.html',
    styleUrls: ['./wishlist-filter-mobile.component.scss'],
})
export class WishlistFilterMobileComponent implements OnInit {
    private subscription: Subscription = new Subscription();

    maxDate: any;
    orderStatus = 1;
    selectedCategory: any;
    fromDate: any;
    toDate: any;
    maxFromDate: any;
    statusList = [];
    todaysDate = new Date();
    statusInterface = { cssClass: 'filterby-dropdown' };
    subClassInterface = { cssClass: 'filterby-dropdown' };

    dateRange: FormGroup;
    filterObj = {};
    fromOrdersList = false;
    catagoryListData: any = [];
    subClassArray: any = [];
    selectedsubClass: any;
    subClassDisable = true;
    constructor(
        private modalCtrl: ModalController,
        private commonService: CommonService,
        private datePipe: DatePipe,
        private toastService: ToastService,
        private dataService: ApiService,

    ) { }

    ngOnInit() {
        if (this.filterObj) {
            if (this.filterObj['from_date']) {
                this.fromDate = new Date(this.filterObj['from_date']);
            }
            if (this.filterObj['to_date']) {
                this.toDate = new Date(this.filterObj['to_date']);
            }
        }
        let date = new Date();
        this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
        this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
        this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
    }

    clear() {
        this.filterObj = {};
        this.modalCtrl.dismiss(this.filterObj);
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    onDateChange(datetime) {
        datetime.cancel(true);
    }

    apply() {
        if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
            if (!this.fromDate) {
                let msg = this.commonService.domDirectionLTR ? "Please select 'From Date'." : 'الرجاء تحديد "من التاريخ".';
                this.toastService.displayToast(msg, null, 'danger');
            }
            if (!this.toDate) {
                let msg = this.commonService.domDirectionLTR ? "Please select 'To Date'." : 'الرجاء تحديد "حتى الآن".';
                this.toastService.displayToast(msg, null, 'danger');
            }
            return
        }
        if (this.fromDate && this.toDate) {
            this.filterObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
            this.filterObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
        }
        if (this.filterObj['from_date'] && this.filterObj['to_date']) {
            if (this.filterObj['from_date'] > this.filterObj['to_date']) {
                let msg = this.commonService.domDirectionLTR ? "'From Date' cannot be greater than 'To Date'." : 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
                this.toastService.displayToast(msg, null, 'danger');
                return
            }
        }
        let isValid = false;
        if (isValid && this.filterObj['from_date'] && this.filterObj['to_date'] && (this.filterObj['from_date'] == this.filterObj['to_date'])) {
            let msg = this.commonService.domDirectionLTR ? "'From Date' and 'To Date' cannot be same." : 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
            this.toastService.displayToast(msg, null, 'danger');
            return
        }
        this.modalCtrl.dismiss(this.filterObj);
    }




}
