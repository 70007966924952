import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { ModalService } from "src/app/services/modal.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ToastService } from "../../services/toast.service";
import { EditProfileComponent } from "../edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./../change-password/change-password.component";
import { TwoFactorAuthenticationComponent } from "./../two-factor-authentication/two-factor-authentication.component";
import { isPlatformServer } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-account-information",
  templateUrl: "./account-information.component.html",
  styleUrls: ["./account-information.component.scss"],
})
export class AccountInformationComponent implements OnInit {
  mobileView = false;
  public title = "My account details";
  public isLoading = false;
  private subscription: Subscription = new Subscription();
  contactInformation: any;
  accountSecurity = {
    is_2F_enable: true,
  };

  @Input() isMobile: any;
  currentLang = this.translate.currentLang;
  constructor(
    public commonService: CommonService,
    public modalService: ModalService,
    private dataService: ApiService,
    private toastService: ToastService,
    private getPlatformService: GetPlatformService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private navCtrl: NavController,
    public translate: TranslateService
  ) {
    this.commonService.eventObservable.subscribe((data) => {
      if (data.event == "profileData") {
        this.getCustomerDetails();
      }
    });
  }

  ngOnInit() {
    this.mobileView = this.isMobile;
    this.getCustomerDetails();
  }

  getCustomerDetails() {
    this.isLoading = true;
    this.subscription.add(
      this.dataService.get(URLS.getDefaultAddressList).subscribe(
        (data) => {
          if (data["data"] && data["code"] == 200) {
            this.isLoading = false;
            let customerData = data["data"];

            this.contactInformation = customerData;

            this.commonService.twoFaEnabled = data["data"]["is_2fa_enabled"];
            this.commonService.localStorageSet(
              "is_2fa_enabled",
              this.commonService.twoFaEnabled
            );
          } else {
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
        }
      )
    );
  }

  toggle2fa() {
    let options: any = {
      id: "twoFaModal_Account",
      backdropDismiss: false,
      from: "MyAccount",
    };
    options["component"] = TwoFactorAuthenticationComponent;
    options["componentProps"] = {
      id: "twoFaModal_Account",
      from: "MyAccount",
    };
    this.modalService.openModal(options);
  }

  goToChangePassword() {
    if (!this.getPlatformService.isDesktop) {
      this.openModal();
    } else {
      this.navCtrl.navigateForward(['/'+this.currentLang +"/change-password"]);
    }
  }

  openModal() {
    let options: any = {
      id: "changePassword",
      from: "MyAccount",
      componentProps: { mobileView: true },
    };
    options["component"] = ChangePasswordComponent;
    options["componentProps"] = {
      id: "changePassword",
      from: "MyAccount",
    };
    this.modalService.openModal(options);
  }

  getFileName(url) {
    return url.split("/").pop();
  }

  openFile(url) {
    if (!isPlatformServer(this.platformId)) {
      window.open(url);
    }
  }

  editProfileOpen() {
    let options: any = {
      id: "registerModal",
      backdropDismiss: false,
    };
    options["component"] = EditProfileComponent;
    let modal: any = this.modalService.openModal(options);
    modal.then((data) => {
      if (data && data.data) {
        this.getCustomerDetails();
      }
    });
  }
}
