// shared.service.ts
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class SharedParamsService {
    private storageKey = 'myAppQueryParams';
    queryParams: any = {};
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        const storedData = localStorage.getItem(this.storageKey);
        this.queryParams = storedData ? JSON.parse(storedData) : {};
    }
    setQueryParams(params: any): void {
        this.queryParams = params;
        // Store data in localStorage
        localStorage.setItem(this.storageKey, JSON.stringify(params));
    }
}