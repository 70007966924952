import { Component, OnInit } from '@angular/core';
import { GetPlatformService } from 'src/app/services/get-platform.service';


@Component({
    selector: 'app-landing-screen',
    templateUrl: './landing-screen.component.html',
    styleUrls: ['./landing-screen.component.scss'],
})
export class LandingScreenComponent implements OnInit {
    constructor(public getPlatformService: GetPlatformService,) {


    }

    ngOnInit() {

    }


}
