<div class="ion-content">
    <ion-grid>
        <ion-row *ngIf="!showBanner">
            <ion-col *ngFor="let data of productGridData" size-xs="6" size-sm="4" size-md="3" size-lg="3" size-xl="3">
                <app-product-card [cardData]="data" [from]="true" [productDetailLabel]="true" [productlistPage]="productlist1"></app-product-card>
            </ion-col>
        </ion-row>
        <ion-row class="row-alignment" *ngIf="showBanner">
            <ng-container *ngFor="let data of productData;index as i;">
                <ion-col size-xs="5.5" size-sm="5.8" size-md="4" size-lg="3" size-xl="3" *ngIf="i < 12">
                    <app-product-card (productClicked)="goToProductDetail(data)" [cardData]="data" [from]="true"
                        [productDetailLabel]="true" [productlistPage]="productlist1"></app-product-card>
                </ion-col>
            </ng-container>
        </ion-row>
        <app-banner [gridBanner]="true" [gridBannerData]="gridBannerData" *ngIf="productData.length > 0"></app-banner>
        <ion-row class="row-alignment" *ngIf="showBanner">
            <ng-container *ngFor="let data of productData;index as i;">
                <ion-col size-xs="5.5" size-sm="5.8" size-md="4" size-lg="3" size-xl="3" *ngIf="i >= 12">
                    <app-product-card (productClicked)="goToProductDetail(data)" [cardData]="data" [from]="true"
                        [productDetailLabel]="true" [productlistPage]="productlist1"></app-product-card>
                </ion-col>
            </ng-container>
        </ion-row>
        <ion-row class="load-more-row ion-justify-content-center" *ngIf="productData.length > 0">
            <app-common-button *ngIf="btnStatus" (click)="loadMoreData()" [btnStatus]="btnStatus" color="white"
                background="var(--ion-app-primary)" type="solid" title="{{ 'productGridComponent.Load more' | translate }}"
                [isIcon]="true" iconSrc="assets/icon/down-arrow.svg" [specificPaddingStart]="'40px'"
                [specificPaddingEnd]="'10px'" [isHideInMobile]="true" [specificWidth]="'190px'" [specificHeight]="'46px'"
                [specificFontWeight]="'bold'"></app-common-button>
        </ion-row>
    </ion-grid>
</div>