<div class="home-page app-header p-b-20">
	<app-header></app-header>
</div>
<ion-content>
	<div id="notfound">
		<div class="notfound-404">
			<h1>{{'notFoundComponent.code' | translate}}</h1>
			<h2>{{'notFoundComponent.pageNotFound' | translate}}</h2>
			<p>{{'notFoundComponent.message' | translate}}</p>
		</div>
		<div class="custom-button">
			<ion-button routerLink="/">{{'notFoundComponent.homePage' | translate}}</ion-button>
		</div>
	</div>

</ion-content>
<ion-footer>
	<div class="app-footer">
		<app-footer [showOnlyLowFooter]="true"></app-footer>
	</div>
</ion-footer>