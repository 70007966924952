<ion-header class="product-review-header">
    <ion-toolbar mode="md">
        <img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
            [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
        <ion-title class="title-capitalize">
            {{"reviewPage.reviews" | translate}}
        </ion-title>
    </ion-toolbar>
</ion-header>
<app-loader *ngIf="isLoading"></app-loader>
<ion-content *ngIf="!isLoading" class="detail-page detail-mobile">
    <ion-row class="review-container scrollToSection" id="section2">
        <ng-container *ngIf="reviews.length > 0">
            <div class="review-top-section">
                <div class="review-rating-container">
                    <div class="review-rating">
                        {{ average_rating }}
                    </div>
                    <div class="review-star-rating">
                        <ionic5-star-rating class="star-rating" #rating activeIcon="custom-star-fill" halfStar="false"
                            defaultIcon="custom-star-default" activeColor="#FACD2E" defaultColor="#758D96"
                            fontSize="22px" readonly="true" [rating]="average_rating">
                        </ionic5-star-rating>
                    </div>
                    <div class="review-header" *ngIf="getPlatformService.isDesktop">
                        {{reviews.length > 0 ? total_ratings : 0}} {{'reviews' | translate}}
                    </div>
                </div>
                <div class="add-review" (click)="addReviewModal()">
                    <ion-row *ngFor="let data of reviewCount" class="review-row">
                        <ion-label class="review-label">{{ commonService.domDirectionLTR ? data.name:data.name_ar
                            }}</ion-label>
                        <ion-range [value]="data.percentage" [pin]="false" class="mh-20 p-0"></ion-range>
                        <ion-label class="review-count-label">{{ data.count }}</ion-label>
                    </ion-row>
                </div>

                <div class="add-review" (click)="addReviewModal()">
                    <app-common-button color="#FFF" background="#38C2D4" type="solid" class="width-100"
                        title="{{'addReview' | translate}}" [isIcon]="false" [isHideInMobile]="false"
                        [isFullWidth]="true" [specificFontSize]="'1rem'">
                    </app-common-button>
                </div>
            </div>
            <div class="review-middle-section">
                <ng-container *ngFor="let data of reviews;let last = last;index as i;">
                    <div *ngIf="i <=reviewparams.size">
                        <div class="reviewer-name-container">
                            <div class="reviewer-name">
                                {{ data.user_name }}
                            </div>
                            <div class="reviewer-rating">
                                <ionic5-star-rating #rating activeIcon="custom-star-fill" halfStar="false"
                                    defaultIcon="custom-star-default" activeColor="#FACD2E" defaultColor="#758D96"
                                    fontSize="17px" readonly="true" [rating]="data.rating">
                                </ionic5-star-rating>
                            </div>
                        </div>
                        <div class="verified-container" *ngIf="data.verified">
                            <div class="verified-logo">
                                <img src="assets/icon/verified.svg" alt="Juman">
                            </div>
                            <div class="verified">
                                {{'verifiedPurchase' | translate}}
                            </div>

                        </div>
                        <div class="review-date">
                            {{ data.date | date: 'dd/MM/yyyy' }}
                        </div>
                        <div class="review-title" *ngIf="getPlatformService.isDesktop">
                            {{ data.title }}
                        </div>
                        <div class="reviewer-comment" [ngClass]="[last ? 'comment-border' : '']">
                            {{ data.comment }}
                        </div>
                    </div>
                    <div *ngIf="i >reviewparams.size && loadMore">
                        <div class="reviewer-name-container">
                            <div class="reviewer-rating">
                                <ionic5-star-rating #rating activeIcon="custom-star-fill" halfStar="false"
                                    defaultIcon="custom-star-default" activeColor="#FACD2E" defaultColor="#758D96"
                                    fontSize="17px" readonly="true" [rating]="data.rating">
                                </ionic5-star-rating>
                            </div>

                        </div>
                        <div class="verified-container" *ngIf="data.verified">
                            <div class="verified-logo">
                                <img src="assets/icon/verified.svg" alt="Juman">
                            </div>
                            <div class="verified">
                                {{'verifiedPurchase' | translate}}
                            </div>

                        </div>
                        <div class="review-date">
                            {{ data.date | date: 'dd/MM/yyyy' }}
                        </div>
                        <div class="review-title" *ngIf="!getPlatformService.isDesktop">
                            {{ data.title }}
                        </div>
                        <div class="reviewer-comment" [ngClass]="[last ? 'comment-border' : '']">
                            {{ data.comment }}
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="reviewsArray.enable_next" class="more-reviews ion-text-center" (click)="loadMoreReviews()">
                    {{ 'loadMoreReviews' | translate }}
                </div>
                <div *ngIf="!reviewsArray.enable_next" class="more-reviews ion-text-center">
                    {{ 'noMoreReviews' | translate }}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!reviews.length">
            <div class="no-reviews pt-30 pb-40">
                {{'noReviews' | translate}}.
            </div>
        </ng-container>
    </ion-row>
</ion-content>