<ion-content>
    <div class="no-internet-container">
        <div>
            <div class="image mb-20">
                <img src="./assets/images/noInternet.svg" alt="Juman">
            </div>
            <div class="no-internet-title pb-15">
                {{'noInternet.title' | translate}}
            </div>
            <div class="no-internet-description">
                {{'noInternet.description' | translate}}
                <div>
                    {{'noInternet.checkConnection' | translate}}
                </div>
            </div>
            <ion-button type="primary" class="try-again-btn mt-60" (click)="tryAgain()">
                <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                {{'tryAgain' | translate}}
            </ion-button>
        </div>
    </div>
</ion-content>