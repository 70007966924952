<div class="add-review-container pt-30 " [ngClass]="getPlatformService.isDesktop? 'ph-30':null">
    <div class="modal-close text-right" (click)="closeModal()">
        <img src="assets/icon/close.svg" alt="Close" height="100%" width="100%" />
    </div>
    <div class="add-review-header">
        {{'notifyMe' | translate}}
    </div>
    <div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>

    <form [formGroup]="notifyMe">
        <div class="email common-input " [ngClass]="getPlatformService.isDesktop? 'mv-20':'m-20'">
            <ion-item lines="none" mode="md">
                <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/email-icon.svg" alt="juman">
                <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{'EMAIL' | translate }}
                </ion-label>
                <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{'Email' | translate }}
                </ion-label>
                <ion-input autocomplete="new-email" formControlName="email" placeholder="{{'ENTEREMAIL' | translate }}"
                    #emailInput>
                </ion-input>
            </ion-item>
            <ng-container
                *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': notifyMe} }">
            </ng-container>
        </div>
    </form>
</div>
<ion-footer>
    <div class="footer-button-container">
        <div class="footer-button">
            <app-common-button color="#FFF" background="#38C2D4" type="solid" class="width-100"
                title=" {{ 'Submit' | translate }}" [isIcon]="false" [isHideInMobile]="false" [specificWidth]="'122px'"
                [specificHeight]="'46px'" [specificFontSize]="'0.9375rem'" (click)="submit()">
            </app-common-button>
        </div>
    </div>
</ion-footer>

<ng-template let-formObject #validationMessages>
    <ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
        <ion-text color="danger" class="error-msg"
            *ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
            {{validation.message | translate}}
        </ion-text>
    </ng-container>
</ng-template>