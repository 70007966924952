<div class="account-information" *ngIf="!mobileView">
    <div class="account-info-grid" *ngIf="contactInformation">
        <ion-grid>
            <ion-row>
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
                    <div class="information-holder">
                        <div class="information-label">
                            {{"accountInfoComponent.contactInformation.heading"| translate}}
                        </div>
                        <div class="information-container">
                            <div class="account-name">
                                {{contactInformation.customer_name}}
                            </div>
                            <div class="account-email">
                                {{contactInformation.customer_email}}
                            </div>
                            <div class="account-email">
                                +966 {{contactInformation.mobile_no}}
                            </div>
                        </div>
                        <div class="edit-btn-container" *ngIf="!mobileView">
                            <!-- <span class="edit-btn" (click)="editProfileOpen()">
                                {{"accountInfoComponent.editBtn" | translate}}
                            </span>
                            <span class="vertical-separator"></span> -->
                            <span class="change-password" (click)="goToChangePassword()">
                                {{"accountInfoComponent.changePass" | translate}}
                            </span>
                        </div>
                    </div>
                </ion-col>
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
                    <div class="information-holder">
                        <div class="information-label">
                            {{"accountInfoComponent.accountSecurity.heading" | translate}}
                        </div>
                        <div class="information-container">
                            <div class="security-note">
                                <span>{{"2FAAccountDescription" | translate}}</span>
                            </div>
                        </div>
                        <div class="twoF-btn-container">
                            <ion-button fill="clear" class="twoF-btn" (click)="toggle2fa()">
                                <img [ngClass]="commonService.domDirectionLTR ? 'mr-10' : 'ml-10'"
                                    src="./assets/icon/lockIcon.svg" alt="add" slot="start">
                                <span *ngIf="!commonService.twoFaEnabled">{{"accountInfoComponent.enable2FactorBtnLabel"
                                    | translate}}</span>
                                <span *ngIf="commonService.twoFaEnabled">{{"accountInfoComponent.disable2FactorBtnLabel"
                                    | translate}}</span>
                            </ion-button>
                        </div>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row class="mt-30">
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
                    <div class="information-holder">
                        <div class="information-label">
                            {{"accountInfoComponent.crNumber"| translate}}
                        </div>
                        <div class="information-container">
                            <div class="account-name">
                                {{contactInformation.customer_cr_no ? contactInformation.customer_cr_no : "--"}}
                            </div>
                            <div class="account-name pointer color-primary" *ngIf="contactInformation.customer_cr_url"
                                (click)="openFile(contactInformation.customer_cr_url)">
                                <i class="feather color-primary icon-file"></i>
                                {{contactInformation.customer_cr_url ? getFileName(contactInformation.customer_cr_url) :
                                "--"}}
                            </div>
                        </div>
                    </div>
                </ion-col>
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
                    <div class="information-holder">
                        <div class="information-label">
                            {{"accountInfoComponent.vatNumber"| translate}}
                        </div>
                        <div class="information-container">
                            <div class="account-name">
                                {{contactInformation.customer_vat_no ? contactInformation.customer_vat_no : "--"}}
                            </div>
                            <div class="account-name pointer color-primary" *ngIf="contactInformation.customer_vat_url"
                                (click)="openFile(contactInformation.customer_vat_url)">
                                <i class="feather color-primary icon-file"></i>
                                {{contactInformation.customer_vat_url ?
                                getFileName(contactInformation.customer_vat_url):"--"}}
                            </div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="mt-30">
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="p-0">
                    <div class="information-holder">
                        <div class="information-label">
                            {{"accountInfoComponent.nationalAddress"| translate}}
                        </div>
                        <div class="information-container">
                            <div class="account-name pointer color-primary"
                                *ngIf="contactInformation.national_address_url"
                                (click)="openFile(contactInformation.national_address_url)">
                                <i class="feather color-primary icon-file"></i>
                                {{contactInformation.national_address_url ?
                                getFileName(contactInformation.national_address_url):"--"}}
                            </div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>

        </ion-grid>
    </div>
</div>
<div class="account-information" *ngIf="mobileView">
    <div class="account-info-grid" *ngIf="contactInformation">
        <ion-grid class="p-0">
            <ion-row>
                <ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
                    <div class="common-input mb-10">
                        <ion-item lines="none" mode="md">
                            <img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
                            <ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
                        </ion-item>

                    </div>

                    <div class="common-input mb-10">
                        <ion-item lines="none" mode="md">
                            <img slot="start" src="assets/icon/user.svg" alt="juman" class="input-icon">
                            <ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
                        </ion-item>

                    </div>

                    <div class="common-input mb-10">
                        <ion-item lines="none" mode="md">
                            <img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
                            <ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

                            <ion-label slot="end" class="btn-label"
                                (click)="openModal()">{{"accountInfoComponent.change" |
                                translate}}</ion-label>
                        </ion-item>


                    </div>

                </ion-col>

            </ion-row>
        </ion-grid>
    </div>
</div>