<div class="mobile-menu">
    <div class="user-info-container">
        <div class="connect-to-account" *ngIf="!isLoggedIn">
            <div class="connect-label">
                {{'connectLabel' | translate}}
            </div>
            <div class="connect-description">
                {{'connectDescription' | translate}}
            </div>
            <div class="buttons-container">
                <ion-button fill="clear" class="connect-buttons register-btn" (click)="openRegisterModal()">
                    {{buttonLabel.register | translate}}
                    <span class="arrowIcon" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
                        <img src="./assets/icon/whiteRightArrow.svg" alt="right-arrow" slot="end">

                    </span>
                </ion-button>
                <ion-button fill="clear" class="connect-buttons login-btn" (click)="openLoginModal()">
                    {{buttonLabel.login | translate}}
                    <span class="arrowIcon" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow '">
                        <img src="./assets/icon/redRightArrow.svg" alt="right-arrow" slot="end">
                    </span>
                </ion-button>
            </div>

        </div>
    </div>
</div>