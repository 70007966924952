import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-product-slider',
    templateUrl: './product-slider.component.html',
    styleUrls: ['./product-slider.component.scss'],
})
export class ProductSliderComponent implements OnInit {

    @ViewChild("productsSlides", { static: false }) slider: IonSlides;

    @Input() productTitle: string = "";
    @Input() public sliderData = [];
    @Input() public relatedProducts: boolean = false;
    @Input() public categories: boolean = false;
    @Input() public viewAll: boolean = false;

    public lastSlide: boolean = false;
    public slideIndex: any;
    public firstSlide: boolean = true;
    public sliderEnd: any;
    currentLang = this.translate.currentLang; 
    public slideOpts = {
        initialSlide: 0,
        speed: 200,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: 1.75,

            },
            360: {
                slidesPerView: 2.2,
            },
            375: {
                slidesPerView: 2.2,
            },
            385: {
                slidesPerView: 2.2,
            },
            500: {
                slidesPerView: 2,
            },
            488: {
                slidesPerView: 2.5,
            },
            700: {
                slidesPerView: 2.5,
            },
            768: {
                slidesPerView: 2,
            },
            800: {
                slidesPerView: 2.3,
            },
            1000: {
                slidesPerView: 3.3,
            },
            1100: {
                slidesPerView: 3.5,
            },
            1280: {
                slidesPerView: 4,
            },
            1400: {
                slidesPerView: 4.5,
            },
            1480: {
                slidesPerView: 5,
            },
            1600: {
                slidesPerView: 5,
            },
            1800: {
                slidesPerView: 5,
            },
            1920: {
                slidesPerView: 5,
            },
        },
    };

    public relatedSlideOptions = {
        initialSlide: 0,
        speed: 200,
        spaceBetween: 40,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 2.3,
            },
            488: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 3.5,
            },
            1100: {
                slidesPerView: 4,
            },
            1280: {
                slidesPerView: 5,
            },
            1600: {
                slidesPerView: 5,
            },
            1800: {
                slidesPerView: 5,
            },
            1920: {
                slidesPerView: 5,
            },
        },
    }

    public categoriesOpts = {
        initialSlide: 0,
        speed: 200,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 2.2,
            },
            360: {
                slidesPerView: 2.7,
            },
            488: {
                slidesPerView: 3.5,
            },
            768: {
                slidesPerView: 3.5,
            },
        },
    };

    constructor(
        public router: Router,
        public commonService: CommonService,
        private navCtrl: NavController,
        private translate: TranslateService,
        ) {
    }

    ngOnInit() {
    }


    async goToProductDetail(data) {
        if(data.category_id){
            let category_id = +data.category_id
            this.commonService.localStorageSet("category_id", category_id)
        }
        if (data.product_id) {
            let prod_id = data.product_id;
     
            // this.navCtrl.navigateForward("/"+this.currentLang+"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
            let prodnameUrl = this.commonService.trimUrl(data.product_name);
            let catnameUrl = this.commonService.trimUrl(data.category_name_en);
            let breadcrumbProdNameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.product_name : data.product_name_ar);
            let breadcrumbCatNameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? data.category_name_en : data.category_name_ar);
            await this.navCtrl.navigateForward("/"+this.currentLang+"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                {
                    animated: false,
                    "state": {
                        "breadcrumb": this.commonService.domDirectionLTR ? data.product_description : data.product_description_ar,
                        "url": "/"+this.currentLang+"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                    },
                });
        }
    }

    viewAllProducts(data) {

        switch (data) {

            case "FEATURED":
                this.navCtrl.navigateForward("/"+this.currentLang+'/product-listing/' + 'featured', { animated: false, "state": { "breadcrumb": this.translate.instant('featuredProduct'), "url": '/product-listing/' + 'featured', product_type: data, banner_img: '/assets/images/slider.svg' } })
                break;

            case "RECOMMENDED":
                this.navCtrl.navigateForward("/"+this.currentLang+'/product-listing/' + 'recommended', { animated: false, "state": { "breadcrumb": this.translate.instant('recommendProduct'), "url": '/product-listing/' + 'recommended', product_type: data, banner_img: '/assets/images/slider.svg' } })
                break;

            case "BESTSELLING":
                this.navCtrl.navigateForward("/"+this.currentLang+'/product-listing/' + 'bestselling', { animated: false, "state": { "breadcrumb": this.translate.instant('bestSellingProduct'), "url": '/product-listing/' + 'bestselling', product_type: data, banner_img: '/assets/images/slider.svg' } })
                break;

            default:
                this.navCtrl.navigateForward("/"+this.currentLang+'/product-listing/', { animated: false, "state" : {banner_img: '/assets/images/slider.svg'} })

        }
    }

    goToProductListing(data?) {
        if (data && data.category_id) {
            this.navCtrl.navigateForward("/"+this.currentLang+'/product-listing/' + data.category_name, {
                animated: false, "state": {
                    "breadcrumb": data.category_name,
                    "url": "/"+this.currentLang+'/product-listing/' + data.category_name,
                    id: data.category_id,
                    "ar_label": data.category_name
                }
            });
        } else {
            this.navCtrl.navigateBack("/"+this.currentLang+'/product-listing');
        }
    }

    goToCategoryListing(data?) {
        if (data && data.category_id) {
            this.navCtrl.navigateForward("/"+this.currentLang+'/tabs/categories', {
                animated: false, queryParams: {
                    "group_id": data.category_id,
                    "from_home": true
                },
            });

        }
        else {
            this.navCtrl.navigateForward("/"+this.currentLang+'/tabs/categories', { animated: false });

        }

    }

    slideToNext() {
        this.slider.slideNext();
    }

    slideToPrev() {
        this.slider.slidePrev();
    }

    async slideChanged() {
        this.slideIndex = await this.slider.getActiveIndex();
        this.sliderEnd = await this.slider.isEnd();
        if (this.slideIndex === 0) {
            this.firstSlide = true;
            this.lastSlide = false;
        } else if (this.slideIndex > 0) {
            this.firstSlide = false;
            this.lastSlide = false;
        }
    }
}
