<ion-content *ngIf="!isLoading && cartDetails && cartData.length > 0 && cartDetails.grand_total">
	<div>
		<ion-row class="row-div">
			<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6">
				<p class="label1 color-app-grey"><span class="color-app-black">{{cartData.length}}
						{{cartData.length > 1 ? ('cartModal.products'|translate) :
						('cartModal.product'|translate)}}</span>
					<span class="label-in-cart"> {{'cartModal.incart'| translate}}</span>
				</p>
			</ion-col>
			<ion-col size-sm="6" size-md="6" size-lg="6" size-xl="6" size-xs="6" class="textEnd">
				<p class="label2 color-app-black">{{'cartModal.CartSubTotal'|translate}} </p>
				<p class="label-total color-app-black" *ngIf="cartDetails.grand_total &&
                    cartDetails.grand_total.items_sub_total">{{commonService.currencySymbol}}
					{{cartDetails.grand_total.items_sub_total|number}}</p>

				<ion-skeleton-text *ngIf="!cartDetails.grand_total &&
                    !cartDetails.grand_total.items_sub_total"
				 class="skeleton-container" name="lines-sharp-small" [animated]="true"></ion-skeleton-text>
				<p class="label2 clearCart color-app-black mt-10 pointer" (click)="clearCart()">
					{{'cartModal.clearCart'|translate}} </p>
			</ion-col>
		</ion-row>
		<div class="divider-line"></div>

		<div class="scroll-content" *ngIf="cartData.length && !isLoading">
			<div *ngFor="let data of cartData;let i =index">
				<ion-row class="row-padding">
					<div class="mr-15 position-rel" [ngClass]=" data.is_sold_out ? 'product-image-blur' : null">

						<div *ngIf="data.discount_type &&  data.discount_type != 'PRICE' && !data.is_sold_out && !data.is_sample_product"
						 class="discout_div ">
							<span>-</span>{{ data.discount_value }}<span>%</span>
						</div>
						<div *ngIf="data.discount_type &&  data.discount_type == 'PRICE' && !data.is_sold_out  && !data.is_sample_product"
						 class="discout_div ">
							<span>{{"OnSale" | translate}}</span>
						</div>

						<img *ngIf="data.product_cover_image" src="{{data.product_cover_image[0].image_url}}" class="product_img" alt="juman" />

						<img *ngIf="!data.product_cover_image" src="./assets/images/empty-img.png" class="product_img" alt="juman" />
					</div>
					<div *ngIf="data.is_sold_out" class="product-outofstock">
						<div class="outofstock-label">
							{{"outOfStock" | translate}}
						</div>
					</div>

					<div class="width-col">
						<p class="label3 color-app-black">{{commonService.domDirectionLTR ? data.product_name_en :
							data.product_name_ar}}</p>
						<div *ngIf="data.is_sample_product == true" class="sample_div ">
							<p>{{'SAMPLE'| translate}}</p>
						</div>

						<p class="label5 color-app-grey display-div cursor-pointer " (click)="goToProductDetail(data)">
							{{'ViewDetails' |translate}}
							<!-- <i class="feather icon-chevron-down arrow-icon color-primary ml-5"></i> -->
						</p>

						<div class="rowQty display-div">
							<div>
								<div class="label3 color-app-black product-price">
									{{commonService.currencySymbol}}{{data.is_sample_product ? (data.price_per_unit |
									number): (data.final_price_per_unit | number)}}
									<!-- <span *ngIf="data.unit_name_en && data.is_sample_product != true" class="label6 color-app-grey">/{{commonService.domDirectionLTR
										?
										data.unit_name_en:data.unit_name_ar}}</span> -->
								</div>
								<div class="pt-5 sub-price-title" *ngIf="data.price_per_piece">
									{{commonService.currencySymbol}} {{ data.price_per_piece }} <span *ngIf="data.secondary_unit_name_en">/</span>
									{{commonService.domDirectionLTR
									? data.secondary_unit_name_en :
									data.secondary_unit_name_ar}}
								</div>
							</div>

							<div class="units-containter">

								<div class="qty_div">
									<div class="label4 color-app-black">
										{{data.total_quantity}}
									</div>
									<!-- code maybe required later -->
									<!-- <span class="label6 color-app-grey text-right">{{commonService.domDirectionLTR
										?
										data.unit_name_en:data.unit_name_ar}}</span> -->
								</div>
								<div class="pt-5 ion-text-center sub-price-title">
									{{ commonService.domDirectionLTR ?
									data.unit_name_en :
									data.unit_name_ar }}<span *ngIf="(data.secondary_unit_name_en || data.secondary_unit_name_ar) && (data &&
									data.unit_id && data.secondary_unit_id && data.unit_id !==
									data.secondary_unit_id)">
										({{ data.conversation_ratio }} {{commonService.domDirectionLTR
										? data.secondary_unit_name_en :
										data.secondary_unit_name_ar}})
									</span>
								</div>
								<!-- code maybe required later -->
								<!-- <div class="qty_div suom"
                                    *ngIf="data.unit_id && data.secondary_unit_id && data.unit_id !== data.secondary_unit_id">
                                    <p class="label4 color-app-black">
                                        {{calculateSUOM(data.conversation_ratio,data.total_quantity)}}
                                    </p>
                                    <span class="label6 color-app-grey  text-right">
                                        {{commonService.domDirectionLTR ? data.secondary_unit_name_en
                                        :
                                        data.secondary_unit_name_ar}} </span>
                                </div> -->
							</div>
							<div class="ml-25 delete-icon mr-20">
								<img src="/assets/icon/trash.svg" class="deleticon color-app-black" (click)="deleteProduct(data)" title="Delete"
								 alt="juman" />
							</div>
						</div>
					</div>



					<div class="width-100" *ngIf="i != (cartData.length - 1)">
						<div class="divider-line mt-15 border-right"></div>
					</div>


				</ion-row>

			</div>
		</div>
	</div>
</ion-content>

<app-loader *ngIf="isLoading" [fromCartPopup]="true"></app-loader>
<app-loader *ngIf="!isLoading && !cartDetails.grand_total && cartData.length != 0" [fromCartPopup]="true"></app-loader>
<div *ngIf="!cartData.length && !isLoading" class="vertical_center">
	<img src="./assets/icon/emptyCart.svg" alt="Juman" class="mb-10" />
	<div class="mb-20">
		<p class="label-3 color-app-black ">{{'Thecartisempty' | translate}}</p>
	</div>
</div>

<ion-footer *ngIf="!isLoading && cartDetails && cartData.length > 0 && cartDetails.grand_total">
	<div class="divider-line"></div>
	<div class="ph-25 pv-10">
		<app-common-button color="#FFF" background="#38C2D4" type="solid" class="width-100" title="{{'cartModal.Viewandeditcart'|translate}}"
		 [isIcon]="false" [isHideInMobile]="false" [isFullWidth]="true" [specificHeight]="'46px'" [specificFontSize]="'0.9rem'"
		 [isButtonLoading]="isAddToCartLoading" (buttonClicked)="gotoCartPage()" [specificFontWeight]="700" *ngIf="cartData.length">
		</app-common-button>

	</div>
</ion-footer>