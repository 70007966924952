import { DOCUMENT, isPlatformServer } from '@angular/common';
import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { SECRET_KEY } from '../_config/api.config';
import { NoInternetComponent } from '../components/no-internet/no-internet.component';
import { GetPlatformService } from './get-platform.service';
import { ModalService } from './modal.service';

// var CryptoJS = require("crypto-js");

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    isNetworkConnected: boolean = true;
    isNoInternetModalOpen: boolean = false;
    networkListener: any;
    domDirectionLTR = true;
    twoFaEnabled = false;
    currencySymbol: string = "SR ";
    accessToken: any;
    public vatCertificateFile: any;
    public companyCrFile: any;
    public nationalAddressFile: any;
    @Output() callSortByData: any = new EventEmitter();
    @Output() callConfigData: any = new EventEmitter();
    public eventObservable = new Subject<any>();
    public event: any = {};
    notificationCount: any;
    userName: any;
    userEmail: any;

    routerEventVariable: any;
    private field: string;

    dialogueWidth: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public cookieService: CookieService,
        public modalController: ModalController,
        private modalService: ModalService,
        private getPlatformService: GetPlatformService,
        @Inject(DOCUMENT) private document: Document,

    ) { }

    public encryptData(data: any) {
        try {
            var encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
            return encryptedData.toString();
        } catch (e) {
            console.log(e);
        }
    }

    public decryptData(encryptedData: string) {
        if (!encryptedData) {
            return null;
        }
        try {
            var bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
            var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

            return decryptedData;
        } catch (e) {
            console.log(e);
        }
    }

    public trimUrl(value:any){
        if(value){
            const tempUrl = value.trim().replace(/['™®©()\[\]{}<>]|[^\w\s-]|[\s]+/g, '-');
            let newUrl = tempUrl.replace(/-+/g, '-');
            newUrl = newUrl.replace(/^-+|-+$/g, '');
            return newUrl;

            //old regex
            //  return value.trim().replace(/[\s,()]+/g, '-').replace(/[^a-zA-Z0-9_]/, '-').replace(/®/g, '').replace(/©/,'');
        }
    }

    localStorageSet(key: string, value: any) {
        if (!isPlatformServer(this.platformId)) {
            localStorage.setItem(key, value);
        }
    }

    localStorageGet(key) {
        if (!isPlatformServer(this.platformId)) {
            return localStorage.getItem(key);
        }
        return null;
    }

    localStorageRemove(key) {
        if (!isPlatformServer(this.platformId)) {
            localStorage.removeItem(key);
        }
    }

    localStorageClear() {
        if (!isPlatformServer(this.platformId)) {
            const notificationToken = this.localStorageGet('notificationToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('from_login');
            localStorage.removeItem('latitude');
            localStorage.removeItem('longitude');
            localStorage.removeItem('mobile_no');
            localStorage.removeItem('shipping_address_id');
            localStorage.removeItem('twoFaData');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('uuid');
            if (notificationToken) {
                this.localStorageSet('notificationToken', notificationToken)
            }
        }
    }

    cookieSet(key: string, value: string) {
        if (!isPlatformServer(this.platformId)) {
            this.cookieService.set(key, value, null, null, null, null, 'Lax')
        }
    }

    cookieGet(key: string) {
        if (!isPlatformServer(this.platformId)) {
            return this.cookieService.get(key)
        }
        return '';

    }

    cookieDelete(key: string) {
        if (!isPlatformServer(this.platformId)) {
            this.cookieService.delete(key)
        }
    }
    cookieDeleteAll() {
        if (!isPlatformServer(this.platformId)) {
            this.cookieService.deleteAll()
        }
    }

    sortByData(data?) {
        this.callSortByData.emit({
            sort_by_params: data
        })
    }

    configData(data?) {
        this.callConfigData.emit({
            config_data: data
        })
    }

    public setEvent(event: string, data: any) {
        this.event = { 'event': event, 'data': data };
        this.eventObservable.next(this.event);
    }

    setData(val) {
        this.field = val;
    }

    getData() {
        return this.field;
    }

    checkNetworks() {
        if (this.networkListener) {
            this.networkListener.remove();
        }
        this.networkListener = Network.addListener('networkStatusChange', status => {
            if (status && status.connected) {
                this.isNetworkConnected = true;
                this.modalController.dismiss();
                this.isNoInternetModalOpen = false;
            } else {
                this.isNetworkConnected = false;
                this.noInternetModal()
            }
        });
    }

    noInternetModal() {
        if (this.isNoInternetModalOpen) {
            return
        }
        const options = {
            backdropDismiss: false,
            cssClass: 'no-internet-modal',
            component: NoInternetComponent
        }
        this.modalService.openModal(options)
        this.isNoInternetModalOpen = true;
    }

    tryAgain() {
        let networkStatus: any = Network.getStatus();
        networkStatus.then(data => {
            if (!this.getPlatformService.isDesktop) {
                if (data && data.connected) {
                    this.isNetworkConnected = true;
                    let isModalOpen;
                    this.modalController.getTop().then((data) => {
                        isModalOpen = data;
                        if (isModalOpen) {
                            this.modalController.dismiss();
                        }
                    });
                    this.isNoInternetModalOpen = false;
                } else {
                    this.isNetworkConnected = false;
                    this.noInternetModal()
                }
            }
        })
    }

    scrollToTop() {
        const element = this.document.querySelector('#main-content');
        if (element) {
            if (this.document.getElementsByClassName('ion-page') && this.document.getElementsByClassName('ion-page').length) {
                for (let i = 0; i < this.document.getElementsByClassName('ion-page').length; i++) {
                    this.document.getElementsByClassName('ion-page')[i].scrollIntoView({ behavior: 'smooth' })
                }

            }
        }
    }
}
