import { Injectable } from '@angular/core';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
    providedIn: 'root'
})
export class GetLatLongService {


    constructor(public platform: Platform,
        public nativeGeocoder: NativeGeocoder,
        public commonService: CommonService) {

    }

    async getLatLongLocation(address, id, addressFlag?) {

        return new Promise((resolve, reject) => {

            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: address }, (result, error) => {
                this.commonService.localStorageSet("latitude", result[0].geometry.location.lat())
                this.commonService.localStorageSet("longitude", result[0].geometry.location.lng())
                if (addressFlag == true) {
                    this.commonService.localStorageSet("shipping_address_id", id);
                    this.commonService.localStorageRemove("shipping_city_id");
                }
                else {
                    this.commonService.localStorageSet("shipping_city_id", id)
                    this.commonService.localStorageRemove("shipping_address_id");
                }
                resolve(result);

            });

        });

    }
}
