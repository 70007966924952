import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-return-order-request',
    templateUrl: './return-order-request.component.html',
    styleUrls: ['./return-order-request.component.scss'],
})
export class ReturnOrderRequestComponent implements OnInit {
    isLoading = false;
    message: string = "";
    reasonsList = [
        {
            id: 1,
            name: "Damaged"
        },
        {
            id: 2,
            name: "Other"
        },
    ];
    showHeader = false;
    selectInterface = { cssClass: 'custom-select-dd', size: 'cover' };
    selectedOrder: any;
    ordersList = [
        {
            order_id: '12910212',
            name: '128 Melody street, New York, N...',
        },
        {
            order_id: '12910213',
            name: '129 Melody street, New York, N...',
        },
        {
            order_id: '12910214',
            name: '127 Melody street, New York, N...',
        },
        {
            order_id: '12910215',
            name: '126 Melody street, New York, N...',
        },
    ];

    public orderData: any = [

        {
            supplier_id: 1,
            imageFile: [],
            imagePreview: [],
            name: 'Supplier name #1',
            orderTotal: 6600,
            orderSubTotal: 6300,
            orderDiscount: 998,
            orderVAT: 50,
            orderShipping: 200,
            product_data: [{
                product_id: 1,
                product_image: "/assets/icon/slider-image2.svg",
                product_title: "Metal Ceiling CT1782",
                product_qty: 1,
                product_is_sample: true,
                product_price: 40,
                product_currency: "SR",
                product_unit: "sqm",
                product_color: "Sed bibendum",
                product_format: "Planks",
                distributor: "Distributor #2",
                estimated_delivery_date: "9/9/2021",
                attributes: [
                    {
                        name: "Panel size",
                        values: [
                            {
                                id: 1,
                                value: "2’ x 4’"
                            },
                        ]
                    },
                    {
                        name: "Colors",
                        values: [
                            {
                                id: 1,
                                value: "Sed bibendum"
                            },
                        ]
                    },
                ],
            },
            {
                product_id: 2,
                product_image: "/assets/icon/slider-image1.svg",
                product_title: "Lorem ipsum dolor sit",
                product_qty: 50,
                product_is_sample: false,
                product_price: 40,
                product_currency: "SR",
                product_unit: "sqm",
                product_color: "Sed bibendum",
                product_format: "Planks",
                distributor: "Distributor #2",
                estimated_delivery_date: "9/9/2021",
                attributes: [
                    {
                        name: "Panel size",
                        values: [
                            {
                                id: 1,
                                value: "2’ x 4’"
                            },
                        ]
                    },
                    {
                        name: "Colors",
                        values: [
                            {
                                id: 1,
                                value: "Sed bibendum"
                            },
                        ]
                    },
                ],
            },
            {
                product_id: 3,
                product_image: "/assets/icon/slider-image3.svg",
                product_title: "Amet adipiscing",
                product_qty: 10,
                product_is_sample: false,
                product_price: 30,
                product_currency: "SR",
                product_unit: "sqm",
                product_color: "Sed bibendum",
                product_format: "Planks",
                distributor: "Distributor #2",
                estimated_delivery_date: "9/9/2021",
                attributes: [
                    {
                        name: "Panel size",
                        values: [
                            {
                                id: 1,
                                value: "2’ x 4’"
                            },
                        ]
                    },
                    {
                        name: "Colors",
                        values: [
                            {
                                id: 1,
                                value: "Sed bibendum"
                            },
                        ]
                    },
                ],
            }]
        },
        {
            supplier_id: 2,
            imageFile: [],
            imagePreview: [],
            name: 'Supplier name #1',
            orderTotal: 500,
            orderSubTotal: 400,
            orderDiscount: 0,
            orderVAT: 0,
            orderShipping: 100,
            product_data: [{
                product_id: 1,
                product_image: "/assets/icon/slider-image2.svg",
                product_title: "Metal Ceiling CT1782",
                product_qty: 10,
                product_is_sample: true,
                product_price: 40,
                product_currency: "SR",
                product_unit: "sqm",
                product_color: "Sed bibendum",
                product_format: "Planks",
                distributor: "Distributor #2",
                estimated_delivery_date: "9/9/2021",
                attributes: [
                    {
                        name: "Panel size",
                        values: [
                            {
                                id: 1,
                                value: "2’ x 4’"
                            },
                        ]
                    },
                    {
                        name: "Colors",
                        values: [
                            {
                                id: 1,
                                value: "Sed bibendum"
                            },
                        ]
                    },
                ],
            },
            ]
        }
    ]
    constructor(
        private router: Router,
        public commonService: CommonService,
        private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private toastService: ToastService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        if (this.router.url.includes("return-request")) {
            this.showHeader = true;
        }
    }

    orderChanged(event) {

    }

    goBack() {
        this.modalService.dismissModal()
    }

    upload(files, data?) {
        files.preventDefault();
        files.stopPropagation();
        let imageLimitMsg = "Can upload only 5 images";
        if (files && files['target'] && files['target']['files'] && files['target']['files'].length > 0) {
            const max_size = 5242880;
            for (let i = 0; i < files['target']['files'].length; i++) {
                if (files['target']['files'][i].size > max_size) {
                    this.message =
                        'Maximum size allowed is ' + max_size / 1048576 + 'MB';
                    files.target.value = null;
                    return false;
                }
                if ((files['target']['files'][i].type).includes(".image") || (files['target']['files'][i].type).includes("image/png") || (files['target']['files'][i].type).includes("image/gif") || (files['target']['files'][i].type).includes("image/jpeg") || (files['target']['files'][i].type).includes("image")) {
                    this.message = null;
                    const reader = new FileReader();
                    if (data && data.imageFile && data.imageFile.length < 5) {
                        data.imageFile.push(files['target']['files'][i])
                    }
                    reader.readAsDataURL(files['target']['files'][i]);
                    reader.onload = (event: any) => {
                        if (data && data.imagePreview && data.imagePreview.length < 5) {
                            data.imagePreview.push(event.target.result)
                        } else {
                            this.toastService.displayToast("Only 5 images allowed", null, 'danger')
                        }
                    };
                    const obj = {
                        fileUploaded: true
                    }
                }
                else {
                    this.message = 'Only images are allowed';
                    files.target.value = null;
                    return false;
                }
            }

        }
        files.target.value = '';
    }

    removeImage(index, data) {
        data.imageFile.splice(index, 1);
        data.imagePreview.splice(index, 1);

    }
}
