import { Directive, HostListener, Input } from '@angular/core';

/*
  Generated class for the LimitTo directive.

  See https://angular.io/docs/ts/latest/api/core/index/DirectiveMetadata-class.html
  for more info on Angular 2 Directives.
*/
@Directive({
    selector: '[limit-to]' // Attribute selector
})
export class LimitTo {

    @Input('limit-to') limitTo;

    constructor() {

    }
    //HostListener decorator handle event handlers for input (onKeyPress)
    @HostListener('keypress', ['$event'])
    onkeypress(ev) {
        const limit = +this.limitTo; //convert to number
        // once it reaches the limit set, stop propgating event.
        if (ev.target.value.length === limit) {
            ev.preventDefault();
        }
    }
}