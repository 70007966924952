<ng-container *ngIf="getPlatformService.isDesktop">
	<div class="main-title" *ngIf="!hideTitle">
		<h2>{{'notificationComponent.notifications'| translate}}</h2>
	</div>

	<div class="listing-table">
		<table class="table" *ngIf="array && array.length">
			<thead>
				<th class="column-heading mw-250 text-left">
					{{"notificationComponent.notifications"
					| translate}}</th>

				<th class="column-heading mw-120 text-left">
					{{"notificationComponent.time"
					| translate}}</th>

				<th class="column-heading ion-text-center arrow-col"></th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let data of array;index as i" class="table-row" (click)="navigationOfNotificationWeb(data)">
					<td class="label1 color-app-black pl-10 text-left" [ngClass]="data.is_read == false ? 'un-read':'read'">
						{{commonService.domDirectionLTR ? data.title : data.titleAr}}
					</td>

					<td class="label1 color-app-black text-left" [ngClass]="data.is_read == false ? 'un-read':'read'">
						{{data.time | date :'MMM dd, yyyy'}}</td>

					<td>
						<div class="right-arrow" [ngClass]="commonService.domDirectionLTR ? 'justify-end' : ' rotate-arrow justify-start'">
							<img src="./assets/icon/rightArrow.svg" alt="righta-arrow" [ngClass]="data.is_read == false ? 'un-read':'read'">
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="!array.length">
			{{'notificationComponent.noNotifications' | translate}}
		</div>
		<div class="pagination-wrapper" *ngIf="array && array.length">
			<ion-row class="pagination-div">
				<ion-col size="4" class="perpage-col">
					<ion-list class="perpage-dropdown">
						<ion-item class="ion-card" lines="none">
							<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" interface="popover"
							 [interfaceOptions]="perPageInterface" (ionChange)="selectPageLength($event)" [(ngModel)]="pageSize">
								<ion-select-option value="10">10</ion-select-option>
								<ion-select-option value="20">20</ion-select-option>
							</ion-select>
						</ion-item>
						<ion-label class="perpage-title">{{"ordersComponent.per_page" | translate}}</ion-label>
					</ion-list>
				</ion-col>
				<ion-col size="8" class="pagination-col">
					<app-tile class="grid-tile" *ngFor="let page of array | paginate :
            { itemsPerPage: pageSize, currentPage: pageNo, totalItems: count };
            let i = index"
					 [current]="page"></app-tile>
					<pagination-controls class="paginator" [ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					 previousLabel="{{'ordersComponent.prev' | translate}}" nextLabel="{{'ordersComponent.next' | translate}}"
					 (pageChange)="onChange($event)"></pagination-controls>
				</ion-col>
			</ion-row>
		</div>
	</div>
</ng-container>



<ng-container *ngIf="!getPlatformService.isDesktop">
	<ion-header class="address-header" *ngIf="!hideTitle" mode="md">
		<ion-toolbar mode="md">
			<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
			<ion-title mode="ios">
				<div class="notifications-header">
					{{'notificationComponent.notifications'| translate}}
				</div>
			</ion-title>
		</ion-toolbar>
	</ion-header>
	<ion-content class="notification-ion-content" *ngIf="from != 'dashboard'">
		<div [ngClass]="!hideTitle ? 'notification-content': null" *ngIf="array.length">
			<ng-container *ngIf="!isLoading">
				<ion-row *ngFor="let data of array" (click)="navigationOfNotification(data)">
					<ion-col class="text-div p-0" size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8">
						<p class="label1 color-app-black" [ngClass]="data.is_read == false ? 'un-read':'read'">
							{{commonService.domDirectionLTR ? data.title : data.titleAr}}</p>
						<p class="label2 color-app-grey2" [ngClass]="data.is_read == false ? 'un-read':'read'">{{data.time |
							date :'MMM dd, yyyy'}}</p>
					</ion-col>
					<div class="horizontal-line mv-15"></div>
				</ion-row>
			</ng-container>
			<ng-container *ngIf="isLoading">
				<ion-row *ngFor="let data of array">
					<ion-col class="time-div p-0" size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8">
						<ion-skeleton-text class="mt-10 mb-10" [animated]="true"></ion-skeleton-text>
					</ion-col>
				</ion-row>
			</ng-container>
			<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
				<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular" loadingText="{{'lodingMoreData' | translate}}">
				</ion-infinite-scroll-content>
			</ion-infinite-scroll>
		</div>
		<div *ngIf="!isLoading && !array.length">
			<div class="mobile-no-notification">
				<p class="label-3 color-app-black ">{{'notificationComponent.noNotifications' | translate}}</p>
			</div>
		</div>
	</ion-content>

	<div class="notification-ion-content" *ngIf="from == 'dashboard'">
		<div [ngClass]="!hideTitle ? 'notification-content': null" *ngIf="array.length">
			<ng-container *ngIf="!isLoading">
				<ion-row *ngFor="let data of array" (click)="navigationOfNotification(data)">
					<ion-col class="text-div p-0" size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8">
						<p class="label1 color-app-black"> {{commonService.domDirectionLTR ? data.title : data.titleAr}}</p>
					</ion-col>
					<ion-col class="time-div p-0" size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4">
						<p class="label2 color-app-grey2">{{data.time | date :'MMM dd, yyyy'}}</p>
					</ion-col>
					<div class="horizontal-line mv-15"></div>
				</ion-row>
			</ng-container>
			<ng-container *ngIf="isLoading">
				<ion-row *ngFor="let data of array">
					<ion-col class="time-div p-0" size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8">
						<ion-skeleton-text class="mt-10 mb-10" [animated]="true"></ion-skeleton-text>
					</ion-col>
				</ion-row>
			</ng-container>
		</div>
		<div *ngIf="!isLoading && !array.length">
			<div class="mobile-no-notification">
				<p class="label-3 color-app-black ">{{'notificationComponent.noNotifications' | translate}}</p>
			</div>
		</div>
	</div>
</ng-container>