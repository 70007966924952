import { Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';


@Injectable({
    providedIn: 'root'
})
export class CapacitorCookieService {

    getCookies = () => {
        return document.cookie;
    };

    setCookie(key, value) {
        document.cookie = key + '=' + value;
    };

    setCapacitorCookie = async () => {
        await CapacitorCookies.setCookie({
            // url: 'http://example.com',
            key: 'language',
            value: 'en',
        });
    };

    deleteCookie = async () => {
        await CapacitorCookies.deleteCookie({
            url: 'https://example.com',
            key: 'language',
        });
    };

    clearCookiesOnUrl = async () => {
        await CapacitorCookies.clearCookies({
            url: 'https://example.com',
        });
    };

    clearAllCookies = async () => {
        await CapacitorCookies.clearAllCookies();
    };
}