import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavController, NavParams } from "@ionic/angular";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { ToastService } from "src/app/services/toast.service";
import { GetPlatformService } from "../../services/get-platform.service";
import { URLS } from "./../../_config/api.config";
import { ApiService } from "./../../services/api-data.service";
import { CommonService } from "./../../services/common.service";
import { EditProfileComponent } from "./../edit-profile/edit-profile.component";
import { isPlatformServer } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
  providers: [NavParams],
})
export class MyAccountComponent implements OnInit {
  componentData = [
    {
      section_name: "myAccountComponent.accountInformation",
      section_btn: "Editprofile",
      section_content: "",
      route: "",
      id: "0",
    },
    {
      section_name: "myAccountComponent.addresses",
      section_btn: "myAccountComponent.manageAddr",
      section_content: "",
      route: "",
      id: "1",
    },
  ];

  mobComponentData = [
    {
      section_name: "myAccountComponent.accountInfo",
      section_btn: "",
      section_content: "",
      route: "",
      id: "0",
    },
    {
      section_name: "accountInfoComponent.crNumber",
      section_btn: "",
      section_content: "",
      route: "",
      id: "1",
    },
    {
      section_name: "accountInfoComponent.vatNumber",
      section_btn: "",
      section_content: "",
      route: "",
      id: "3",
    },
    {
      section_name: "accountInfoComponent.nationalAddress",
      section_btn: "",
      section_content: "",
      route: "",
      id: "4",
    },
    {
      section_name: "myAccountComponent.selectInterests",
      section_btn: "",
      section_content: "",
      route: "",
      id: "2",
    },
  ];
  registrationCheckbox = [];
  selectedIntrestList: any;
  customer_cr_no: any;
  customer_vat_no: any;
  customerData: any;
  public isLoading = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    public commonService: CommonService,
    private navParams: NavParams,
    private dataService: ApiService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    public navCtrl: NavController,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public getPlatformService: GetPlatformService
  ) {}

  ngOnInit() {
    this.getCustomerDetails();
    this.getProductCategories();
  }

  getCustomerDetails() {
    this.isLoading = true;

    this.subscription.add(
      this.dataService.get(URLS.getDefaultAddressList).subscribe(
        (data) => {
          if (data["data"] && data["code"] == 200) {
            this.isLoading = false;
            this.customerData = data["data"];
            this.customer_cr_no = this.customerData.customer_cr_no;
            this.customer_vat_no = this.customerData.customer_vat_no;

            let selectedIntrest = [];
            if (this.customerData.customer_interests) {
              this.customerData.customer_interests.forEach((element) => {
                selectedIntrest.push(element.group_id);
              });
              this.selectedIntrestList = selectedIntrest;
            }

            this.registrationCheckbox.forEach((element) => {
              element.isChecked = false;
            });
            if (this.selectedIntrestList) {
              setTimeout(() => {
                this.registrationCheckbox.forEach((element) => {
                  this.selectedIntrestList.forEach((element2) => {
                    if (element2 == element.value) {
                      element.isChecked = true;
                    }
                  });
                });
              }, 300);
            }
          } else {
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
        }
      )
    );
  }

  getProductCategories() {
    this.isLoading = true;
    this.subscription.add(
      this.dataService.get(URLS.getProductCategories).subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.isLoading = false;
            let listData = data["data"]["result"];
            listData.forEach((element) => {
              let obj = {
                label: element.group_name_en,
                label_ar: element.group_name_ar,
                value: element.group_id,
                isChecked: false,
              };
              this.registrationCheckbox.push(obj);
            });

            if (this.selectedIntrestList) {
              this.registrationCheckbox.forEach((element) => {
                this.selectedIntrestList.forEach((element2) => {
                  if (element2 == element.value) {
                    element.isChecked = true;
                  }
                });
              });
            }
          } else {
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
        }
      )
    );
  }

  selectCheckbox(item) {
    // if (item["isChecked"] == false) {
    //     item["isChecked"] = true;
    //     this.selectedIntrestList = item.value;
    // } else {
    //     item["isChecked"] = false;
    //     this.selectedIntrestList = null
    // }
  }

  goBack() {
    this.navCtrl.back({ animated: false });
  }

  manageAddressClick() {
    this.router.navigate(['/'+this.translate.currentLang+"/home/account-details/my-addresses"]);
  }

  getFileName(url) {
    return url.split("/").pop();
  }

  openFile(url) {
    if (!isPlatformServer(this.platformId)) {
      window.open(url);
    }
  }

  editProfileOpen() {
    let options: any = {
      id: "registerModal",
      backdropDismiss: false,
      componentProps: { mobileView: true },
    };
    options["component"] = EditProfileComponent;
    let modal: any = this.modalService.openModal(options);
    modal.then((data) => {
      if (data && data.data == true) {
        this.getCustomerDetails();
      }
    });
  }

  editProfileWebOpen() {
    let options: any = {
      id: "registerModal",
      backdropDismiss: false,
    };
    options["component"] = EditProfileComponent;
    let modal: any = this.modalService.openModal(options);
    modal.then((data) => {
      if (data && data.data == true) {
        this.getCustomerDetails();
      }
    });
  }
}
