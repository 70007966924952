<div *ngIf="toastService.isVisible && !toastService.modalPopupAlert && !toastService.isCartPage">
    <div class="toast-container header-container">
        <div class="div-container">
            <div class="msg-img mh-10">
                <img *ngIf="toastService.msgType == 'danger'" src="../../../assets/icon/error-close.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'warning'" src="../../../assets/icon/error-warning.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'success'" src="../../../assets/icon/error-success.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'info'" src="../../../assets/icon/error-info.svg" alt="Juman"
                    height="100%" width="100%">
            </div>
            <div class="width-lbl">
                <p class="label-msg">{{toastService.msgText}}</p>
            </div>
            <div class="msg-img mr-10">
                <img src="../../../assets/icon/close-error-popup.svg" alt="Juman" height="100%" width="100%"
                    class="pointer" (click)="toastClose()">
            </div>
        </div>
    </div>
</div>

<!-- ---------------------- Popup toast design ---------------------------------------- -->
<div *ngIf="toastService.modalPopupAlert" class="toast-container2 header-container">
    <div class="div-container2">
        <div class="msg-img mh-10">
            <img *ngIf="toastService.msgType == 'danger'" src="../../../assets/icon/error-close.svg" alt="Juman"
                height="100%" width="100%">
            <img *ngIf="toastService.msgType == 'warning'" src="../../../assets/icon/error-warning.svg" alt="Juman"
                height="100%" width="100%">
            <img *ngIf="toastService.msgType == 'success'" src="../../../assets/icon/error-success.svg" alt="Juman"
                height="100%" width="100%">
            <img *ngIf="toastService.msgType == 'info'" src="../../../assets/icon/error-info.svg" alt="Juman"
                height="100%" width="100%">
        </div>
        <div class="width-lbl">
            <p class="label-msg">{{toastService.msgText}}</p>
        </div>
        <div class="msg-img mr-10">
            <img src="../../../assets/icon/close-error-popup.svg" alt="Juman" height="100%" width="100%" class="pointer"
                (click)="toastClose()">
        </div>
    </div>
</div>

<!-- ---------------------- Cart toast design ---------------------------------------- -->
<div *ngIf="toastService.isVisible && toastService.isCartPage">
    <div class="cart-toast-container header-container">
        <div class="div-container">
            <div class="msg-img mh-10">
                <img *ngIf="toastService.msgType == 'danger'" src="../../../assets/icon/error-close.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'warning'" src="../../../assets/icon/error-warning.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'success'" src="../../../assets/icon/error-success.svg" alt="Juman"
                    height="100%" width="100%">
                <img *ngIf="toastService.msgType == 'info'" src="../../../assets/icon/error-info.svg" alt="Juman"
                    height="100%" width="100%">
            </div>
            <div class="width-lbl">
                <p class="label-msg">{{toastService.msgText}}</p>
            </div>
            <div class="msg-img mr-10">
                <img src="../../../assets/icon/close-error-popup.svg" alt="Juman" height="100%" width="100%"
                    class="pointer" (click)="toastClose()">
            </div>
        </div>
    </div>
</div>