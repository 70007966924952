import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { CustomValidator } from 'src/validators/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';
import { TwoFactorAuthenticationComponent } from '../two-factor-authentication/two-factor-authentication.component';
import { LoginPage } from './../../pages/login/login.page';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    @ViewChild('emailInput', { static: false }) emailInput!: IonInput;

    public twoFaDetails: any;
    public uuid: any;
    public mobile_no: any;
    public passwordType = 'password';
    public repeatPasswordType = 'password';
    public validation_messages = {
        email: [
            { type: 'required', message: 'forgotPasswordComponent.emailRequired' },
            { type: 'invalidEmail', message: 'forgotPasswordComponent.emailInvalid' }
        ],
        password: [
            { type: 'required', message: 'forgotPasswordComponent.newPassword' },
            { type: 'invalidString', message: 'forgotPasswordComponent.invalidStringPassword' },
            { type: 'invalidPassword', message: 'forgotPasswordComponent.invalidPassword' }
        ],
        repeatPassword: [
            { type: 'required', message: 'forgotPasswordComponent.confirmPassword' },
            { type: 'invalidString', message: 'forgotPasswordComponent.invalidStringPassword' },
            { type: 'invalidPassword', message: 'registerComponent.repeatPasswordInvalid' }
        ],
    }

    private subscription: Subscription = new Subscription();

    changePasswordFromGroup: FormGroup;

    step: any = 1;
    public isLoading = false;
    constructor(
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private platform: Platform
    ) {
    }

    ngOnInit() {
        this.uuid = this.commonService.localStorageGet("uuid");
        this.mobile_no = this.commonService.localStorageGet("mobile_no");
        setTimeout(() => {
            this.emailInput.setFocus()
        }, 300)
        this.changePasswordFromGroup = this.initializechangePasswordFromGroup();
        this.step = this.commonService.localStorageGet('step') ? this.commonService.localStorageGet('step') : 1;
        const decrypted2FaData = this.commonService.localStorageGet("twoFaData");
        this.twoFaDetails = this.commonService.decryptData(decrypted2FaData);
        this.twoFaDetails = JSON.parse(this.twoFaDetails);
    }

    get f1() { return this.changePasswordFromGroup.controls; }

    closeModal() {
        this.modalService.dismissModal();
        if (this.step == 2) {
            this.commonService.localStorageRemove("from_forgotPassword");
            this.commonService.localStorageRemove("uuid");
            this.commonService.localStorageRemove("mobile_no");
            this.commonService.localStorageRemove("step");
            this.commonService.localStorageRemove("twoFaData");
        }
    }

    initializechangePasswordFromGroup() {
        return this.formBuilder.group({
            email: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.emailorMobileValidator]),
            }),
            password: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
            }),
            repeatPassword: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.matchPassword]),
            })
        })
    }

    cancelModal() {
        this.modalService.dismissModal();
        if (this.step == 2) {
            this.commonService.localStorageRemove("from_forgotPassword");
            this.commonService.localStorageRemove("uuid");
            this.commonService.localStorageRemove("mobile_no");
            this.commonService.localStorageRemove("step");
            this.commonService.localStorageRemove("twoFaData");
        }

        let options: any = {
            id: "loginModal",
            backdropDismiss: false
        };
        options['component'] = LoginPage;
        this.modalService.openModal(options);
    }

    cancelModalResetPassword() {
        this.modalService.dismissModal();
        if (this.step == 2) {
            this.commonService.localStorageRemove("from_forgotPassword");
            this.commonService.localStorageRemove("uuid");
            this.commonService.localStorageRemove("mobile_no");
            this.commonService.localStorageRemove("step");
            this.commonService.localStorageRemove("twoFaData");
        }

        this.commonService.localStorageSet('step', 1);
    }

    openTwoFaModal() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (!this.changePasswordFromGroup.controls.email.valid) return;

        if (this.changePasswordFromGroup.controls.email.valid) {

            const generateOtpParams = {
                "user_name": this.changePasswordFromGroup.controls.email.value,
                "purpose_type": "FORGOT_PASSWORD",
                "platform": this.platform.is("mobileweb") || this.platform.is("ipad") ? "WEB" : this.platform.is("android") ? "ANDROID" : this.platform.is("ios") ? "IOS" : "WEB"
            }
            this.commonService.localStorageSet('from_forgotPassword', true);
            this.isLoading = true;
            this.subscription.add(
                this.dataService.post(URLS.generateOtp, generateOtpParams).subscribe(data => {
                    if (data['code'] == 200) {
                        this.closeModal();
                        this.changePasswordFromGroup.controls.email.reset();
                        if (data['data']) {
                            if (data['data']['uuid'] && data['data']['mobile_no']) {
                                this.commonService.localStorageSet('uuid', data['data']['uuid']);
                                this.commonService.localStorageSet('mobile_no', data['data']['mobile_no']);
                                this.commonService.localStorageSet('step', 2);
                                setTimeout(() => {
                                    let options: any = {
                                        id: "twoFaModal",
                                        backdropDismiss: false
                                    };
                                    options['component'] = TwoFactorAuthenticationComponent;
                                    this.modalService.openModal(options)
                                }, 300);
                            }
                        }
                    } else {
                        this.toastService.displayToast(data['message'], null, 'danger');
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toastService.displayToast(error['message'], null, 'danger');
                })
            )
        } else {
            this.changePasswordFromGroup.controls['email'].markAsTouched();
            return;
        }
    }


    resetPassword() {

        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (!this.changePasswordFromGroup.controls.password.valid || !this.changePasswordFromGroup.controls.repeatPassword.valid) return;

        if (this.changePasswordFromGroup.controls.password.valid && this.changePasswordFromGroup.controls.repeatPassword.valid) {
            const params = {
                "mobile_no": this.mobile_no,
                "uuid": this.uuid,
                "new_password": this.changePasswordFromGroup.controls.password.value,
                "otp": +this.twoFaDetails['otp'],
                "purpose_type": "FORGOT_PASSWORD",
            }
            this.isLoading = true;
            this.subscription.add(
                this.dataService.put(URLS.resetPassword, params).subscribe(data => {
                    if (data['code'] == 200) {
                        this.commonService.localStorageSet('step', 1);
                        this.cancelModal();
                    } else {
                        this.toastService.displayToast(data['message'], null, 'danger');
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.toastService.displayToast(error['message'], null, 'danger');
                })
            )
        } else {
            this.changePasswordFromGroup.controls['password'].markAsTouched();
            this.changePasswordFromGroup.controls['repeatPassword'].markAsTouched();
            return;
        }
    }
}
