<ion-header>
    <ion-toolbar mode="md">
        <div class="login-desktop-header ion-text-center" *ngIf="getPlatformService.isDesktop">
            {{'Login' | translate}}
        </div>
        <div class="mobile-back text-left ml-10" (click)="closeModal()" *ngIf="!getPlatformService.isDesktop">
            <img src="assets/icon/leftArrow.svg" alt="Juman">
        </div>
        <div class="text-right" [ngClass]="[ commonService.domDirectionLTR ? 'modal-close' : 'modal-close-arabic']"
            (click)="closeModal()" *ngIf="getPlatformService.isDesktop">
            <img src="assets/icon/close.svg" alt="Close" height="100%" width="100%">
        </div>
    </ion-toolbar>
</ion-header>
<app-loader *ngIf="isLoading && !getPlatformService.isDesktop "></app-loader>

<ion-content>
    <div class="login-container" [ngClass]="[ getPlatformService.isDesktop ? 'login-container-desktop' : '']">
        <div class="login-header" *ngIf="!getPlatformService.isDesktop">
            {{ 'Login to Juman' | translate }}
        </div>
        <form id="loginForm" [formGroup]="loginFromGroup">
            <div class="email common-input">
                <ion-item lines="none" mode="md">
                    <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/email-icon.svg"
                        alt="Juman">
                    <ion-label position="floating" *ngIf="getPlatformService.isDesktop">
                        {{'EMAIL' | translate }} / {{'MOBILE' |translate }}
                    </ion-label>
                    <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">
                        {{'Email' | translate }} / {{'mobile' |translate }}
                    </ion-label>
                    <ion-input autocomplete="new-email" formControlName="email" #emailInput>
                    </ion-input>
                </ion-item>
                <ng-container
                    *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': loginFromGroup} }">
                </ng-container>
            </div>
            <div class="password common-input">
                <ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
                    (click)="passwordType = 'password'"
                    [ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
                <ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
                    (click)="passwordType = 'text'"
                    [ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
                <ion-item lines="none" mode="md">
                    <img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/password-icon.svg"
                        alt="juman">
                    <ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'PASSWORD' | translate
                        }}</ion-label>
                    <ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Password' | translate
                        }}</ion-label>
                    <ion-input class="registration-pass" autocomplete="new-password" type="{{passwordType}}"
                        clearOnEdit="false" type="password" (keydown.space)="$event.preventDefault();"
                        formControlName="password"></ion-input>
                </ion-item>
                <ng-container
                    *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': loginFromGroup} }">
                </ng-container>
            </div>
        </form>
        <div class="remember-me pt-10">
            <div lines="none" mode="md" class="checkbox-item">
                <ion-checkbox slot="start" class="mr-10 checkbox" [(ngModel)]="rememberMe"></ion-checkbox>
                <ion-label class="checkbox-label pointer" (click)="rememberMeClick()">{{'rememberMe' | translate
                    }}</ion-label>
            </div>
        </div>
        <div class="forgot-password text-right">
            <span (click)="forgotPasswordOpen()">{{ 'Forgot password?' | translate }}</span>
        </div>
        <div class="login-bottom-container" *ngIf="getPlatformService.isDesktop">
            <div class="cancel pr-40" (click)="closeModal()">
                {{ 'Cancel' | translate }}
            </div>
            <div class="login-button">
                <button type="submit" (click)="login()" form="loginForm" [disabled]="isLoading">
                    <ion-spinner class="spinner-btn" name="lines-sharp-small" *ngIf="isLoading"></ion-spinner>
                    {{'Login' | translate}}
                </button>
            </div>
        </div>
    </div>
    <ng-template let-formObject #validationMessages>
        <ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
            <ion-text color="danger" class="error-msg"
                *ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
                {{validation.message | translate}}
            </ion-text>
        </ng-container>
    </ng-template>
</ion-content>

<ion-footer *ngIf="!getPlatformService.isDesktop">
    <div class="login-button ion-text-center mh-10">
        <app-common-button color="#FFFFFF" background="#38C2D4" title="{{'Login'|translate}}" [isIcon]="false"
            [isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
            [specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="login()">
        </app-common-button>
    </div>
    <div class="register-container ion-text-center">
        {{'Don’t have an account yet?' | translate }} <span class="register pl-5" (click)="openRegisterModal()">
            {{'Register'|translate }}
            <span class="right-icon" (click)="openRegisterModal()">
                <img src="assets/icon/right-arrow.svg" alt="Juman" height="100%" width="100%">
            </span>
        </span>
    </div>
</ion-footer>

<ion-footer *ngIf="getPlatformService.isDesktop">
    <div class="register-container ion-text-center"
        [ngClass]="[ getPlatformService.isDesktop ? 'register-container-desktop' : '']">
        {{'Don’t have an account yet?' | translate }} <span class="register pl-10" (click)="openRegisterModal()">{{
            'Register'
            |
            translate }}<span class="right-icon"
                [ngClass]="[ getPlatformService.isDesktop ? 'right-icon-desktop' : '']"><img
                    src="assets/icon/right-arrow.svg" alt="Juman" height="100%" width="100%"></span></span>
    </div>
</ion-footer>