import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ToastService } from '../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit {
    categoryList: any = [];
    menuOptions2: any = [];
    isCatLoading = false;
    @Input() menuVisibility;
    @ViewChild('menu') menu: ElementRef;
    menuHeight = 0;
    @Output() public childEvent = new EventEmitter();
    private subscription: Subscription = new Subscription();
    public items = [];
    public isLoading = false;
    selectedGroupId: number = 1;
    selectedCategoryId: number = 1;
    groupImage: string = null;
    isView: boolean = false;
    currentLang = this.translate.currentLang;
    public selectedGroupNameEn: any;
    public selectedGroupNameAr: any;
    public timeoutId: any;
    public categoryTimeoutId: any;
    constructor(
        public router: Router,
        private dataService: ApiService,
        private toastService: ToastService,
        public commonService: CommonService,
        private translate: TranslateService,
        private navCtrl: NavController) { }

    ngOnInit() {
        this.getGroupList();
    }

    // hide menu on category clicks
    hideMenu() {
        this.menuVisibility = false;
        this.childEvent.emit(this.menuVisibility);
    }

    async goToProductListing(data) {
        this.hideMenu();
        let cat_id = data.category_id;
        let catnameUrl: any = this.commonService.trimUrl(data.category_name_en);
        await this.navCtrl.navigateForward('/' + this.currentLang + '/product-listing/' + catnameUrl + "/" + cat_id, {
            animated: false, "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.category_name_en : data.category_name_ar,
                "url": '/product-listing/' + catnameUrl + "/" + cat_id,
                "id": data.category_id,
                "ar_label": data.category_ar,
                "banner_img": data.category_image_url
            }
        });
    }

    notifyMe() {
        this.menuHeight = this.menu.nativeElement.offsetHeight;
    }

    getGroupList() {
        this.subscription.add(
            this.dataService.get(URLS.allGroupList).subscribe(data => {
                if (data['code'] == 200) {
                    this.items = [];
                    let groupData = data['data']['result'];
                    this.items = groupData;
                    this.selectedGroupId = this.items[0]['group_id'];
                    this.selectedGroupNameEn = this.items[0]['group_name_en'];
                    this.selectedGroupNameAr = this.items[0]['group_name_ar'];
                    this.getCategoryList();
                } 
            }, error => {
            })
        )

    }
    getProductCategories() {
        this.isCatLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getProductCategories).subscribe(data => {
                if (data['code'] == 200) {
                    this.isCatLoading = false;
                    this.items = [];
                    let categoryData = data['data']['result'];
                    categoryData.forEach((element, i) => {
                        let childData = [];
                        if (element.children && element.children.length) {
                            element.children.forEach(child => {
                                let childObj = {
                                    category: child.category_name_en ? child.category_name_en : "",
                                    category_ar: child.category_name_ar ? child.category_name_ar : "",
                                    category_id: child.category_id ? child.category_id : "",
                                    category_image_url: child.category_image_url ? child.category_image_url : ""
                                }
                                childData.push(childObj);
                            });
                        }
                        let obj = {
                            group_id: element.group_id ? element.group_id : "",
                            heading: element.group_name_en ? element.group_name_en : "",
                            heading_ar: element.group_name_ar ? element.group_name_ar : "",
                            options: childData
                        }
                        this.items.push(obj);
                    });
                } else {
                    this.isCatLoading = false;
                }
            }, error => {
                this.isCatLoading = false;
            })
        )
    }

    goToCategory(data) {
        let grp_id = data.group_id;
        let catnameUrl: any = this.commonService.trimUrl(data.heading ? data.heading : data.product_name_en);
        this.router.navigateByUrl('/' + this.currentLang + '/category-listing/' + catnameUrl + '/' + grp_id, {
            "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.heading : data.heading_ar,
                "url": '/' + this.currentLang + '/category-listing/' + catnameUrl + '/' + grp_id
            }
        });
    }

    getCategoryList() {
        let param = {};
        let group_Id = [];
        group_Id.push(this.selectedGroupId);
        param["group_id"] = group_Id;
        param["page"] = 1;
        param["size"] = 9;
        this.isView = false;
        this.categoryList = [];
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.categoryListFilter, param).subscribe(data => {
                if (data['code'] == 200) {
                    this.isView = data['data']['enable_next']
                    let categorydata = data['data']['result'];
                    if(categorydata.length && categorydata[0]['category_id']){
                        this.selectedCategoryId = categorydata[0]['category_id']
                    } else {
                        this.selectedCategoryId = null
                    }
                    if(categorydata.length && categorydata[0]['category_image_url']){
                        this.groupImage = categorydata[0]['category_image_url']
                    } else {
                        this.groupImage = null;
                    }
                    this.categoryList = categorydata;
                    this.categoryList.forEach(element => {
                        if(element.category_name_en){
                            element['category_name_en'] = element.category_name_en.charAt(0).toUpperCase() + element.category_name_en.slice(1);
                        }
                    });
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                } else {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                }
            }, error => {
                setTimeout(() => {
                    this.isLoading = false;
                }, 200);
            })
        )
    }

    mouseEnter(menu){
        this.timeoutId = setTimeout(() =>{
            this.catItemChange(menu);
        }, 300)
    }

    mouseLeave() {
        clearTimeout(this.timeoutId);
    }

    catItemChange(val) {
        this.selectedGroupId = val.group_id;
        this.selectedGroupNameEn = val['group_name_en'];
        this.selectedGroupNameAr = val['group_name_ar'];
        this.getCategoryList();
    }

    async goToCategoryListing(data?) {
        let grp_id = data.group_id;
        let catnameUrl :any = this.commonService.trimUrl(this.selectedGroupNameEn);
        let breadcrumbCatNameUrl = this.commonService.trimUrl(this.commonService.domDirectionLTR ? this.selectedGroupNameEn : this.selectedGroupNameAr);
        let currentLang = this.translate.currentLang;    
        this.navCtrl.navigateForward('/'+currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
        {
            animated: false,
            "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? this.selectedGroupNameEn : this.selectedGroupNameAr,
                "url": '/'+currentLang + '/category-listing/' + breadcrumbCatNameUrl + '/' + grp_id,
            },
        });

    }

    async goToGroup(data){
        let grp_id = data.group_id;
        let catnameUrl :any = this.commonService.trimUrl(data.group_name_en);
        let currentLang = this.translate.currentLang;    
        this.navCtrl.navigateForward('/'+currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
        {
            animated: false,
            "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.group_name_en : data.group_name_ar,
                "url": '/'+currentLang + '/category-listing/' + catnameUrl + '/' + grp_id,
            },
        });
    }

    onCategoryHover(data){
     this.categoryTimeoutId =  setTimeout(() => {
          this.setCategoryImage(data);
      }, 300);
    }

    setCategoryImage(data?){
        this.groupImage = data.category_image_url;
        this.selectedCategoryId = data.category_id;
    }

    onCategoryLeave(){
        clearTimeout(this.categoryTimeoutId);
    }
}
