import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-design-and-color',
    templateUrl: './design-and-color.component.html',
    styleUrls: ['./design-and-color.component.scss'],
})
export class DesignAndColorComponent implements OnInit {

    @Input() configData = [];
    @Input() selectedValue: any;
    @Input() config_id: any;
    @Input() valueId: any;
    public selected_attribute: any;
    public configDataOptions = [];

    constructor(private modalService: ModalService,
        public commonService: CommonService) { }

    ngOnInit() {
        this.configDataOptions['config_id'] = this.config_id
        this.configData.forEach((element, index) => {
            let obj = {
                value: element.configuration_value_en,
                label: element.configuration_value_en,
                value_ar: element.configuration_value_ar,
                label_ar: element.configuration_value_ar,
                checked: this.selectedValue == element.configuration_value_en ? true : false,
                value_id: element.configuration_value_id

            }
            this.configDataOptions.push(obj);
        });

    }



    closeModal() {
        this.modalService.dismissModal();
    }

    selectCheckbox(event, id) {
        this.configDataOptions.forEach((element, index) => {
            if (index === id && event.detail.checked) {
                element['checked'] = true;
                this.commonService.configData(this.configDataOptions);
                setTimeout(() => {
                    this.closeModal();
                }, 200);
            } else if (index == id && !event.detail.checked) {
                element['checked'] = false;
                this.commonService.configData(this.configDataOptions);
            } else if (index != id && event.detail.checked) {
                element['checked'] = false;
                this.commonService.configData(this.configDataOptions);
            }
        });
    }

}
