<div class="footer" *ngIf="showOnlyLowFooter != true">
    <div class="left-footer">
        <div class="upper-footer container">
            <div class="company-part">
                <a [routerLink]="'/'+currentLang +'/home'">
                        <img class="icon" router-direction="none" src="assets/icon/companyLogoWhite.svg"
                        alt="Juman" height="100%" width="100%">
                </a>
                
            </div>
        </div>
        <div class="lower-footer container pr-40">
            <div class="stores-container">
                <h2 class="downloadapp">{{"footerComponent.downloadApp" | translate}}</h2>
                <div class="stores">
                    <a href="https://play.google.com/store/apps/details?id=com.jumanmarketplace.myapp"
                        rel="noopener noreferrer" aria-label="playstore link" target="_blank" class="stores-links">
                        <img src="../../../assets/icon/google-stores.svg" alt="playstore logo" width="100%"
                            height="100%" />
                    </a>
                    <a href="https://apps.apple.com/in/app/juman-marketplace/id1673534093" target="_blank"
                        rel="noopener noreferrer" aria-label="appstore link" class="stores-links">
                        <img src="../../../assets/icon/apple-logo.svg" alt="appstore logo" width="100%" height="100%" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="right-footer">
        <div class="upper-footer container">
            <div class="linking-wrapper">
                <div class="linking-section">
                    <div class="heading">{{"footerComponent.company" | translate}}</div>
                    <ul>
                        <li>
                            <a [routerLink]="'/'+currentLang +'/about-us'">
                                {{"footerComponent.aboutUs" | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="linking-section" *ngIf="!isLoggedIn">
                    <div class="heading">{{"footerComponent.myAccount" | translate}}</div>
                    <ul>
                        <li (click)="openRegisterModal()">{{"footerComponent.register" | translate}}</li>
                        <li (click)="openLoginModal()">{{"footerComponent.login" | translate}}</li>
                    </ul>
                </div>
                <div class="linking-section">
                    <div class="heading">{{"footerComponent.help" | translate}}</div>
                    <ul>
                        <li>
                            <a [routerLink]="'/'+currentLang +'/contact-us'">
                                {{"footerComponent.contactUs" | translate}}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="'/'+currentLang +'/faq'">
                                {{"footerComponent.faq" | translate}}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="'/'+currentLang +'/return-policy'">
                                {{"footerComponent.returns" | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="linking-section">
                    <div class="heading">{{"footerComponent.sell" | translate}}</div>
                    <ul>
                        <li><a href="{{supplierUrl}}" target="_blank"
                                rel="noopener noreferrer">{{"footerComponent.sellOnJuman"
                                | translate}}</a></li>
                        <li>
                            <a [routerLink]="'/'+currentLang +'/faq'" [queryParams]="{ fromPage: 'fromSupplierFaq' }">
                                {{"footerComponent.sellerFaq" | translate}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="social-option">
                <span><a href="https://www.facebook.com/profile.php?id=100089534437746" target="_blank"
                        rel="noopener noreferrer"><img src="assets/icon/facebook.svg" alt="Juman" height="100%"
                            width="100%"></a></span>
                <span> <a href="https://www.instagram.com/jumanmarketplace/" target="_blank"
                        rel="noopener noreferrer"><img class="pinterest" src="assets/icon/instagram.svg" alt="Juman"
                            height="100%" width="100%"></a></span>
                <span> <a href="https://www.linkedin.com/company/juman-marketplace/" target="_blank"
                        rel="noopener noreferrer"><img src="assets/icon/linkedin.svg" alt="Juman" height="100%"
                            width="100%" rel="noopener noreferrer"></a></span>
            </div>
        </div>
        <div class="lower-footer container pr-40">
            <div class="left-section">© {{currentYear}} {{"footerComponent.copyright" | translate}}</div>
            <div class="right-section">
                <ul>
                    <li>
                        <a [routerLink]="'/'+currentLang +'/terms-of-use'">
                            {{"footerComponent.term" | translate}}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="'/'+currentLang +'/privacy-policy'">
                           {{"footerComponent.policy" | translate}}
                        </a>
                    </li>
                    <li *ngIf="false">
                        <a [routerLink]="'/'+currentLang +'/cookie-policy'" >
                            {{"footerComponent.cookies" | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="footer2" *ngIf="showOnlyLowFooter == true">
    <div class="lower-footer container pr-40">
        <div class="left-section" class="pointer">
            <a [routerLink]="'/'+currentLang +'/home'">
               {{"footerComponent.copyright" | translate}}
            </a>
        </div>
        <div class="right-section">
            <ul>
                <li class="pointer">
                    <a [routerLink]="'/'+currentLang +'/terms-of-use'">
                        {{"footerComponent.term" | translate}}
                    </a>
                </li>
                <li class="pointer">
                    <a  [routerLink]="'/'+currentLang +'/privacy-policy'">
                        {{"footerComponent.policy" | translate}}
                    </a>
                </li>
                <li class="pointer" *ngIf="false">
                    <a [routerLink]="'/'+currentLang +'/cookie-policy'">
                        {{"footerComponent.cookies" |
                            translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>