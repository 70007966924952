import { DOCUMENT, isPlatformServer } from "@angular/common";
import {
    Component,
    Inject,
    OnInit,
    PLATFORM_ID,
    Renderer2,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { App } from "@capacitor/app";
import { Network } from "@capacitor/network";
import { SplashScreen } from "@capacitor/splash-screen";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import {
    AlertController,
    ModalController,
    NavController,
    Platform,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { getAnalytics, isSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CapacitorCookieService } from "./services/capacitor-cookie.service";
import { CommonService } from "./services/common.service";
import { GetPlatformService } from "./services/get-platform.service";
import { ModalService } from "./services/modal.service";
import { PushnotificationService } from "./services/pushnotification.service";
import { ToastService } from "./services/toast.service";
import { TranslateConfigService } from "./services/translate-config.service";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
    isNetworkConnected: boolean = true;
    isNoInternetModalOpen: boolean = false;
    networkListener: any;
    showSplash: any;
    closed$ = new Subject<any>();
    canonicalUrl: string;
    constructor(
        private renderer2: Renderer2,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private getPlatformService: GetPlatformService,
        private commonService: CommonService,
        private platform: Platform,
        public modalController: ModalController,
        private alertController: AlertController,
        private router: Router,
        public toastService: ToastService,
        private modalService: ModalService,
        private modalCtrl: ModalController,
        public capacitorCookieService: CapacitorCookieService,
        private pushNotif: PushnotificationService,
        private titleService: Title,
        public navCtrl: NavController,
        private firebaseAnalytics: FirebaseAnalytics,
        private translateService: TranslateConfigService,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private metaTagService: Meta,

    ) {
        this.translate.addLangs(["en", "ar"]);

        if (this.commonService.localStorageGet("locale")) {
            const browserLang = this.commonService.localStorageGet("locale");
            this.translate.use(browserLang.match(/en|ar/) ? browserLang : "en");
        } else {
            this.commonService.localStorageSet("locale", "en");
            this.translate.setDefaultLang("en");
        }
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(
                    router.routerState,
                    router.routerState.root
                );
                if (title.length) {
                    this.titleService.setTitle(
                        "Juman Marketplace | " +
                        title[title.length - 1]
                    );
                }
                this.metaTagService.updateTag({
                    property: "og:title",
                    content: "Juman Marketplace | " +
                        title[title.length - 1],
                });

                this.metaTagService.updateTag({
                    name: "description",
                    content: "Juman Marketplace is a digital E-commerce platform linking finishing building materials manufacturers, contractors & retailers. Effortless navigation, rapid shipping via top logistics, quick sample delivery. Your one-stop solution for all building materials requirements.",
                });
                this.metaTagService.updateTag({
                    property: "og:description",
                    content: "Juman Marketplace is a digital E-commerce platform linking finishing building materials manufacturers, contractors & retailers. Effortless navigation, rapid shipping via top logistics, quick sample delivery.Your one-stop solution for all building materials requirements.",
                });
                this.metaTagService.updateTag({
                    name: "keywords",
                    content: "Marketplace, Juman Market, E-commerce, Juman Market Ceramics, Tiles, Floor, Wall, Ceilings, Wall paper, Builder, Banner, Category, Group, Coupon, Discount",
                });
                const canonicalUrl = window.location.origin + event.urlAfterRedirects;
                this.updateCanonicalTag(canonicalUrl);
            }
        });
    }

    checkLanguageInUrl() {
        const url = location.pathname
        if (url.includes('/ar')) {
            this.translate.use('ar');
            this.commonService.localStorageSet("locale", "ar");
            this.translate.setDefaultLang("ar");
            // Perform some action if URL contains /en
        } else {
            this.translate.use('en');
            this.commonService.localStorageSet("locale", "en");
            this.translate.setDefaultLang("en");
            // Perform default action
        }
    }

    ngOnInit() {
        // this.translate.addLangs(["en", "ar"]);
        // if (this.commonService.localStorageGet("locale")) {
        //     const browserLang = this.commonService.localStorageGet("locale");
        //     this.translate.use(browserLang.match(/en|ar/) ? browserLang : "en");
        // } else {
        //     this.commonService.localStorageSet("locale", "en");
        //     this.translate.setDefaultLang("en");
        // }
        this.getPlatformService.getPlatformType();
        this.initializeApp();
        this.checkLanguageInUrl();
    }

    updateCanonicalTag(url: string) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                let currentUrl = window.location.href;
                if (event.url == '/en' || event.url == '/ar') {
                    currentUrl = `${currentUrl}/home`
                }
                const existingCanonical:any = this.document.querySelector('link[rel="canonical"]');
                if (existingCanonical) {
                    this.metaTagService.removeTagElement(existingCanonical);
                    this.renderer2.setAttribute(existingCanonical, 'href', url);
                }
                this.metaTagService.updateTag({
                    rel: 'canonical',
                    href: url
                });
            }

        })
    }

    initializeApp() {

        // Initialize Firebase
        SplashScreen.hide();
        isSupported().then(() => {
            const app = initializeApp(environment.firebaseConfig);
            const analytics = getAnalytics(app);
            setTimeout(() => {
                this.firebaseAnalytics.setEnabled(true);
            }, 300);
        })

        let networkStatus: any = Network.getStatus();
        networkStatus.then((data) => {
            if (!this.getPlatformService.isDesktop) {
                if (data && data.connected) {
                    this.commonService.isNetworkConnected = true;
                    let isModalOpen;
                    this.modalCtrl.getTop().then((data) => {
                        isModalOpen = data;
                        if (isModalOpen) {
                            this.modalController.dismiss();
                        }
                    });
                    this.commonService.isNoInternetModalOpen = false;
                } else {
                    this.commonService.isNetworkConnected = false;
                    this.commonService.noInternetModal();
                }
            }
        });
        this.commonService.isNoInternetModalOpen = false;
        if (!this.getPlatformService.isDesktop) {
            this.commonService.checkNetworks();
        }

        if (!this.getPlatformService.isDesktop) {
            this.showSplash = true;
        }

        this.commonService.routerEventVariable = this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd),
                takeUntil(this.closed$)
            )
            .subscribe((event) => {
                if (this.router.url.includes("home/payment-loading")) {
                    this.showSplash = false;
                }
            });

        this.platform.ready().then(() => {
            // Capture the app start time
            if (!this.getPlatformService.isDesktop) {
                let checkappStartTime = localStorage.getItem('appStartTime');
                if (!checkappStartTime) {
                    let appStartTime = JSON.stringify(+new Date());
                    localStorage.setItem('appStartTime', appStartTime)
                }
                setTimeout(() => {
                    // SplashScreen.hide();
                    this.showSplash = false;
                    this.router.navigate(['/' + this.translate.currentLang + "/home"]);
                }, 3000);
            }

            //Initialize Notification
            isSupported().then(() => {
                this.firebaseAnalytics.setEnabled(true);
            })

            this.pushNotif.initPush();
            this.backButtonEvent();


        });
    }

    backButtonEvent() {
        this.platform.backButton.subscribeWithPriority(0, () => {
            this.modalController.getTop().then((data) => {
                if (this.router.url == `/${this.translate.currentLang}/home` && !data) {
                    this.presentAlert();
                } else if (data) {
                    if (this.isNetworkConnected) {
                        this.modalController.dismiss();
                    }
                }
                if (this.router.url.includes("home/order-thank-you")) {
                    this.navCtrl.navigateRoot('/' + this.translate.currentLang + "/home", { animated: false });
                } else {
                    if (!isPlatformServer(this.platformId)) {
                        window.history.back();
                    }
                }
            });
        });
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: this.translate.instant("Exit App"),
            message: this.translate.instant("Are you sure you want to exit app?"),
            buttons: [
                {
                    text: this.translate.instant("no"),
                    role: "cancel",
                    handler: () => { },
                },
                {
                    text: this.translate.instant("yes"),
                    role: "confirm",
                    handler: () => {
                        App.exitApp();
                    },
                },
            ],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
    }

    changeLang(language: string) {
        this.commonService.localStorageSet("locale", language);
        this.translate.use(language);
    }

    public getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(...this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
}
