import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal.service';
import { CommonService } from './../../services/common.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-login-prompt',
    templateUrl: './login-prompt.component.html',
    styleUrls: ['./login-prompt.component.scss'],
})
export class LoginPropmptComponent implements OnInit {

    isLoggedIn: boolean;
    connectLabel = "Connect to your account";
    connectDescription = " Dui sit tellus proin a faucibus vel viverra";
    mobileView = false;
    buttonLabel = {
        register: 'Register',
        login: 'Login'
    }
    
    constructor(private modalService: ModalService,
        public commonService: CommonService,
        public navCtrl: NavController,
        public translate: TranslateService) { }

    ngOnInit() {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; } else this.isLoggedIn = false;

    }

    openRegisterModal() {
        this.modalService.dismissModal();
        this.navCtrl.navigateRoot(['/'+this.translate.currentLang+'/register'], { animated: false });
    }

    openLoginModal() {
        this.modalService.dismissModal();
        this.navCtrl.navigateRoot(['/'+this.translate.currentLang+'/login'], { animated: false });
    }

}
