<div class="two-fa-container" *ngIf="getPlatformService.isDesktop">
    <div class="modal-close text-right" (click)="closeModal()">
        <img src="assets/icon/close.svg" alt="Close" height="100%" width="100%">
    </div>
    <div class="pb-10" [ngClass]="[ commonService.domDirectionLTR ? 'two-fa-header' : 'two-fa-header-arabic' ]">
        {{'offerDialog.ExploreOffers' |translate}}
    </div>
</div>
<ion-header class="address-header" *ngIf="!getPlatformService.isDesktop" mode="md">
    <ion-toolbar mode="md">
        <img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
            [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
        <ion-title>
            <div class="offer-dialog-header">
                {{'offerDialog.ExploreOffers' |translate}}
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>
<app-loader *ngIf="isLoading"></app-loader>

<div class="main-div" *ngIf="!isLoading">
    <div *ngFor="let data of offerData;let i = index" class="border-div">
        <div class="row-div p-10-20">
            <div class="width-100 ">
                <p class="title1 color-app-black">{{data.coupon_code}}</p>
                <p class="label1 color-app-grey mt-5">{{commonService.domDirectionLTR ? data.coupon_title_en :
                    data.coupon_title_ar}}</p>
            </div>
            <app-common-button *ngIf="getPlatformService.isDesktop" color="#FFF" background="#38C2D4" type="solid"
                title="{{'Apply'|translate}}" [isIcon]="false" [isHideInMobile]="false" [boxshadow]="false"
                [specificHeight]="'40px'" [specificFontSize]="'0.9rem'" [specificWidth]="'100px'"
                (click)="applyCoupon(data)">
            </app-common-button>
            <app-common-button *ngIf="!getPlatformService.isDesktop" color="#FFF" background="#38C2D4" type="solid"
                title="{{'Apply'|translate}}" [isIcon]="false" [isHideInMobile]="false" [boxshadow]="false"
                [specificHeight]="'32px'" [specificFontSize]="'1rem'" [specificWidth]="'85px'"
                (click)="applyCoupon(data)">
            </app-common-button>
            <!-- <app-common-button color="#434345" background="transparent"
                type="clear" title="{{'Expired'|translate}}" [isIcon]="false" [isHideInMobile]="false"
                [specificHeight]="'40px'" [specificFontWeight]="'bold'" [specificFontSize]="'0.9rem'"
                [specificWidth]="'100px'">
            </app-common-button> -->
        </div>
        <div *ngIf="!data.showDetails" class="row-div show-details-div" (click)="showHideDetails(data,i)">
            <p class="label2 color-primary">{{'ShowDetails' |translate}}</p>
            <i class="feather color-primary icon-chevron-down"></i>
        </div>

        <div *ngIf="data.showDetails" class="horizontal-line mh-20"></div>

        <div *ngIf="data.showDetails" class="p-10-20 ">
            <div class="row-div mt-5 cursor-pointer" (click)="showHideDetails(data,i)">
                <p class="label2 color-app-black">{{'HideDetails' |translate}}</p>
                <i class="feather color-app-black icon-chevron-up"></i>
            </div>

            <p class="label1 color-app-grey mt-5 " *ngIf="commonService.domDirectionLTR"
                [innerHTML]="data.coupon_description_en"> </p>
            <p class="label1 color-app-grey mt-5 " *ngIf="!commonService.domDirectionLTR"
                [innerHTML]="data.coupon_description_ar"> </p>
        </div>


    </div>

    <div *ngIf="!offerData.length">
        <div class="no-data">
            {{"noRecordFound" | translate}}
        </div>
    </div>
</div>