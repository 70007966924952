<div class="register-block">
    <!-- <img class="graph graph-one" src="assets/icon/graph_1.svg" alt="Juman">
	<img class="graph graph-two" src="assets/icon/graph_2.svg" alt="Juman"> -->
    <div class="heading">{{"registerOnComponent.title" | translate}}</div>
    <span class="text">{{"registerOnComponent.text" | translate}}</span>
    <div class="custom-button">
        <ion-button (click)="openRegisterModal()">
            <div class="button-text">
                {{"registerOnComponent.action" | translate}}
            </div>
            <div>
                <ion-icon src="assets/icon/whiteRightArrow.svg" alt="juman" height="100%" width="100%"></ion-icon>
            </div>
        </ion-button>
    </div>
</div>