<ng-container *ngIf="getPlatformService.isDesktop">
	<div class="main-title" *ngIf="!hideTitle">
		<h2>{{'notificationComponent.notifications'| translate}}</h2>
	</div>

	<div class="listing-table">
		<table class="table" *ngIf="notificationCategoryArray && notificationCategoryArray.length">
			<thead>
				<th class="column-heading mw-250 text-left">
					{{"notificationComponent.notifications"
					| translate}}</th>
				<th class="column-heading ion-text-center arrow-col"></th>
			</thead>
			<tbody class="table-body">
				<tr *ngFor="let data of notificationCategoryArray;index as i" class="table-row" (click)="gotoNotificationlist(data)">
					<td class="label1 color-app-black pl-10 text-left">
						{{commonService.domDirectionLTR ? data.title : data.title_ar}}
					</td>

					<td class="label1 color-app-black align-end">

						<ion-badge *ngIf="data.unread" mode="ios">{{data.unread}}</ion-badge>
					</td>

					<td>
						<div class="right-arrow pointer" [ngClass]="commonService.domDirectionLTR ? 'justify-end' : ' rotate-arrow justify-start'">
							<img src="./assets/icon/rightArrow.svg" alt="righta-arrow">
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="no-record width-100" *ngIf="noData">
			{{'notificationComponent.noNotifications' | translate}}
		</div>

	</div>
</ng-container>



<ng-container *ngIf="!getPlatformService.isDesktop">
	<ion-header class="address-header" *ngIf="!hideTitle" mode="md">
		<ion-toolbar mode="md">
			<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()" [ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
			<ion-title mode="ios">
				<div class="notifications-header">
					{{'notificationComponent.notifications'| translate}}
				</div>
			</ion-title>
		</ion-toolbar>
	</ion-header>
	<ion-content class="notification-ion-content" *ngIf="from != 'dashboard'">
		<div [ngClass]="!hideTitle ? 'notification-content': null" *ngIf="notificationCategoryArray.length">
			<ng-container *ngIf="!isLoading">
				<ion-row *ngFor="let data of notificationCategoryArray" (click)="gotoMobileNotificationlist(data)">
					<ion-col class="text-div p-0" size-xs="10" size-sm="10" size-md="10" size-lg="8" size-xl="8">
						<p class="label1 color-app-black"> {{commonService.domDirectionLTR ? data.title : data.title_ar}}
						</p>
					</ion-col>
					<ion-col class="time-div p-0" size-xs="2" size-sm="2" size-md="2" size-lg="4" size-xl="4">
						<ion-badge *ngIf="data.unread" mode="ios">{{data.unread}}</ion-badge>
					</ion-col>
					<div class="horizontal-line mv-15"></div>
				</ion-row>
			</ng-container>
			<ng-container *ngIf="isLoading">
				<ion-row *ngFor="let data of notificationCategoryArray">
					<ion-col class="time-div p-0" size-md="2" size-lg="4" size-xl="4">
						<ion-skeleton-text class="mt-10 mb-10" [animated]="true"></ion-skeleton-text>
					</ion-col>
				</ion-row>
			</ng-container>
		</div>
		<div *ngIf="!notificationCategoryArray.length">
			<div class="mobile-no-notification">
				<p class="label-3 color-app-black ">{{'notificationComponent.noNotifications' | translate}}</p>
			</div>
		</div>
	</ion-content>

	<div class="notification-ion-content" *ngIf="from == 'dashboard'">
		<div [ngClass]="!hideTitle ? 'notification-content': null" *ngIf="notificationCategoryArray.length">
			<ng-container *ngIf="!isLoading">
				<ion-row *ngFor="let data of notificationCategoryArray" (click)="gotoMobileNotificationlist(data)">
					<ion-col class="text-div p-0" size-xs="10" size-sm="10" size-md="10" size-lg="8" size-xl="8">
						<p class="label1 color-app-black"> {{commonService.domDirectionLTR ? data.title : data.title_ar}}
						</p>
					</ion-col>
					<ion-col class="time-div p-0" size-xs="2" size-sm="2" size-md="2" size-lg="4" size-xl="4">
						<ion-badge *ngIf="data.unread" mode="ios">{{data.unread}}</ion-badge>
					</ion-col>
					<div class="horizontal-line mv-15"></div>
				</ion-row>
			</ng-container>
			<ng-container *ngIf="isLoading">
				<ion-row *ngFor="let data of notificationCategoryArray">
					<ion-col class="time-div p-0" size-md="2" size-lg="4" size-xl="4">
						<ion-skeleton-text class="mt-10 mb-10" [animated]="true"></ion-skeleton-text>
					</ion-col>
				</ion-row>
			</ng-container>
		</div>
		<div *ngIf="noData && !isLoading">
			<div class="mobile-no-notification">
				<p class="label-3 color-app-black ">{{'notificationComponent.noNotifications' | translate}}</p>
			</div>
		</div>
	</div>
</ng-container>