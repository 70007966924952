import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomValidator } from '../../../validators/customvalidators';
import { ModalService } from '../../services/modal.service';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { GetLatLongService } from './../../services/get-lat-long';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-edit-address',
    templateUrl: './add-edit-address.page.html',
    styleUrls: ['./add-edit-address.page.scss'],
})
export class AddEditAddressPage implements OnInit {



    isCheckedList = [];
    mobileView = false;
    isCountrySelected = false;
    isRegionSelected = false;
    isCitySelected = false;

    public validation_messages = {

        firstname: [
            { type: 'required', message: 'addEditAddressComponent.firstNameRequired' },
            { type: 'invalidString', message: 'addEditAddressComponent.firstNameInvalid' },
            { type: 'invalidName', message: 'addEditAddressComponent.firstNameInvalid' },
            { type: 'maxlength', message: 'addEditAddressComponent.firstNameMaxLength' }
        ],
        lastname: [
            { type: 'required', message: 'addEditAddressComponent.lastNameRequired' },
            { type: 'invalidString', message: 'addEditAddressComponent.lastNameInvalid' },
            { type: 'invalidName', message: 'addEditAddressComponent.lastNameInvalid' },
            { type: 'maxlength', message: 'addEditAddressComponent.lastNameMaxLength' }

        ],
        addressline1: [
            { type: 'required', message: 'addEditAddressComponent.addressRequired' },
            { type: 'invalidString', message: 'addEditAddressComponent.addressInvalid' },
            { type: 'invalidName', message: 'addEditAddressComponent.addressInvalid' },
            { type: 'maxlength', message: 'addEditAddressComponent.addressMaxLength' }

        ],
        country: [
            { type: 'required', message: 'addEditAddressComponent.countryRequired' },
        ],
        state: [
            { type: 'required', message: 'addEditAddressComponent.stateRequired' }
        ],
        city: [
            { type: 'required', message: 'addEditAddressComponent.cityRequired' }
        ],
        zipcode: [
            { type: 'required', message: 'addEditAddressComponent.zipCodeRequired' },
            { type: 'invalidString', message: 'addEditAddressComponent.zipCodeInvalid' },
            { type: 'invalidZip', message: 'addEditAddressComponent.zipCodeInvalid' }
        ],
        mobile_no: [
            { type: 'required', message: 'addEditAddressComponent.mobileRequired' },
            { type: 'invalidString', message: 'addEditAddressComponent.mobileInvalid' },
            { type: 'invalidNumber', message: 'addEditAddressComponent.mobileInvalid' }
        ],
    }

    registerFromGroup: FormGroup;
    public isLoading = false;
    private subscription: Subscription = new Subscription();
    is_default_billing: boolean = false;
    is_default_shipping: boolean = false;

    @Input() countryData: any = [];
    @Input() cityData: any = [];
    @Input() regionData: any = [];
    addressData: any;
    @Input() address_id: any;
    public optionSelect = {
        cssClass: 'location-popover'
    }
    public countryCodeLabel: boolean = false;
    public resetAddressCountry: boolean = false;
    helpEnText = "Adding your mobile number so we can contact you";
    helpArText = "إضافة رقم الجوال حتى نتمكن من الاتصال بك"


    constructor(
        private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        public getLatLongService: GetLatLongService,
        public translate: TranslateService

    ) {

    }

    ngOnInit() {
        this.registerFromGroup = this.initializeRegisterFromGroup();
        this.getCountryList();
    }

    ionViewWillEnter() {
        if (this.address_id) {
            this.getAddresses()
        }
    }
    getAddresses() {
        this.isLoading = true;

        const params = {
            address_id: this.address_id
        }


        this.subscription.add(
            this.dataService.get(URLS.getAddressList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;

                    this.addressData = data['data'][0];
                    this.registerFromGroup.controls['firstname'].setValue(this.addressData['first_name']);
                    this.registerFromGroup.controls['lastname'].setValue(this.addressData['last_name']);
                    this.registerFromGroup.controls['zipcode'].setValue(this.addressData['zipcode']);
                    this.registerFromGroup.controls['mobile_no'].setValue(this.addressData['mobile_no']);
                    this.registerFromGroup.controls['addressline1'].setValue(this.addressData['address']);
                    this.registerFromGroup.controls['country'].setValue(this.addressData['country_id'].toString());
                    this.registerFromGroup.controls['state'].setValue(this.addressData['region_id'].toString());
                    this.registerFromGroup.controls['city'].setValue(this.addressData['city_id'].toString());
                    this.isCountrySelected = true;
                    this.is_default_billing = this.addressData.is_default_billing_address;
                    this.is_default_shipping = this.addressData.is_default_shipping_address;
                    this.onMobilePhoneFocus();


                    this.getRegionList(null, true);
                    this.getCityList(null, true)

                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }

    async getCountryList() {
        this.isLoading = true;

        let params = {
            dropdown: true,
        }

        await this.subscription.add(
            this.dataService.get(URLS.countryList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.countryData = data['data']['result'];

                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )

    }

    valueSelected(event) {
        if (event.target.value) {
            this.isCitySelected = true;
        }

        this.cityData.forEach(element => {
            if (element.city_id == event.target.value) {
                this.getLatLongService.getLatLongLocation(element.city_en, +element.city_id);
            }

        });
    }

    getCityList(event?, fromEdit?) {
        if ((event && event.target.value) || this.registerFromGroup.controls['state'].value) {
            this.isRegionSelected = true;
        }
        if (fromEdit != true) {
            this.registerFromGroup.controls['city'].reset();
        }
        this.isLoading = true;

        let params = {
            dropdown: true,
            regions: +this.f1.state.value
        }

        this.subscription.add(
            this.dataService.get(URLS.cityList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.cityData = data['data']['result'];

                    if (this.cityData.length <= 0) {
                        this.registerFromGroup.controls['state'].reset();
                    }
                }
                else {
                    this.isLoading = false;
                    this.registerFromGroup.controls['state'].setValue('');
                }
            }, error => {
                this.isLoading = false;
            })
        )

    }

    getRegionList(event?: any, fromDetail?) {
        this.resetAddressCountry = false;
        if (event && event.target.value) {
            this.isCountrySelected = true;
        }

        if (fromDetail != true) {
            this.resetAddressCountry = true;
            this.registerFromGroup.controls['state'].reset();
            this.registerFromGroup.controls['city'].reset();
        }


        this.isLoading = true;
        let params = {
            dropdown: true,
            countries: +this.f1.country.value
        }

        this.subscription.add(
            this.dataService.get(URLS.regionList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.regionData = data['data']['result'];
                    if (this.regionData.length <= 0) {
                        this.registerFromGroup.controls['country'].setValue('');
                    }
                } else {
                    this.isLoading = false;
                    this.registerFromGroup.controls['country'].setValue('');
                }
            }, error => {
                this.isLoading = false;
            })
        )

    }


    closeModal() {
        this.modalService.dismissModal();
    }



    get f1() { return this.registerFromGroup.controls; }

    onSubmit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (this.registerFromGroup.invalid) {
            return;
        }


        const params = {
            "address_title": this.f1.addressline1.value,
            "first_name": this.f1.firstname.value,
            "last_name": this.f1.lastname.value,
            "address": this.f1.addressline1.value,
            "city_id": +this.f1.city.value,
            "zipcode": this.f1.zipcode.value,
            "mobile_no": this.f1.mobile_no.value,
            "is_default_shipping_address": this.is_default_shipping,
            "is_default_billing_address": this.is_default_billing,
            "latitude": +this.commonService.localStorageGet("latitude"),
            "longitude": +this.commonService.localStorageGet("longitude"),
        }

        this.isLoading = true;
        this.subscription.add(
            this.dataService.post(URLS.addAddress, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.commonService.setEvent('profileData', null);
                    this.modalService.dismissModalwithData(true);
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )

    }

    onUpdate() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (this.registerFromGroup.invalid) {
            return;
        }

        const params = {
            "address_id": +this.address_id,
            "address_title": this.f1.addressline1.value,
            "first_name": this.f1.firstname.value,
            "last_name": this.f1.lastname.value,
            "address": this.f1.addressline1.value,
            "city_id": +this.f1.city.value,
            "zipcode": this.f1.zipcode.value,
            "mobile_no": this.f1.mobile_no.value,
            "is_default_shipping_address": this.is_default_shipping,
            "is_default_billing_address": this.is_default_billing,
            "latitude": +this.commonService.localStorageGet("latitude"),
            "longitude": +this.commonService.localStorageGet("longitude"),
        }

        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.updateAddress, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.commonService.setEvent('profileData', null);
                    this.modalService.dismissModalwithData(true);
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )

    }

    initializeRegisterFromGroup() {
        return this.formBuilder.group({
            firstname: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50), this.translate.currentLang == 'en' ? CustomValidator.nameValidator : CustomValidator.arabicNameValidator, , CustomValidator.noWhiteSpaceValidator]),
            }),
            lastname: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50), this.translate.currentLang == 'en' ? CustomValidator.nameValidator : CustomValidator.arabicNameValidator, , CustomValidator.noWhiteSpaceValidator]),
            }),
            addressline1: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(200), this.translate.currentLang == 'en' ? CustomValidator.charnumberValidator : CustomValidator.arabicAddressValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            country: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            state: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            city: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            zipcode: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.zipCodeValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
            mobile_no: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.phoneValidator, CustomValidator.noWhiteSpaceValidator]),
            }),
        })
    }


    goBack() {
        this.modalService.dismissModal()
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    onMobilePhoneFocus() {
        this.countryCodeLabel = true;
    }

    onMobilePhoneBlur() {
        if (!this.f1.mobile_no.value) {
            this.countryCodeLabel = false;
        } else {
            this.countryCodeLabel = true;
        }
    }
}
