import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { GetLatLongService } from 'src/app/services/get-lat-long';
import { ApiService } from '../../services/api-data.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { QuoteService } from '../../services/quote.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-rfq-cart',
    templateUrl: './rfq-cart.component.html',
    styleUrls: ['./rfq-cart.component.scss'],
    providers: [NavParams]
})
export class RFQCartComponent implements OnInit {

    public isLoading = false;
    private subscription: Subscription = new Subscription();
    isLoggedIn: boolean;
    @Input() value: any;
    @Input() quoteId: any;
    @Input() from?: any;
    public locationSelect = {
        size: 'cover',
        side: 'bottom',
        alignment: 'center',
        cssClass: 'location-popover',
    }
    inerfaceOption = { cssClass: 'rfq-dropdown' };
    quoteData: any = [];
    quoteProductData = [];
    tempSelectedAddress: any;
    shipping_address: any = [];
    defalutShippingAddress: any;
    defalutShippingAddressId: any;
    email: any;
    errorMsg: any;
    pdfView: any;
    pdfSRC: any;

    constructor(private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private navParams: NavParams,
        private quoteService: QuoteService,
        public getLatLongService: GetLatLongService,
        public translate: TranslateService,
        public cartService: CartService,
        private navCtrl: NavController,
        @Inject(PLATFORM_ID) private platformId: Object,
        public router: Router) {
        this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
    }

    ngOnInit() {
    }

    ionViewWillEnter(){
        this.isLoading = true;

        this.quoteService.getQuote(this.quoteId).then((data) => {
            this.quoteData = data;
            this.quoteProductData = [];
            this.quoteData.result.forEach(element => {
                element.products.forEach(element2 => {
                    this.quoteProductData.push(element2);
                });
            });
            this.getAddresses();

            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    closeModal() {
        this.modalService.dismissModal();
    }


    chooseRFQOption(optionValue) {
        let data = {
            data: true,
            value: optionValue
        };
        this.modalService.dismissModal(data);
    }


    getAddresses() {
        this.shipping_address = [];

        this.subscription.add(
            this.dataService.get(URLS.getAddressList).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.shipping_address = data['data'];
                    this.shipping_address.forEach(element => {
                        if (element.is_default_shipping_address == true) {
                            element.select = true;
                            this.defalutShippingAddress = element;
                            this.defalutShippingAddressId = element.address_id;
                            this.cartService.shipping_address = element;
                            this.commonService.localStorageSet('cart_shipping_address', element.address_id);

                        }
                    });

                    if (this.shipping_address.length == 1 && this.shipping_address[0].is_default_shipping_address != true) {
                        const params = {
                            "address_id": +this.shipping_address[0].address_id,
                            "is_default_shipping_address": true,
                            "is_default_billing_address": true
                        }

                        this.subscription.add(
                            this.dataService.put(URLS.updateAddress, params).subscribe(data => {
                                if (data['data'] && data['code'] == 200) {
                                    this.tempSelectedAddress = null;
                                    this.getAddresses();
                                } else {
                                    this.toastService.displayToast(data['message'], null, 'danger');
                                }
                            }, error => {
                                this.toastService.displayToast(error['message'], null, 'danger');
                            }))
                    }

                    if (this.quoteId && this.quoteData && this.quoteData.quotation_shipping_address) {
                        this.defalutShippingAddress = this.quoteData.quotation_shipping_address;
                        this.defalutShippingAddressId = this.quoteData.quotation_shipping_address.address_id;
                    }
                }
            }, error => {
            })
        )
    }


    chooseAddress(element) {
        this.shipping_address.forEach(data => {
            if (data.address_id == element.address_id) {
                data.select = true;
                this.tempSelectedAddress = data.address_id;
            }
            else {
                data.select = false;
            }
        });
        this.isLoading = true;
        this.commonService.localStorageSet('cart_shipping_address', this.tempSelectedAddress);
        this.getLatLongService.getLatLongLocation(element['city_en'], +element.address_id, true).then(data => {
            this.quoteService.getQuote(this.quoteId).then((data) => {
                this.quoteData = data;
                this.quoteProductData = [];
                this.quoteData.result.forEach(element => {
                    element.products.forEach(element2 => {
                        this.quoteProductData.push(element2);
                    });

                });
                this.isLoading = false;
            });
        })

    }

    async shippingChnage(event) {
        await this.quoteService.updateQuote(this.quoteId, null, this.defalutShippingAddressId).then(data2 => {
            if (data2 && data2['code'] == 200) {
                this.isLoading = true;
                this.quoteService.getQuote(this.quoteId).then((data) => {
                    this.quoteData = data;
                    this.quoteProductData = [];
                    this.quoteData.result.forEach(element => {
                        element.products.forEach(element2 => {
                            this.quoteProductData.push(element2);
                        });

                    });
                    this.isLoading = false;
                });
            }
        }).catch(error => {
        });
    }

    onSubmit(value) {
        if (value == 'mail') {
            this.errorMsg = null
            if (!this.email || this.email == null || this.email.trim() == '') {
                this.errorMsg = "loginComponent.emailRequired"
                return;
            }
            else {
                const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
                if (!EMAIL_REGEXP.test(this.email.trim())) {
                    this.errorMsg = "loginComponent.emailInvalid"
                    return;
                }
            }
        }

        if (!this.defalutShippingAddressId) {
            this.toastService.displayToast(this.translate.instant('.addressRequired'), null, 'danger')
        }


        if (value == 'mail') {
            this.value = value;
            this.downloadQuote(true);
        }
        if (value == 'pdf') {
            this.value = value;
            this.downloadQuote(false)
        }
    }

    emailInputMethod(event) {
        this.errorMsg = null
        if (!event.target.value || event.target.value == null || event.target.value.trim() == '') {
            this.errorMsg = "loginComponent.emailRequired"
            return;
        }
        else {
            const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
            if (!EMAIL_REGEXP.test(event.target.value.trim())) {
                this.errorMsg = "loginComponent.emailInvalid"
                return;
            }
        }
    }


    deleteQuote(data, j) {
        this.quoteService.deleteQuote(j).then((response: any) => {
            this.quoteService.updateQuote(this.quoteId, response, null, !response ? true : false).then((data2) => {
                if (data2) {
                    this.isLoading = true;
                    this.quoteService.getQuote(this.quoteId).then((data3) => {
                        this.quoteData = data3;
                        this.quoteProductData = [];
                        if (this.quoteData.result) {
                            this.quoteData.result.forEach(element => {
                                if (element.products) {
                                    element.products.forEach(element2 => {
                                        this.quoteProductData.push(element2);
                                    });
                                }
                                else {
                                    this.closeModal();
                                }
                            });
                        }
                        else {
                            this.closeModal();
                        }
                        this.isLoading = false;
                    });
                }
            })
                .catch((error) => { console.log(error) });
        })

    }

    async qtychange(flag, data) {
        let dumpData = data;
        if (flag == 'enter') {
            if (dumpData.total_quantity <= dumpData.min_quantity) {
                dumpData.total_quantity = dumpData.min_quantity;
            }
        }
        if (flag == 'minus') {
            if (dumpData.total_quantity <= 1) {
                return;
            }
            dumpData.total_quantity--;
        }
        if (flag == 'add') {
            dumpData.total_quantity++;
        };

        let productData = [];
        this.quoteData.result.forEach(element => {
            element.products.forEach(element2 => {
                if (element2.product_id == data.product_id) {
                    element2.total_quantity = +data.total_quantity;
                }
                productData.push(element2);
            });
        });

        await this.quoteService.updateQuote(this.quoteId, productData).then(data2 => {
            if (data2 && data2['code'] == 200) {
                this.isLoading = true;
                this.quoteService.getQuote(this.quoteId).then((data) => {
                    this.quoteData = data;
                    this.quoteProductData = [];
                    this.quoteData.result.forEach(element => {
                        element.products.forEach(element2 => {
                            this.quoteProductData.push(element2);
                        });

                    });
                    this.isLoading = false;
                });
            }
        }).catch(error => {
        });
    }

    isnumeric(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
        let pattern = /^([0-9])$/;
        let result = pattern.test(e.key);
        return result;
    }

    quantityInput(data) {
        if (data.total_quantity === 'e' || data.total_quantity === '+' || data.total_quantity === '-') {
            data.total_quantity = data.min_quantity;
        }
        if (data.total_quantity == 0 || !data.total_quantity || data.total_quantity == null) {
            data.total_quantity = data.min_quantity;
        }

    }

    goToProductDetail(data) {
        let prod_id = data.main_product_ref_id;
        let prodnameUrl = this.commonService.trimUrl(data.product_name ? data.product_name: data.product_name_en);
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        if (data.product_id) {
            this.navCtrl.navigateRoot('/'+this.translate.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                {
                    animated: false,
                    queryParams: {
                        "sub_product_id": data.product_id
                    }
                });
        } else {
            this.navCtrl.navigateRoot('/'+this.translate.currentLang +"/"+catnameUrl+"/"+ prodnameUrl+"/"+ prod_id,
                {
                    animated: false,
                });
        }
    }

    downloadQuote(isMail) {

        this.isLoading = true;

        let params = {
            quotation_request_id: +this.quoteId,
            address_id: +this.defalutShippingAddressId,
            is_mail: isMail
        }

        if (this.email && this.value == 'mail') {
            params['customer_email'] = this.email
        }
        this.subscription.add(
            this.dataService.put(URLS.quoteDownload, params).subscribe(data => {
                this.isLoading = false;
                if (data && data['code'] == 200) {
                    if (isMail == false) {
                        this.closeModal();
                        const navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: data['data'],
                            },
                        };

                        this.router.navigate(['/'+this.translate.currentLang+'/home/pdf-view'], navigationExtras);
                    }
                    else {
                        this.closeModal();

                        setTimeout(() => {
                            this.toastService.displayToast(data['message'], null, 'success');
                        }, 100)
                    }
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger')
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger')

            })
        )
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
