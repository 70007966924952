import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss'],
})
export class NoInternetComponent implements OnInit {
  isLoading = false;
  constructor(
    public commonService : CommonService
  ) { }

  ngOnInit() { }

  tryAgain() {
    this.commonService.tryAgain();
  }
}
