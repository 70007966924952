<div [ngClass]="isHideInMobile ?'hide-btn-mobile ' : ' btn-container'">
    <ion-button fill="{{type}}" [style.--padding-start]="specificPaddingStart"
        [style.--padding-end]="specificPaddingEnd" [style.--background]="background" [style.height]="specificHeight"
        [class.full-width]="isFullWidth == true" [style.fontSize]="specificFontSize" [style.width]="specificWidth"
        [style.min-width]="specificWidth" [class.box-shadow-none]="boxshadow == false" [disabled]="buttonDisalbed"
        [ngClass]="[notifyMeButton ? 'notify-button' : '']" [attr.disabled]="buttonDisalbed" class="btn-css"
        (click)="firechange()" aria-label="action button">
        <div *ngIf="isButtonLoading">
            <ion-spinner name="circular"></ion-spinner>
        </div>
        <div *ngIf="isShippmentIcon">
            <ion-icon *ngIf="iconSrc" src="{{iconSrc}}" alt="juman"></ion-icon>
            <i *ngIf="iconClass" class="{{iconClass}}"></i>
        </div>
        <div class="text-css" [ngClass]="isShippmentIcon ? 'shippment-icon' : ''" [style.color]="color"
            [style.fontSize]="specificFontSize" [style.fontWeight]="specificFontWeight">
            {{title}}
        </div>
        <div *ngIf="isIcon" class="button-icon pl-15">
            <ion-icon *ngIf="iconSrc" src="{{iconSrc}}" alt="juman"></ion-icon>
            <i *ngIf="iconClass" class="{{iconClass}}"></i>
        </div>
    </ion-button>

</div>