import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { QuoteService } from 'src/app/services/quote.service';
import { ApiService } from '../../services/api-data.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-rfq-confirmation',
    templateUrl: './rfq-confirmation.component.html',
    styleUrls: ['./rfq-confirmation.component.scss'],
    providers: [NavParams]
})
export class RFQConfirmationComponent implements OnInit {

    public isLoading = false;
    private subscription: Subscription = new Subscription();
    isLoggedIn: boolean;
    @Input() product_id?: any;
    @Input() selected_product_id?: any;
    @Input() quantity?: any;
    @Input() from?: any;
    @Input() cartArray?: any;
    constructor(private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private navParams: NavParams,
        public quoteService: QuoteService,
        public router: Router) {
        this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
    }

    ngOnInit() {

    }

    closeModal() {
        this.modalService.dismissModal();
    }


    chooseRFQOption(optionValue) {
        if (this.from == 'cart' && this.cartArray && this.cartArray.length > 0) {
            this.quoteService.addIntoQuoteBulk(this.cartArray).then(response => {
                let data = {
                    data: true,
                    value: optionValue,
                    quote_id: response['data'].quotation_request_id
                };
                this.modalService.dismissModal(data);
            }).catch(err => { });

        }
        else {
            this.quoteService.addIntoQuote(this.selected_product_id, this.quantity).then(response => {
                let data = {
                    data: true,
                    value: optionValue,
                    quote_id: response['data'].quotation_request_id
                };
                this.modalService.dismissModal(data);
            }).catch(err => { });

        }


    }
}
