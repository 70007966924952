import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { RegisterPage } from '../../pages/register/register.page';
import { ModalService } from '../../services/modal.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-register-now',
    templateUrl: './register-now.component.html',
    styleUrls: ['./register-now.component.scss'],
})
export class RegisterNowComponent implements OnInit {

    constructor(private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        public navCtrl: NavController,
        public translate: TranslateService) { }

    ngOnInit() { }

    openRegisterModal() {
        if (this.getPlatformService.isDesktop) {
            setTimeout(() => {
                let options: any = {
                    id: "registerModal",
                    backdropDismiss: false
                };
                options['component'] = RegisterPage;
                this.modalService.openModal(options)
            }, 300);
        } else {
            this.navCtrl.navigateForward('/'+this.translate.currentLang+'/register');
        }
    }

}
