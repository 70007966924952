import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api-data.service';
import { ToastService } from '../services/toast.service';
import { URLS } from '../_config/api.config';

@Injectable({
    providedIn: 'root'
})
export class WishlistService {


    private subscription: Subscription = new Subscription();
    public whishListData: any;

    constructor(private dataService: ApiService,
        private toastService: ToastService) {
    }


    getWishList() {
        const params = {
            page: 1,
            size: 1000
        }

        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.getWishList, params).subscribe(data => {
                    if (data && data['code'] == 200) {
                        this.whishListData = data['data']['result'];
                    }
                    resolve(data);
                }, error => {
                    reject(error);
                })
            )
        })
    }

    //   isInWishList(id) {
    //     this.getWishList().then(() => {
    //       let data_id: any;
    //       if (this.whishListData) {
    //         data_id = this.whishListData.find(element => element.product_id === +id);
    //         if(data_id){
    //           this.isPresentInWishList = true;
    //         } else {
    //           this.isPresentInWishList = false;
    //         }
    //       }
    //       })
    //   }
}
