import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from './../../services/api-data.service';
import { CartService } from './../../services/cart.service';
import { CommonService } from './../../services/common.service';
import { ToastService } from './../../services/toast.service';
import { URLS } from './../../_config/api.config';

@Component({
    selector: 'app-offer-dialog',
    templateUrl: './offer-dialog.component.html',
    styleUrls: ['./offer-dialog.component.scss'],
})
export class OfferDialogComponent implements OnInit {

    public offerData: any = [];
    public isLoading = false;
    private subscription: Subscription = new Subscription();

    pageSize = {
        page: 1,
        limit: 10
    }
    constructor(
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private navParams: NavParams,
        private dataService: ApiService,
        private toastService: ToastService,
        public cartService: CartService,
    ) {
    }

    ngOnInit() {
        this.getofferList();
    }

    closeModal() {
        this.modalService.dismissModal();
    }


    getofferList() {
        this.isLoading = true;

        let params = {
            "page": this.pageSize.page,
            "size": this.pageSize.limit,
            "product_ids": this.cartService.cartProductIds
        }

        this.subscription.add(
            this.dataService.post(URLS.couponList, params).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    this.offerData = data['data']['result'];
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }
    showHideDetails(data, i) {
        this.offerData.forEach((element, index) => {
            if (index == i) {
                this.offerData[i].showDetails = !this.offerData[i].showDetails;
            }
            else {
                element.showDetails = false;
            }
        });
    }

    applyCoupon(data) {
        this.modalService.dismissModal(data);
    }

    goBack() {
        this.modalService.dismissModal()
    }
}
