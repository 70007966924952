import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from './../../services/api-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    public isLoading = false;
    @Input() categoryData: any = [];
    @Output() loadMoreData = new EventEmitter();
    @Input() enableNext: boolean = false;

    constructor(
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private navCtrl: NavController,
        private translate: TranslateService,
    ) {
    }

    ionViewWillEnter() {
    }


    ngOnInit() {
    }

    loadData(event) {
        this.loadMoreData.emit(event);
    }

    async goToProductListing(data) {
        let cat_id = data.category_id;
        let catnameUrl = this.commonService.trimUrl(data.category_name_en ? data.category_name_en : data.category_name);
        let currentLang = this.translate.currentLang;    
        await this.navCtrl.navigateForward('/'+currentLang +'/product-listing/' + catnameUrl + "/" + cat_id, {
            animated: false, "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.category_name_en : data.category_name_ar,
                "url": '/'+currentLang +'/product-listing/' + catnameUrl + "/" + cat_id,
                id: data.category_id,
                "ar_label": data.category_name_ar,
                "banner_img": data.category_image_url
            }
        });
    }
}
