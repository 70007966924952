import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { LoginPage } from '../../pages/login/login.page';
import { RegisterPage } from '../../pages/register/register.page';
import { AuthenticationService } from '../../services/authentication.service';
import { CartService } from '../../services/cart.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { ApiService } from './../../services/api-data.service';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
    selector: 'app-my-account-menu',
    templateUrl: './my-account-menu.component.html',
    styleUrls: ['./my-account-menu.component.scss'],
})
export class MyAccountMenuComponent implements OnInit {
    connectLabel = "Connect to your account";
    useremail = null;
    username = null;
    connectDescription = " Dui sit tellus proin a faucibus vel viverra";
    mobileView = false;
    buttonLabel = {
        register: 'Register',
        login: 'Login'
    }
    currentLang = this.translate.currentLang; 
    menuOptions = [
        {
            menu_name: 'accountMenuComponent.Dashboard',
            route:'/home/account-details/account-dashboard',
            is_enable: true,
            icon_url: './assets/icon/dashboardIcon.svg',
            id: 1,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myAccount',
            route: '/home/account-details/my-account',
            is_enable: true,
            icon_url: './assets/icon/myAccountDetails.svg',
            id: 2,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myOrders',
            route: '/home/account-details/my-orders',
            is_enable: true,
            icon_url: './assets/icon/myOrders.svg',
            id: 3,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myQuoteRequest',
            route: '/home/account-details/my-quote-request',
            is_enable: true,
            icon_url: './assets/icon/myOrders.svg',
            id: 14,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myAddresses',
            route: '/home/account-details/my-addresses',
            is_enable: true,
            icon_url: './assets/icon/houseIcon.svg',
            id: 4,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myCreditCards',
            route: "/account-details/my-credit-cards",
            is_enable: false,
            icon_url: './assets/icon/creditCardIcon.svg',
            id: 5,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myWishList',
            route: "/home/account-details/my-wishlist",
            is_enable: true,
            icon_url: './assets/icon/myWishilistIcon.svg',
            id: 6,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.myReturnOrders',
            route: "/home/account-details/return-order-listing",
            is_enable: true,
            icon_url: './assets/icon/return-order.svg',
            id: 7,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.cancelOrders',
            route: "/home/account-details/cancel-order-listing",
            is_enable: true,
            icon_url: './assets/icon/cancel-order.svg',
            id: 13,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.notifications',
            route: "/home/account-details/notification",
            is_enable: true,
            icon_url: './assets/icon/bellIcon.svg',
            id: 8,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.press',
            route: "/press",
            is_enable: false,
            icon_url: './assets/icon/press.svg',
            id: 11,
            is_public: false,
            is_private: true,
            is_web: false
        },
        {
            menu_name: 'accountMenuComponent.settings',
            route: "/home/account-details/setting",
            is_enable: true,
            icon_url: './assets/icon/settingIcon.svg',
            id: 9,
            is_public: false,
            is_private: true,
            is_web: true
        },
        {
            menu_name: 'accountMenuComponent.others',
            route: "/home/other-menu",
            is_enable: true,
            icon_url: './assets/icon/other-menu.svg',
            id: 12,
            is_public: false,
            is_private: true,
            is_web: false
        },
        // {
        //     menu_name: 'accountMenuComponent.logout',
        //     is_enable: true,
        //     route: "home/account-logout",
        //     icon_url: './assets/icon/returnOrders.svg',
        //     id: 10,
        //     is_public: false,
        //     is_private: true,
        //     is_web: true
        // },
    ];

    isLoggedIn: boolean;
    menuTitleShow: boolean = true;
    private subscription: Subscription = new Subscription();
    public isLoading = false;
    public activeNotificationTab: boolean;
    constructor(private modalService: ModalService,
        public commonService: CommonService,
        private alertController: AlertController,
        private authService: AuthenticationService,
        private toastService: ToastService,
        private dataService: ApiService,
        public getPlatformService: GetPlatformService,
        private router: Router,
        private navCtrl: NavController,
        private translate: TranslateService,
        public cartService: CartService) {
        const token = this.commonService.localStorageGet('accessToken');
        if (token) { this.isLoggedIn = true; this.getCustomerDetailsAPI(); } else this.isLoggedIn = false;
        this.commonService.eventObservable.subscribe(data => {
            if (data.event == 'profileData') {
                this.getCustomerDetailsAPI();
            }
            if (data.event == 'updateProfile') {
                this.getCustomerDetailsAPI();
            }
            if (data.event == 'detailsUpdate') {
                this.getCustomerDetailsAPI();
            }

        });
    }

    ngOnInit() {


    }


    goBack() {
        this.modalService.dismissModal();
    }

    menuToggoleHideShow() {
        this.menuTitleShow = !this.menuTitleShow
    }

    getCustomerDetailsAPI() {
        this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getCustomerDetails).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;
                    if (data['data']) {
                        this.commonService.localStorageSet('userInfo', JSON.stringify(data['data']));
                    }
                    this.username = data['data']['customer_name'];
                    this.useremail = data['data']['customer_email'];
                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }

    async openModal(id) {
        if (id == 1) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/dashboard', { animated: false });
        }
        if (id == 2) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/my-account-details', { animated: false });
        }
        if (id == 3) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/orders', { animated: false });
        }
        if (id == 4) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/address', { animated: false });
        }
        if (id == 6) {
            this.navCtrl.navigateRoot('/'+ this.currentLang+'/tabs/my-wishlist', { animated: false });
        }
        if (id == 8) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/notification', { animated: false });
        }
        if (id == 7) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/return-order', { animated: false });
        }
        if (id == 13) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/cancel-order', { animated: false });
        }
        if (id == 9) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/setting', { animated: false });
        }
        if (id == 11) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/press', { animated: false });
        }
        if (id == 12) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/other-menu', { animated: false });
        }
        if (id == 14) {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/my-account/my-quote-request', { animated: false });
        }
        if (id === 10) {
            const alert = await this.alertController.create({
                header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
                message: this.commonService.domDirectionLTR ? 'You want to logout?' : 'تريد الخروج؟',
                buttons: [
                    {
                        text: this.translate.instant('no'),
                        role: 'cancel',
                        handler: () => {
                        },
                    },
                    {
                        text: this.translate.instant('yes'),
                        role: 'confirm',
                        handler: () => {
                            this.logout();
                        },
                    },
                ],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
        }
        if (id === 15) {
            const alert = await this.alertController.create({
                header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
                message: this.commonService.domDirectionLTR ? 'You want to delete account?' : 'تريد حذف الحساب؟',
                buttons: [
                    {
                        text: this.translate.instant('no'),
                        role: 'cancel',
                        handler: () => {
                        },
                    },
                    {
                        text: this.translate.instant('yes'),
                        role: 'confirm',
                        handler: () => {
                            this.deleteUser();
                        },
                    },
                ],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
        }
    }


    async presentAlert(event, id) {
        if (id === 10) {
            const alert = await this.alertController.create({
                header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
                message: this.commonService.domDirectionLTR ? 'You want to logout?' : 'تريد حذف الحساب؟',
                buttons: [
                    {
                        text: this.translate.instant('no'),
                        role: 'cancel',
                        handler: () => {
                        },
                    },
                    {
                        text: this.translate.instant('yes'),
                        role: 'confirm',
                        handler: () => {
                            this.logout();
                        },
                    },
                ],
            });
            await alert.present();
            const { role } = await alert.onDidDismiss();
        }
    }

    menuDisplay(data) {
        if (this.isLoggedIn && data.is_enable) {
            return true;
        }
        else if (!this.isLoggedIn && data.is_public) {
            return true;
        }
        else return false;
    }

    openRegisterModal() {
        this.modalService.dismissModal();

        if (this.getPlatformService.isDesktop) {
            setTimeout(() => {
                let options: any = {
                    id: "registerModal",
                    backdropDismiss: false
                };
                options['component'] = RegisterPage;
                this.modalService.openModal(options)
            }, 300);
        } else {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/register');
        }


    }

    openLoginModal() {
        this.modalService.dismissModal();
        if (this.getPlatformService.isDesktop) {
            setTimeout(() => {
                let options: any = {
                    id: "loginModal",
                    backdropDismiss: false
                };
                options['component'] = LoginPage;
                this.modalService.openModal(options)
            }, 300);
        } else {
            this.navCtrl.navigateForward('/'+ this.currentLang+'/login');
        }
    }

    logout() {
        this.authService.logout().subscribe(data => {
            if (data['code'] == 200 && data['data']) {
                this.cartService.isloggedIn = false;
                this.commonService.localStorageClear();
                this.modalService.dismissAllModal();
                this.commonService.setEvent('logoutCall', null);
                this.navCtrl.navigateBack('/'+ this.currentLang+"/home", { animated: false });
            } else {
                this.cartService.isloggedIn = true;
                this.toastService.displayToast(data['message'], null, 'danger');
            }
        }, error => {
            this.cartService.isloggedIn = false;
            this.toastService.displayToast(error['message'], null, 'danger');
            this.modalService.dismissAllModal();
            this.commonService.setEvent('logoutCall', null);
            this.navCtrl.navigateBack('/'+ this.currentLang+"/home", { animated: false });
            this.commonService.localStorageClear();
        });
    }


    deleteUser() {

        this.isLoading = true;

        const params = new FormData();
        params.append("is_deleted", 'true');

        this.subscription.add(
            this.dataService.put(URLS.deleteUserAPI, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                    this.cartService.isloggedIn = false;
                    this.commonService.localStorageClear();
                    this.modalService.dismissAllModal();
                    this.commonService.setEvent('logoutCall', null);
                    this.navCtrl.navigateBack('/'+ this.currentLang+"/home", { animated: false });
                } else {
                    this.cartService.isloggedIn = true;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.cartService.isloggedIn = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                this.modalService.dismissAllModal();
                this.commonService.setEvent('logoutCall', null);
                this.navCtrl.navigateBack('/'+ this.currentLang+"/home", { animated: false });
                this.commonService.localStorageClear();
            })
        )

    }
}
