import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
    selector: 'app-common-button',
    templateUrl: './common-button.component.html',
    styleUrls: ['./common-button.component.scss'],
})
export class CommonButtonComponent implements OnInit {

    @Input() background: any;
    @Input() color: any;
    @Input() type: any;
    @Input() title: any;
    @Input() action: any;
    @Input() isIcon: boolean;
    @Input() isShippmentIcon: boolean;
    @Input() iconSrc?: any;
    @Input() iconClass?: any;
    @Input() isHideInMobile: boolean;
    @Input() isFullWidth?: boolean;
    @Input() boxshadow?: boolean;
    @Input() specificHeight?: any;
    @Input() specificWidth?: any;
    @Input() specificFontSize?: any;
    @Input() specificFontWeight?: any;
    @Input() specificPaddingStart?: any;
    @Input() specificPaddingEnd?: any;
    @Input() notifyMeButton: boolean = false;
    @Input() btnStatus: boolean = true;
    @Input() buttonDisalbed: boolean = false;
    @Input() isButtonLoading: boolean = false;
    @Output() buttonClicked = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    firechange() {
        if (this.buttonDisalbed) {
            return;
        }
        else {
            this.buttonClicked.emit(true);
        }
    }

}
