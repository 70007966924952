import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { CustomValidator } from 'src/validators/customvalidators';
import { URLS } from './../../_config/api.config';
import { ApiService } from './../../services/api-data.service';
import { CommonService } from './../../services/common.service';
import { GetPlatformService } from './../../services/get-platform.service';
import { ToastService } from './../../services/toast.service';
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    @ViewChild('passwordInput', { static: false }) passwordInput!: IonInput;


    public validation_messages = {
        oldpassword: [
            { type: 'required', message: 'changePassword.oldPasswordRequired' },
            { type: 'invalidString', message: 'loginComponent.passwordStringInvalid' },
        ],
        password: [
            { type: 'required', message: 'changePassword.newPasswordRequired' },
            { type: 'invalidString', message: 'loginComponent.passwordStringInvalid' },
            { type: 'invalidPassword', message: 'loginComponent.passwordInvalid' }
        ],
        repeatPassword: [
            { type: 'required', message: 'registerComponent.repeatPasswordRequired' },
            { type: 'invalidString', message: 'registerComponent.repeatPasswordInvalidString' },
            { type: 'invalidPassword', message: 'registerComponent.repeatPasswordInvalid' }
        ],
    }

    public passwordType = 'password';
    public repeatPasswordType = 'password';
    public oldPasswordType = 'password';

    changePasswordFromGroup: FormGroup;
    public isLoading = false;
    private subscription: Subscription = new Subscription();

    constructor(
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        public router: Router,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.changePasswordFromGroup = this.initializechangePasswordFromGroup();
        setTimeout(() => {
            if (this.passwordInput) {
                this.passwordInput.setFocus();
            }
        }, 100);
    }

    ionViewWillEnter() {
        setTimeout(() => {
            if (this.passwordInput) {
                this.passwordInput.setFocus();
            }
        }, 100);
    }

    closeModal() {
        if (this.getPlatformService.isDesktop) {
            this.router.navigateByUrl('/'+this.translate.currentLang+'/home/account-details/my-account');
        }
        else {
            this.modalService.dismissModal();

        }
    }

    get f1() { return this.changePasswordFromGroup.controls; }

    savePassword() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }

        if (!this.changePasswordFromGroup.valid) return;

        const params = {
            "old_password": this.changePasswordFromGroup.controls.oldpassword.value,
            "new_password": this.changePasswordFromGroup.controls.password.value,
        }

        this.isLoading = true;

        this.subscription.add(this.dataService.put(URLS.changePassword, params).subscribe(data => {
            if (data['code'] == 200) {
                this.closeModal();
            } else {
                this.toastService.displayToast(data['message'], null, 'danger');
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastService.displayToast(error['message'], null, 'danger');
        })
        )
    }

    initializechangePasswordFromGroup() {
        return this.formBuilder.group({
            oldpassword: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
            }),
            password: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
            }),
            repeatPassword: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.matchPassword]),
            })
        })
    }
}
