<div class="two-fa-container">
    <div class="modal-close-mobile text-right" (click)="closeModal()">
        <img src="assets/icon/modal-close-mobile.svg" alt="juman">
    </div>
    <div class="header-line">
    </div>
    <div class="two-fa-header pb-10">
        {{'filterBy' | translate}}
    </div>
    <div class="filter-by-options ph-20">
        <ion-list>
            <ion-item lines="none" id="fromDatePicker" class="with-bottom-border list-item">
                <div>
                    <ion-label class="filter-label"> {{'fromDate' | translate}}</ion-label>
                    <span class="selected-date">{{fromDate ? (fromDate | date:'dd/MM/yyyy') : ''}}</span>
                </div>
                <img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
            </ion-item>
            <ion-popover trigger="fromDatePicker" size="cover" id="datePopover">
                <ng-template>
                    <ion-datetime presentation="date" [min]="'2000-01-01'" [max]="toDate ? toDate : maxFromDate"
                        #filterFromDate [(ngModel)]="fromDate" [ngModelOptions]="{standalone: true}"
                        [showDefaultButtons]="true" mode="md" cancelText="{{'Cancel' | translate}}"
                        doneText="{{'done' | translate}}"></ion-datetime>
                </ng-template>
            </ion-popover>
            <div class="divider-line"></div>
            <ion-item lines="none" id="toDatePicker" class="with-bottom-border list-item">
                <div>
                    <ion-label class="filter-label"> {{'toDate' | translate}}</ion-label>
                    <span class="selected-date">{{toDate ? (toDate | date:'dd/MM/yyyy') : ''}}</span>
                </div>
                <img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
            </ion-item>
            <ion-popover trigger="toDatePicker" size="cover" id="datePopover">
                <ng-template>
                    <ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'" [max]="maxDate"
                        [min]="fromDate ? fromDate : '2000-01-01'" [max]="maxDate" [ngModelOptions]="{standalone: true}"
                        #filterToDate [(ngModel)]="toDate" [showDefaultButtons]="true" mode="md"
                        cancelText="{{'Cancel' | translate}}" doneText="{{'done' | translate}}"></ion-datetime>
                </ng-template>
            </ion-popover>
            <div class="divider-line"></div>
        </ion-list>
    </div>
    <ion-footer>
        <div class="footer-button-container ph-20 mt-20">
            <div class="apply-filter-btn clear-btn">
                <ion-button fill="clear" class="red-btn" (click)="clear()">{{'clear' | translate }}</ion-button>
            </div>
            <div class="apply-filter-btn">
                <ion-button fill="clear" class="red-btn" (click)="apply()">{{'apply' | translate }}</ion-button>
            </div>
        </div>
    </ion-footer>
</div>