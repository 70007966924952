import { DatePipe } from "@angular/common";
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CartService } from "src/app/services/cart.service";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { QuoteService } from "src/app/services/quote.service";
import { URLS } from "../../_config/api.config";
import { ApiService } from "../../services/api-data.service";
import { CommonService } from "../../services/common.service";
import { ExportExcelService } from "../../services/export-excel.service";
import { ModalService } from "../../services/modal.service";
import { ToastService } from "../../services/toast.service";
import { RFQCartComponent } from "../rfq-cart/rfq-cart.component";

@Component({
    selector: "app-my-quote-request",
    templateUrl: "./my-quote-request.component.html",
    styleUrls: ["./my-quote-request.component.scss"],
})
export class MyQuoteRequestComponent implements OnInit {
    isLoading = false;
    isDownloading = false;
    searchText: any;
    private subscription: Subscription = new Subscription();
    perPageInterface = { cssClass: "custom-select-dd per-page-dd" };
    currencySymbol: string = "SR";
    showSearch = false;
    enableNext = false;
    filterObj = {};
    showFilter = false;

    public count = 0;

    public itemsPerPage = "10";

    public currentPage = 1;
    selectInterface = { cssClass: "custom-select-dd", size: "cover" };
    selectStatus: any = "all";
    fromDate: any;
    toDate: any;
    dateRange: FormGroup;
    maxFromDate: any;
    maxDate: any;
    minToDate: any;
    todaysDate = new Date();
    columnHeaders = {
        order_id: "Order #",
        date: "Date",
        ship_to: "Ship to",
        order_total: "Order total",
        status: "Status",
    };
    statusList = [];
    quoteList = [];
    @Input() showHeader: boolean;
    @Input() from: any;
    @Input() dataArray: any;
    @ViewChild('popover') popover;
    constructor(
        private modalService: ModalService,
        private dataService: ApiService,
        private toastService: ToastService,
        public commonService: CommonService,
        private translateService: TranslateService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private router: Router,
        private quoteService: QuoteService,
        private getPlatformService: GetPlatformService,
        public cartService: CartService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private exportService: ExportExcelService
    ) {
        if (this.router.url.includes("my-quote-request")) {
            this.showHeader = true;
        }
    }

    ngOnInit() {
        if (this.router.url.includes("my-quote-request")) {
            this.showHeader = true;
            this.statusList = [
                { value: "all", label: this.translateService.instant("all") },
                {
                    value: "true",
                    label: this.translateService.instant("myQuoteComponent.completed"),
                },
                {
                    value: "false",
                    label: this.translateService.instant("myQuoteComponent.requested"),
                }
            ];
            this.initializeDateForm();

            this.getQuoteList(this.filterObj);
        }
        if (this.from == "dashboard") {
            this.quoteList = this.dataArray;
        }
    }

    ionViewWillEnter() {
        this.initializeDateForm();
        if (this.router.url.includes("my-quote-request")) {
            this.showHeader = true;
            this.statusList = [
                { value: "all", label: this.translateService.instant("all") },
                {
                    value: "true",
                    label: this.translateService.instant("myQuoteComponent.completed"),
                },
                {
                    value: "false",
                    label: this.translateService.instant("myQuoteComponent.requested"),
                }
            ];
            this.getQuoteList(this.filterObj);
        }
        if (this.from == "dashboard") {
            this.quoteList = this.dataArray;
        }
    }

    initializeDateForm() {
        this.dateRange = this.fb.group({
            from: new FormControl(null),
            to: new FormControl(null),
        });
        let date = new Date();
        this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
        this.maxDate = this.datePipe.transform(this.todaysDate, "YYYY-MM-dd");
        this.maxFromDate = this.datePipe.transform(this.maxFromDate, "YYYY-MM-dd");
    }

    applyBtn() {
        this.filterObj = {};
        if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
            if (!this.fromDate) {
                let msg = this.commonService.domDirectionLTR
                    ? "Please select 'From Date'."
                    : 'الرجاء تحديد "من التاريخ".';
                this.toastService.displayToast(msg, null, "danger");
            }
            if (!this.toDate) {
                let msg = this.commonService.domDirectionLTR
                    ? "Please select 'To Date'."
                    : 'الرجاء تحديد "حتى الآن".';
                this.toastService.displayToast(msg, null, "danger");
            }
            return;
        }
        if (this.fromDate && this.toDate) {
            this.filterObj["from_date"] = this.datePipe.transform(
                this.fromDate,
                "yyyy-MM-dd"
            );
            this.filterObj["to_date"] = this.datePipe.transform(
                this.toDate,
                "yyyy-MM-dd"
            );
        }
        if (this.filterObj["from_date"] && this.filterObj["to_date"]) {
            if (this.filterObj["from_date"] > this.filterObj["to_date"]) {
                let msg = this.commonService.domDirectionLTR
                    ? "'From Date' cannot be greater than 'To Date'."
                    : 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
                this.toastService.displayToast(msg, null, "danger");
                return;
            }
        }
        let isValid = false;
        if (
            isValid &&
            this.filterObj["from_date"] &&
            this.filterObj["to_date"] &&
            this.filterObj["from_date"] == this.filterObj["to_date"]
        ) {
            let msg = this.commonService.domDirectionLTR
                ? "'From Date' and 'To Date' cannot be same."
                : 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
            this.toastService.displayToast(msg, null, "danger");
            return;
        }
        if (this.selectStatus == "all") {
            delete this.filterObj["status"];
        } else {
            this.filterObj["status"] = this.selectStatus;
        }
        this.currentPage = 1;
        this.getQuoteList(this.filterObj);
    }

    clearFilter() {
        this.fromDate = null;
        this.toDate = null;
        this.selectStatus = "all";
        this.dateRange.reset();
        this.filterObj = {};
        if (this.searchText) {
            this.filterObj["search"] = this.searchText;
        }
        this.getQuoteList(this.filterObj);
    }

    onDateChange(datetime) {
        datetime.cancel(true);
    }

    search() {
        if (!this.searchText) return;
        this.filterObj["search"] = this.searchText;
        this.currentPage = 1;
        this.getQuoteList(this.filterObj);
    }

    openFilters() {
        this.showFilter = !this.showFilter;
    }

    openRFQCartModal(quote) {
        if (!this.getPlatformService.isDesktop) {
            this.router.navigate(['/'+this.translateService.currentLang+'/home/rfq-mobile'])
        }
        else {
            let options: any = {
                id: "addReviewModal",
                cssClass: 'rfq-popover',
                componentProps: {
                    from: 'list',
                    value: quote.quotation_request_id,
                    quoteId: quote.quotation_request_id
                },
            };
            options['component'] = RFQCartComponent;
            let modal: any = this.modalService.openModal(options);
            modal.then((data) => {
                this.getQuoteList(this.filterObj);
            })
        }

    }

    onChange(event): void {
        this.currentPage = event;
        this.getQuoteList(this.filterObj);
    }

    getQuoteList(params, infiniteScroll?, download?) {
        if (!download) {
            params = {
                page: this.currentPage,
                limit: +this.itemsPerPage,
                ...params,
            };
        }
        return new Promise((resolve, reject) => {
            if (!download) {
                this.isLoading = true;
            }
            this.subscription.add(
                this.dataService.get(URLS.quoteList, params).subscribe(
                    (data) => {
                        if (data && data["code"] == 200) {
                            if (download) {
                                let list =
                                    data["data"] &&
                                        data["data"]["result"] &&
                                        data["data"]["result"].length
                                        ? data["data"]["result"]
                                        : [];
                                if (list.length) {
                                    list.forEach((element, index) => {
                                        delete element["paid_order_amount"];
                                    });
                                    this.exportService.exportAsExcelFile(list, "quote_list");
                                }
                            } else {
                                this.enableNext = data["data"]["enable_next"] ? true : false;
                                this.count =
                                    data["data"] && data["data"]["total_count"]
                                        ? +data["data"]["total_count"]
                                        : 0;
                                let list =
                                    data["data"] &&
                                        data["data"]["result"] &&
                                        data["data"]["result"].length
                                        ? data["data"]["result"]
                                        : [];
                                if (!infiniteScroll) {
                                    this.quoteList = [];
                                }
                                list.forEach((element) => {
                                    this.quoteList.push(element);
                                });
                            }
                            resolve(this.quoteList);
                        } else {
                            reject();
                        }
                        this.isLoading = false;
                    },
                    (error) => {
                        reject();
                        this.isLoading = false;
                    }
                )
            );
        });
    }

    loadData(event) {
        setTimeout(() => {
            if (this.enableNext) {
                this.currentPage += 1;
                this.getQuoteList(this.filterObj, true).then(
                    (data) => {
                        event.target.complete();
                    },
                    (err) => {
                        event.target.complete();
                    }
                );
            }
        }, 500);
    }

    selectPageLength(event) {
        this.itemsPerPage = event.detail.value;
        this.currentPage = 1;
        this.getQuoteList(this.filterObj);
    }

    clearSearch() {
        this.searchText = null;
        delete this.filterObj["search"];
        this.currentPage = 1;
        this.getQuoteList(this.filterObj);
    }

    downloadExcel() {
        if (this.isDownloading) {
            return;
        }
        const params = {
            dropdown: true,
        };
        this.isDownloading = true;
        this.getQuoteList(params, false, true).then(
            (data) => {
                this.isDownloading = false;
            },
            (err) => {
                this.isDownloading = false;
            }
        );
    }


    quoteStatus(quoteData, productlevel?) {
        switch (quoteData.is_fulfilled) {
            case true:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
            case false:
                if (productlevel) {
                    return 'btn-red product-status-btn';
                } else {
                    return 'btn-red';
                }
            default:
                if (productlevel) {
                    return 'btn-green product-status-btn';
                } else {
                    return 'btn-green';
                }
        }
    }

    openPopup(data, event) {
        event.preventDefault();
        event.stopPropagation();
        data.isOpen = !data.isOpen;
    }

    async proceedToOrderQuote(quote) {

        quote.products.forEach(product => {
            let finalQuantity = product.is_in_cart == true && product.total_quantity_per_product != product.cart_quantity ?
                product.total_quantity_per_product > product.cart_quantity ?
                    product.total_quantity_per_product - product.cart_quantity :
                    product.cart_quantity - product.total_quantity_per_product
                : product.total_quantity_per_product;
            if (!product.is_in_cart) {
                this.cartService.addIntoCart(product, finalQuantity, null).then((data) => {
                });
            }
            else if (finalQuantity != product.total_quantity_per_product) {
                this.cartService.addIntoCart(product, finalQuantity, null).then((data) => {
                });
            }
        });

        this.commonService.localStorageSet('tempQuoteId', quote.quotation_request_id);
        this.router.navigate(['/'+this.translateService.currentLang+'/cart']);
    }
}
