import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { CustomValidator } from '../../../validators/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { ToastService } from '../../services/toast.service';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
    selector: 'app-notify-me',
    templateUrl: './notify-me.component.html',
    styleUrls: ['./notify-me.component.scss'],
})
export class NotifyMeComponent implements OnInit {
    public validation_messages = {
        email: [
            { type: 'required', message: 'loginComponent.emailRequired' },
            { type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
        ],
    }

    notifyMe: FormGroup;
    productId: any;
    private subscription: Subscription = new Subscription();
    @ViewChild('emailInput', { static: false }) emailInput!: IonInput;
    constructor(
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        private dataService: ApiService,
        private toastService: ToastService,
        private modalCtrl: ModalController,
        public modalService: ModalService
    ) {

    }

    ngOnInit() {
        this.notifyMe = this.formBuilder.group({
            email: new FormControl(null, {
                validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
            }),
        })
    }

    ionViewWillEnter() {
        this.emailInput.setFocus();
    }
    get f1() { return this.notifyMe.controls; }

    submit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }
        if (this.notifyMe.invalid) {
            return
        }
        const params = {
            "product_id": this.productId,
            "customer_email": this.notifyMe.value.email
        }
        this.subscription.add(
            this.dataService.post(URLS.notifyMe, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.toastService.displayToast(data['message'], null, 'success');
                    this.modalService.dismissModal(data['data']);
                } else {
                    this.toastService.displayToast(data['message'], null, 'error');
                    this.closeModal();
                }
            }, err => {
                this.toastService.displayToast(err, null, 'error');
                this.closeModal();

            })
        )
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }
}
