import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { AddReviewComponent } from '../../components/add-review/add-review.component';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-product-review',
    templateUrl: './product-review.page.html',
    styleUrls: ['./product-review.page.scss'],
})
export class ProductReviewPage implements OnInit {

    private subscription: Subscription = new Subscription();
    public reviews = [];
    public reviewsArray: any = [];
    public reviewsDetails: any;
    public reviewCount = []
    public loadMore: boolean = false;
    public average_rating: any;
    public total_ratings: any;
    public isLoading = false;
    reviewparams = {
        page: 1,
        size: 10,
    }
    @Input() product_id: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        public getPlatformService: GetPlatformService,
        private modalService: ModalService,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.getProductReviews();
    }


    addReviewModal() {
        let options: any = {
            id: "addReviewModal",
            componentProps: { product_id: this.product_id },
        };
        options['component'] = AddReviewComponent;
        let modal: any = this.modalService.openModal(options);
        modal.then((data) => {
            if (data && data.data == true) {
                this.reviews = [];
                this.getProductReviews();
            }
        })
    }


    getProductReviews() {
        if (+this.product_id) {
            let params = {
                product_id: +this.product_id,
                page: this.reviewparams.page,
                size: this.reviewparams.size
            }
            this.subscription.add(
                this.dataService.get(URLS.getReviews, params).subscribe(data => {
                    if (data['code'] == 200) {
                        this.isLoading = false;
                        let reviewData = data['data']['result'];
                        this.reviewsArray = data['data'];
                        this.reviewsDetails = data['data']['rating_details'];
                        this.reviewCount = [];
                        if ('five_star' in this.reviewsDetails) {
                            let object = {
                                name: "5 Star",
                                name_ar: "نجو 5",
                                count: this.reviewsDetails['five_star'],
                                percentage: ((this.reviewsDetails['five_star'] * 100) / this.reviewsArray.total_count)
                            }
                            this.reviewCount.push(object);
                        }
                        if ('four_star' in this.reviewsDetails) {
                            let object = {
                                name: "4 Star",
                                name_ar: "4 نجو",
                                count: this.reviewsDetails['four_star'],
                                percentage: ((this.reviewsDetails['four_star'] * 100) / this.reviewsArray.total_count)
                            }
                            this.reviewCount.push(object);
                        }
                        if ('three_star' in this.reviewsDetails) {
                            let object = {
                                name: "3 Star",
                                name_ar: "3 نجو",
                                count: this.reviewsDetails['three_star'],
                                percentage: ((this.reviewsDetails['three_star'] * 100) / this.reviewsArray.total_count)
                            }
                            this.reviewCount.push(object);
                        }
                        if ('two_star' in this.reviewsDetails) {
                            let object = {
                                name: "2 Star",
                                name_ar: "2 نجو",
                                count: this.reviewsDetails['two_star'],
                                percentage: ((this.reviewsDetails['two_star'] * 100) / this.reviewsArray.total_count)
                            }
                            this.reviewCount.push(object);
                        }
                        if ('one_star' in this.reviewsDetails) {
                            let object = {
                                name: "1 Star",
                                name_ar: "1 نجو",
                                count: this.reviewsDetails['one_star'],
                                percentage: ((this.reviewsDetails['one_star'] * 100) / this.reviewsArray.total_count)
                            }
                            this.reviewCount.push(object);
                        }

                        if (reviewData.length > 0) {
                            this.average_rating = this.reviewsDetails['average_rating'];

                            reviewData.forEach(element => {
                                let obj = {
                                    name: element.review_title ? element.review_title : "",
                                    verified: element.is_verified_customer ? element.is_verified_customer : false,
                                    date: element.created_at ? element.created_at : "7/7/2021",
                                    user_name: element.customer_name ? element.customer_name : "l",
                                    rating: element.customer_rating ? element.customer_rating : 4,
                                    title: element.review_title ? element.review_title : "",
                                    comment: element.customer_review ? element.customer_review : ""
                                }
                                this.reviews.push(obj);
                            });
                        }


                    } else {
                        this.isLoading = false;
                    }
                }, error => {
                    this.isLoading = false;
                })
            )
        }
    }

    loadMoreReviews() {
        this.loadMore = this.reviewsArray.enable_next;
        if (this.reviewsArray.enable_next) {
            this.reviewparams.page = this.reviewparams.page + 1;
            this.reviewparams.size = 10;
            this.getProductReviews();
        }
    }



    goBack() {
        this.modalService.dismissModal();
    }
}