import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../services/api-data.service';
import { SECRET_KEY, URLS } from '../_config/api.config';
import { CommonService } from './common.service';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    // secret key to encrypt the login credentails
    private secretKey: string = SECRET_KEY;
    private token = false;

    // onserveable logged in status
    public loggedInStatus;


    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private navCtrl: NavController,
        private router: Router,
        private commonService: CommonService
    ) {
        this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());

    }



    /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
    private hasToken(): boolean {
        return !!this.commonService.localStorageGet('token');
    }



    /**
    *
    * @returns {Observable<T>}
    */
    public isLoggedIn() {
        return this.loggedInStatus.asObservable();
    }



    public logout() {
        return this.http.get<any>(URLS.logout);
    }
}
