import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from '../../services/common.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { LoginPage } from './../../pages/login/login.page';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'app-order-sample',
    templateUrl: './order-sample.component.html',
    styleUrls: ['./order-sample.component.scss'],
})
export class OrderSampleComponent implements OnInit {
    productImage: string = '';
    orderSampleData: any;
    max_sample_unit_provided: any;
    sample_unit_id: any;
    sample_unit_name_ar: any;
    sample_unit_name_en: any;
    sample_unit_value: any;
    sample_price_per_unit: any;
    selected_Product: any;
    product_value: any;
    isAddToCartLoading = false;
    public productDetails: any[] = [
        {
            name: 'Metal Ceiling CT1782', image: 'assets/icon/product_image1.svg', options:
                [
                    { optionName: 'color', optionValue: 'Sed bibendum' },
                    { optionName: 'Format', optionValue: 'Planks' },
                    { optionName: 'Distributor', optionValue: 'Distributor #2' },
                    { optionName: 'ESTIMATED DELIVERY', optionValue: '9/9/2021' }
                ],
            price: '40'
        }
    ]
    constructor(
        private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        public commonService: CommonService,
        public cartService: CartService,
        private navCtrl: NavController,
        private translate: TranslateService,
        private toastService: ToastService,
    ) { }

    ngOnInit() {
        this.productImage = this.orderSampleData['product_images'][0]['image_url'];
    }

    closeModal() {
        this.modalService.dismissModal();
    }

    addtocart() {
        const token = this.commonService.localStorageGet('accessToken');

        if (!token) {
            let options: any = {
                id: "loginModal",
                backdropDismiss: false,
                componentProps: {
                    selected_Product: this.selected_Product,
                    product_value: 1,
                    is_sample: true
                }
            };
            options['component'] = LoginPage;
            this.modalService.openModal(options);
        }
        else {
            this.isAddToCartLoading = true;
             this.cartService.addIntoCart(this.orderSampleData, 1, true).then((cartServiceData) => {
                if (cartServiceData['code'] == 200) {
                    
                    this.cartService.getCart().then((data) => {
                         let cartData: any = data;
                         let is_not_sample_product = true;
                         let is_sample_item_and_shipment_free = false;
                        for (let i = 0; i < cartData.result.length; i++) {
                            const element = cartData.result[i];
                            if (element.products && element.products.length) {
                                is_not_sample_product = element.products.some(obj => !obj.is_sample_product);
                                is_sample_item_and_shipment_free = element.products.some(obj => obj.is_sample_item_and_shipment_free);
                                if(is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)){
                                    break;
                                }
                            }
                        }
                        if (is_not_sample_product || (!is_not_sample_product && !is_sample_item_and_shipment_free)) {
                            this.isAddToCartLoading = false;
                            this.toastService.displayToast(cartServiceData['message'], null, 'success').then(()=>{
                                this.modalService.dismissModal();
                            });
                        } else if (!is_not_sample_product && is_sample_item_and_shipment_free) {
                            this.isAddToCartLoading = false;
                            this.modalService.dismissModal();
                            this.navCtrl.navigateForward(['' + this.translate.currentLang + '/checkout-step-1'], { animated: false });
                        }
                        this.isAddToCartLoading = false;
                    });
                }
            });
        }
    }
}
