
export class CustomValidator {
    // Validates Email ID
    static emailValidator(email): any {
        if (email.value === null || email.value === "") return null;
        if (email.pristine) {
            return null;
        }
        const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
        email.markAsTouched();
        if (EMAIL_REGEXP.test(email.value.trim())) {
            return null;
        }
        return {
            invalidEmail: true
        };
    }
    static emailorMobileValidator(data): any {
        if (data.value === null || data.value === "") return null;
        if (data.pristine) {
            return null;
        }
        const EMAIL_REGEXP = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
        const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{9}$/;
        data.markAsTouched();
        if (EMAIL_REGEXP.test(data.value) || data.value === '' || data.value === null) {
            return null;
        }
        if (PHONE_REGEXP.test(data.value) || data.value === '' || data.value === null) {
            return null;
        }
        return {
            invalidEmail: true
        };
    }

    // Validates Password field
    static passwordPatternValidator(password): any {
        if (password.pristine) {
            return null;
        }
        if (password.parent.controls.password.value) {
            const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$/;
            password.markAsTouched();
            if (PASSWORD_REGEXP.test(password.value)) {
                return null;
            }
            return {
                invalidPassword: true
            };
        }
    }


    static matchPassword(password): any {
        if (password.parent) {
            if (password.parent.controls.password.pristine) {
                return null;
            }
            if (password.parent.controls.password.value === password.parent.controls.repeatPassword.value) {
                return null;
            }
            if (!password.parent.controls.password.value || !password.parent.controls.repeatPassword.value) {
                return null;
            }
            return {
                invalidPassword: true
            };
        }
    }

    static noWhiteSpaceValidator(string): any {
        if (string.errors && string.errors.maxlength) return null;

        if (string.value === null || string.value === "") return null;
        if (string.pristine) return null;
        string.markAsTouched();
        if (string.value.trim() == '' && string.value && string.value.length < 50) {
            return {
                invalidString: true
            }
        } else {
            return null
        }
    }

    static noSpaceValidator(string): any {
        const SPACE_REGEXP = /\s/g;
        if (string.value === null || string.value === "") return null;
        if (string.pristine) return null;
        string.markAsTouched();
        if (SPACE_REGEXP.test(string.value)) {
            return {
                invalidString: true
            }
        } else {
            return null
        }
    }

    static nameValidator(string): any {
        if (string.value === null || string.value === "" || string.value.trim() === "") return null;
        if (string.pristine) return null;
        const NAME_REGEX = /^[a-zA-Z']+(?:[_.\s][a-zA-Z']+)*$/;
        string.markAsTouched();
        if (string.value && string.value.trim().length > 50) {
            return null;
        }

        if (NAME_REGEX.test(string.value.trim())) {
            return null;
        }
        return {
            invalidName: true
        }
    }

    static charnumberValidator(string): any {
        if (string.errors && string.errors.maxlength) return null;

        if (string.value === null || string.value === "" || string.value.trim() === " ") return null;
        if (string.pristine) return null;
        const NAME_REGEX = /^[a-zA-Z0-9-.,']+(?:[_.\s][a-zA-Z0-9-.,']+)*$/; // allow - . and ,
        string.markAsTouched();
        if (NAME_REGEX.test(string.value.trim())) {
            return null;
        }
        return {
            invalidName: true
        }
    }

    static numberValidator(number): any {
        if (number.pristine) {
            return null;
        }
        if (number.value == "" || number.value == null || number.value == undefined) {
            return null
        }
        const NUMBER_REGEXP = /^(0|[1-9]\d*)?$/;
        number.markAsTouched();
        if (NUMBER_REGEXP.test(number.value) && number.value.length >= 9) {
            return null;
        } else if (number.value.length < 9) {
            return {
                minlength: true
            }
        }
        return {
            invalidNumber: true
        };
    }

    static onlyNumberValidator(number): any {
        if (number.pristine) {
            return null;
        }
        if (number.value == "" || number.value == null || number.value == undefined) {
            return null
        }
        const NUMBER_REGEXP = /^([0-9]\d*)?$/;
        number.markAsTouched();
        if (NUMBER_REGEXP.test(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    // Validates US phone numbers
    static phoneValidator(number): any {
        if (number.pristine) {
            return null;
        }
        const PHONE_REGEXP = /^(\+\d{1,3}[- ]?)?\d{9}$/;
        number.markAsTouched();
        if (number.value === "0000000000") {
            return {
                invalidNumber: true
            };
        }
        if (PHONE_REGEXP.test(number.value) || number.value === '' || number.value === null) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }
    // Validates zip codes
    static zipCodeValidator(zip): any {
        if (zip.value === null || zip.value === "" || zip.value === undefined) return null;
        if (zip.pristine) {
            return null;
        }
        const ZIP_REGEXP = /^[0-9]{5}$/; // indian zip code
        zip.markAsTouched();
        if (ZIP_REGEXP.test(zip.value)) {
            return null;
        }
        return {
            invalidZip: true
        };
    }
    // Validates zip codes
    static nationalIdValidator(zip): any {
        if (zip.value === null || zip.value === "" || zip.value === undefined) return null;
        if (zip.pristine) {
            return null;
        }
        const ZIP_REGEXP = /^[12]\d{9}$/; // indian zip code
        zip.markAsTouched();
        if (ZIP_REGEXP.test(zip.value)) {
            return null;
        }
        return {
            invalidNationalId: true
        };
    }

    static arabicNameValidator(string): any {
        if (string.value === null || string.value === "" || !string.value) return null;
        if (string.pristine) return null;
        // const NAME_REGEX = /^[\u0621-\u064A0-9 ]+$/;
        const NAME_REGEX = /[\u0600-\u06FF\u0750-\u077F]/;
        string.markAsTouched();
        if (NAME_REGEX.test(string.value.trim())) {
            return null;
        }
        return {
            invalidName: true
        }
    }

    static arabicAddressValidator(string): any {
        // string.value.trim() === " "
        if (string.value === null || string.value === "" || string.value.trim() === " ") return null;
        if (string.pristine) return null;
        // const NAME_REGEX = /^[\=\@\#\$\&\%\_\!\/\+\-\*\0-9\٠-٩\ء-ي]+$/gm;
        const NAME_REGEX = /[\u0600-\u06FF\u0750-\u077F]/;
        string.markAsTouched();
        if (NAME_REGEX.test(string.value.trim())) {
            return null;
        }
        return {
            invalidName: true
        }
    }
}
