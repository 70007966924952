<app-loader *ngIf="isLoading"></app-loader>

<div class="recommended-container">
    <ion-content>
        <ion-grid *ngIf="categoryData.length">
            <ion-row class="ion-justify-content-start">
                <ion-col *ngFor="let data of categoryData">
                    <div class="slide-container" (click)="goToProductListing(data)" size-xs="1" size-sm="1" size-md="1"
                        size-lg="1" size-xl="1">
                        <div class="product-image-container">
                            <img src="{{ data.category_image_url }}" alt="juman" />
                        </div>
                        <div
                            [ngClass]="commonService.domDirectionLTR ? 'product-detial-container':'rtl-product-detial-container'">
                            <div class="product-group-description ion-text-left">
                                {{ commonService.domDirectionLTR ? data.group_name_en : data.group_name_ar }}
                            </div>
                            <div class="product-description ion-text-left">
                                {{ commonService.domDirectionLTR ? data.category_name_en : data.category_name_ar }}
                            </div>
                            <div class="product-price ion-text-left">
                                <div class="from">
                                    {{ data.total_product_count}} {{'Items'|translate}}
                                </div>
                            </div>
                        </div>
                        <div class="right-arrow"
                            [ngClass]="commonService.domDirectionLTR ? '':'rotate-arrow left-arrow'">
                            <img src="assets/icon/arrowRight.svg" alt="juman" />
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>

        <div *ngIf="!categoryData.length" class="no-orders">
            <img src="./assets/icon/categories_icon.svg" alt="Juman" />
            <div class="mb-20">
                <p class="label-3 color-app-black ">{{'noRecordFound' | translate}}</p>
            </div>
        </div>

        <ion-infinite-scroll mode="md" (ionInfinite)="loadData($event)">
            <ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
                loadingText="{{'lodingMoreData' | translate}}">
            </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </ion-content>

</div>