import { environment } from '../../environments/environment';


export const URLS = Object({
    // Authentication

    //home page
    getBannerData: `${environment.apiUrl}/customers/banner/get`,
    getFeatureProductsData: `${environment.apiUrl}/customer/featuredproducts/getall`,
    getExploreCatalogue: `${environment.apiUrl}/customers/catalogue`,
    getProductCategories: `${environment.apiUrl}/customers/product/group/list`,
    getRecommendedProducts: `${environment.apiUrl}/customer/recommendedproducts/getall`,
    getBestSellingProducts: `${environment.apiUrl}/customer/bestselling/getall`,

    //product listing
    productlist: `${environment.apiUrl}/customer/product/getall`,
    priceRange: `${environment.apiUrl}/customer/product/get`, // price range filter
    categoryListFilter: `${environment.apiUrl}/customer/category/list`, // catagory list filter
    regionListFilter: `${environment.apiUrl}/customer/region/list`, // region list filter
    brandListFilter: `${environment.apiUrl}/customer/brand/list`, // brand list filter
    supplierListFilter: `${environment.apiUrl}/customer/supplier/list`, // brand list filter
    groupListFilter: `${environment.apiUrl}/customers/product/group/list`, // gruop list filter

    // product detail page
    getProductDetail: `${environment.apiUrl}/products/getdetails`,
    getReviews: `${environment.apiUrl}/ratings/get`,
    addReviews: `${environment.apiUrl}/rating/add`,


    //search page
    getSearchResults: `${environment.apiUrl}/customer/product/search`,
    getSearchTypes: `${environment.apiUrl}/search/types`,

    //registration flow
    generateOtp: `${environment.apiUrl}/customer/generate/otp`,
    verifyOtp: `${environment.apiUrl}/customer/verify/otp`,
    registration: `${environment.apiUrl}/customer/registration`,
    getLocation: `${environment.apiUrl}/master/cities/list`,

    //logout
    logout: `${environment.apiUrl}/customer/logout`,

    //login
    login: `${environment.apiUrl}/customer/login`,

    //resent otp
    resendOtp: `${environment.apiUrl}/customer/resend/otp`,

    //reset password
    resetPassword: `${environment.apiUrl}/customer/reset/password`,

    //change password
    changePassword: `${environment.apiUrl}/customer/change/password`,

    // get 2fa status
    getTwoFaSettings: `${environment.apiUrl}/2FA/settings`,

    // update 2FA settings
    updateTwoFaSettings: `${environment.apiUrl}/2FA/update`,

    // wishlist
    getWishList: `${environment.apiUrl}/wishlist/get`,
    addWishList: `${environment.apiUrl}/wishlist/add`,
    removeWishList: `${environment.apiUrl}/wishlist/delete`,
    getSubClassListing: `${environment.apiUrl}/active/subclasses`,
    getWishListSubClass: `${environment.apiUrl}/wishlist/subClass`,

    //customer details
    getCustomerDetails: `${environment.apiUrl}/customer/get`,
    updateCustomerDetails: `${environment.apiUrl}/customer/update`,

    //address
    getDefaultAddressList: `${environment.apiUrl}/customer/address/default`,
    getAddressList: `${environment.apiUrl}/customer/address/list`,
    addAddress: `${environment.apiUrl}/customer/address/add`,
    updateAddress: `${environment.apiUrl}/customer/address/update`,

    //master
    countryList: `${environment.apiUrl}/master/countries/list`,
    cityList: `${environment.apiUrl}/master/cities/list`,
    regionList: `${environment.apiUrl}/master/regions/list`,

    //CMS
    getCMSdata: `${environment.apiUrl}/customer/cms/list`,
    getFAQs: `${environment.apiUrl}/cms/faqs/get`,
    //cart
    cartList: `${environment.apiUrl}/customer/cart/list`,
    cartAdd: `${environment.apiUrl}/customer/cart/add`,
    cartUpdate: `${environment.apiUrl}/customer/cart/update`,

    checkoutInvoice: `${environment.apiUrl}/customer/invoice/add`,

    //coupon
    couponApply: `${environment.apiUrl}/customer/coupon/apply`,
    couponList: `${environment.apiUrl}/customer/coupon/list`,

    // Notifications
    notificationList: `${environment.apiUrl}/notification/list`,
    notificationListType: `${environment.apiUrl}/notification/types`,
    notificationSettingList: `${environment.apiUrl}/notification/settings/get`,
    updateNotificationStatus: `${environment.apiUrl}/notification/settings/update`,
    notificationReadStatus: `${environment.apiUrl}/notification/status/update`,



    //Orders
    orderList: `${environment.apiUrl}/customer/order/list`,
    orderDetails: `${environment.apiUrl}/customer/order/details`,

    //contact us

    contactUs: `${environment.apiUrl}/customer/enquiry/add`,
    dashBoardCount: `${environment.apiUrl}/count/get`,
    pressList: `${environment.apiUrl}/customer/press/list`,
    notifyMe: `${environment.apiUrl}/product/notifyme`,
    productAvailability: `${environment.apiUrl}/sub/product/availability`,
    searchedProductAdd: `${environment.apiUrl}/searched/product/add`,

    //language apis
    languageChange: `${environment.apiUrl}/customer/language/change`,

    // banner list
    getBannerList: `${environment.apiUrl}/customers/banner/product/list`,
    paymentStatus: `${environment.apiUrl}/customer/payment/status`,
    masterPaymentType: `${environment.apiUrl}/master/payment/types`,

    //Cancel products
    reasonsList: `${environment.apiUrl}/cancellation/reasons`,
    cancelOrderItem: `${environment.apiUrl}/cancel/order/items`,



    //getList
    allGroupList: `${environment.apiUrl}/customers/group/list`,
    allCategoryFromGroup: `${environment.apiUrl}/customers/group/categories`,
    clearCartAPI: `${environment.apiUrl}/customer/clear/cart`,

    deleteUserAPI: `${environment.apiUrl}/customer/update`,
    sadadPaymentAPI: `${environment.apiUrl}/payment/saddad/initiate`,

    cancelOrderList: `${environment.apiUrl}/customer/order/cancel/get`,
    returnOrderList: `${environment.apiUrl}/customer/order/return/get`,

    //quote
    quoteList: `${environment.apiUrl}/customer/quotation/list`,
    quoteAdd: `${environment.apiUrl}/customer/quotation/add`,
    quoteUpdate: `${environment.apiUrl}/customer/quotation/update`,
    quoteDetails: `${environment.apiUrl}/customer/quotation/details`,
    quoteDownload: `${environment.apiUrl}/customer/quotation/download`,
    quoteMail: `${environment.apiUrl}/customer/quotation/mail`,

    //related product
    relatedProductList: `${environment.apiUrl}/related/product/get`,

    //sitemap
    siteMapList: `${environment.apiUrl}/sitemap/list`,
    siteMapDetail: `${environment.apiUrl}/sitemap/details`




})

export const SECRET_KEY: string = `${environment.rsa_public_key}`;

