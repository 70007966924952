import { Component, Input, OnInit } from "@angular/core";
// import { Geolocation } from '@capacitor/geolocation';
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { CommonService } from "../../services/common.service";
import { ModalService } from "../../services/modal.service";
declare var google;
@Component({
	selector: "app-order-summary",
	templateUrl: "./order-summary.component.html",
	styleUrls: ["./order-summary.component.scss"],
})
export class OrderSummaryComponent implements OnInit {
	@Input() currency: any = "SR";
	@Input() orderSubTotal: any = "0";
	@Input() orderShipping: any = 0;
	@Input() orderShippingWithoutVat: any = 0;
	@Input() shippingVat: any = 0;
	@Input() orderTotal: any = "0";
	@Input() orderDiscount: any;
	@Input() orderVAT: any = 0;
	@Input() vatPercent?: any;
	@Input() isTotalSummary?: any;
	@Input() fromList?: any;
	@Input() discountCode?: any;
	@Input() supplierIndex?: any;
	@Input() supplierName?: any;
	@Input() shippingEliminated?: any;
	@Input() eliminatedRates?: any;
	@Input() orderSummeryData?: any;

	shippingEnText =
		"Shipping cost with VAT to have the products delivered to the selected location";
	shippingArText = "تكلفة الشحن مع ضريبة القيمة المضافة لتسليم المنتجات إلى الموقع المحدد";

	shippingEnTextwithoutVAT =
		"Shipping cost without VAT to have the products delivered to the selected location";
	shippingArTextwithoutVAT = "تكلفة الشحن بدون ضريبة القيمة المضافة لتوصيل المنتجات إلى الموقع المحدد";

	shippingEnVAT =
		"Shipping VAT Amount to have the products delivered to the selected location";
	shippingArVAT = "مبلغ ضريبة القيمة المضافة للشحن لتوصيل المنتجات إلى الموقع المحدد";

	vatEnText1 = "Value Added Tax (";
	vatArText1 = "ضريبة القيمة المضافة (";

	vatEnText2 = "%) of your total order price";
	vatArText2 = "%) من إجمالي سعر طلبك";

	constructor(
		public getPlatformService: GetPlatformService,
		private nativeGeocoder: NativeGeocoder,
		private modalService: ModalService,
		public commonService: CommonService
	) {
		let discount_code = this.commonService.localStorageGet("discount_code");
		if (discount_code && discount_code != "null" && discount_code != null) {
			this.discountCode = discount_code;
		}
	}

	ngOnInit() {
		let discount_code = this.commonService.localStorageGet("discount_code");
		if (discount_code && discount_code != "null" && discount_code != null) {
			this.discountCode = discount_code;
		}

		setTimeout(() => {
			if (this.orderSummeryData && this.orderSummeryData.length) {
				this.orderSummeryData.sort((a, b) => a.sequence - b.sequence);
			}
		}, 200);
	}
}
