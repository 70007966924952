import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { CommonService } from 'src/app/services/common.service';
import { ApiService } from '../../services/api-data.service';
import { GetPlatformService } from '../../services/get-platform.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { CustomValidator } from './../../../validators/customvalidators';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
    providers: [NavParams]
})
export class EditProfileComponent implements OnInit {
    registerFromGroup: FormGroup;
    public isLoading = false;
    private subscription: Subscription = new Subscription();

    public validation_messages = {
        email: [
            { type: 'required', message: 'loginComponent.emailRequired' },
            { type: 'invalidEmail', message: 'loginComponent.emailInvalid' }
        ],
        password: [
            { type: 'required', message: 'loginComponent.passwordRequired' },
            { type: 'invalidString', message: 'loginComponent.passwordStringInvalid' },
            { type: 'invalidPassword', message: 'loginComponent.passwordInvalid' }
        ],
        name: [
            { type: 'required', message: 'registerComponent.nameRequired' },
            { type: 'invalidName', message: 'registerComponent.nameInvalidString' },
            { type: 'maxlength', message: 'registerComponent.maxLengthRequired' },
        ],
        mobilephone: [
            { type: 'required', message: 'registerComponent.mobilePhoneRequired' },
            { type: 'invalidNumber', message: 'registerComponent.invalidPhoneNo' },
            { type: 'minlength', message: 'registerComponent.invalidPhoneNo' },
            { type: 'invalidString', message: 'registerComponent.invalidPhoneNo' },
        ],
        repeatPassword: [
            { type: 'required', message: 'registerComponent.repeatPasswordRequired' },
            { type: 'invalidString', message: 'registerComponent.repeatPasswordInvalidString' },
            { type: 'invalidPassword', message: 'registerComponent.repeatPasswordInvalid' }
        ],
        companyRegistrationNumber: [
            { type: 'required', message: 'registerComponent.compRegistrationNoRequired' },
            { type: 'invalidString', message: 'registerComponent.compRegistrationNoInvalidString' },
        ],
        location: [
            { type: 'required', message: 'registerComponent.locationRequired' },
        ],
        selectIntrestList: [
            { type: 'required', message: 'registerComponent.intrestsRequired' }
        ],
        vatNumber: [
            { type: 'required', message: 'companyDetailsComponent.vatNumberRequired' },
            { type: 'invalidString', message: 'companyDetailsComponent.vatNumbervalid' },
            { type: 'invalidNumber', message: 'companyDetailsComponent.vatNumbervalid' },
            { type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

        ],
        crNumber: [
            { type: 'required', message: 'companyDetailsComponent.crNumberRequired' },
            { type: 'invalidString', message: 'companyDetailsComponent.crNumberValid' },
            { type: 'invalidNumber', message: 'companyDetailsComponent.crNumberValid' },
            { type: 'minlength', message: 'companyDetailsComponent.vatNumbervalid' }

        ],
        companyCRNoName: [
            { type: 'required', message: 'companyDetailsComponent.companyCRNoNameError' },
        ],
        vatCertificateNoName: [
            { type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },

        ],
        nationalAddressFile: [
            { type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },
        ]
    }

    @ViewChild('companyLogoNameElem') companyLogoNameElem: ElementRef;
    @ViewChild('companyCrNoElem') companyCrNoElem: ElementRef;
    @ViewChild('vatCertificateNoElem') vatCertificateNoElem: ElementRef;
    @ViewChild('brandLogoElem') brandLogoElem: ElementRef;
    @ViewChild('nationalAddressFileElem') nationalAddressFileElem: ElementRef;

    public desktopView: boolean = false;
    public countryCodeLabel: boolean = false;

    public message: any;
    public fileToUpload: any;

    public isLocationSelected: boolean = false;
    public selected_Product: any;
    public product_value: any;
    public locationData: any = [];
    public is_sample: any;
    public city_id: any;
    public locationSelect = {
        size: 'cover',
        side: 'bottom',
        alignment: 'center',
        cssClass: 'location-popover'
    }
    isCompanyCRNoFile = false;
    isVatCertificateNoFile = false;
    companyCRNoName: any;
    vatCertificateNoName: any;
    companyLogoFile: any;
    brandLogoFile: any;
    companyCRNoFile: any;
    vatCertificateNoFile: any;
    nationalAddressNoFile: any;
    supplierId: any;
    accessToken: any;
    files: any;
    nationalAddressFile: any;
    isNationalAddressFile = false;
    isCheckedList = [];
    selectedCategory = [];
    public passwordType = 'password';
    public repeatPasswordType = 'password';
    selectedIntrestList = [];
    registrationCheckbox = [];

    constructor(private modalService: ModalService,
        public getPlatformService: GetPlatformService,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        private dataService: ApiService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private navParams: NavParams,
        public router: Router,
        private translate: TranslateService,

        @Inject(DOCUMENT) private document: Document,) {
    }

    ngOnInit() {
        this.registerFromGroup = this.initializeRegisterFromGroup();
        this.loadAllData();
    }

    loadAllData() {
        this.isLoading = true;
        Promise.all([
            this.getProductCategories(),
            this.getProfileDetails(),
        ]).then(data => {
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        })
    }


    getProfileDetails(fromviewAfter?) {
        this.subscription.add(
            this.dataService.get(URLS.getDefaultAddressList).subscribe(data => {
                if (data['data'] && data['code'] == 200) {
                    this.isLoading = false;
                    let customerData = data['data'];
                    this.registerFromGroup.controls['name'].setValue(customerData['customer_name'] ? customerData['customer_name'] : null);
                    this.registerFromGroup.controls['vatNumber'].setValue(customerData['customer_vat_no'] ? customerData['customer_vat_no'] : null);
                    this.registerFromGroup.controls['crNumber'].setValue(customerData['customer_cr_no'] ? customerData['customer_cr_no'] : null);
                    this.registerFromGroup.controls['companyCRNoName'].setValue(customerData['customer_cr_url'] ? customerData['customer_cr_url'].split('/').pop() : null);
                    this.registerFromGroup.controls['vatCertificateNoName'].setValue(customerData['customer_vat_url'] ? customerData['customer_vat_url'].split('/').pop() : null);
                    this.registerFromGroup.controls['nationalAddressFile'].setValue(customerData['national_address_url'] ? customerData['national_address_url'].split('/').pop() : null);
                    // this.registerFromGroup.controls['selectIntrestList'].setValue(customerData['national_address_url'] ? customerData['national_address_url'].split('/').pop() : null);


                    this.companyCRNoName = customerData['customer_cr_url'] ? customerData['customer_cr_url'].split('/').pop() : null;

                    this.vatCertificateNoName = customerData['customer_vat_url'] ? customerData['customer_vat_url'].split('/').pop() : null;

                    this.nationalAddressFile = customerData['national_address_url'] ? customerData['national_address_url'].split('/').pop() : null;

                    let selectedIntrest = [];
                    this.selectedIntrestList = [];
                    if (customerData.customer_interests) {
                        customerData.customer_interests.forEach(element => {
                            selectedIntrest.push(element.group_id)
                        });
                        this.selectedIntrestList = selectedIntrest;
                    }

                    this.registrationCheckbox.forEach(element => {
                        element.isChecked = false;
                    });

                    this.selectedValueGet().then((data: any) => {
                        this.registrationCheckbox = data;
                    })

                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }


    selectedValueGet() {
        return new Promise((resolve, reject) => {
            if (this.selectedIntrestList) {
                this.registrationCheckbox.forEach(element => {
                    this.selectedIntrestList.forEach(element2 => {
                        if (element2 == element.value) {
                            element.isChecked = true;
                        }
                    });
                });

                resolve(this.registrationCheckbox);
            }
            else {
                reject();
            }
        })
    }

    get f1() { return this.registerFromGroup.controls; }


    getFileName(url) {
        return url.split('/').pop();
    }

    onSubmit() {
        for (const i in this.f1) {
            this.f1[i].markAsDirty();
            this.f1[i].updateValueAndValidity();
            this.f1[i].markAsTouched();
        }


        if (this.registerFromGroup.invalid) {
            return;
        }

        if (!this.f1['companyCRNoName'].value || !this.f1['vatCertificateNoName'].value || !this.f1['nationalAddressFile'].value) {
            this.toastService.displayToast(this.translate.instant('allInputRequired'), null, 'danger');
            return;
        }
        let checked = [];
        if (this.registrationCheckbox.length) {
            this.registrationCheckbox.forEach(element => {
                if (element.isChecked) {
                    checked.push(element.value)
                }
            })
        }

        checked = [...checked]
        if (checked.length === 0) {
            this.toastService.displayToast(this.translate.instant("selectInterest"), null, 'danger');
            return;
        }

        const params = new FormData();
        if (this.registerFromGroup.controls["name"].value) {
            params.append("customer_name", this.registerFromGroup.controls["name"].value)
        }
        if (this.registerFromGroup.controls["crNumber"].value) {
            params.append("customer_cr_no", this.registerFromGroup.controls["crNumber"].value)
        }
        if (this.registerFromGroup.controls["vatNumber"].value) {
            params.append("customer_vat_no", this.registerFromGroup.controls["vatNumber"].value)
        }

        if (this.registrationCheckbox.length) {
            let checkedId = [];
            this.registrationCheckbox.forEach(element => {
                if (element.isChecked) {
                    checkedId.push(element.value)
                }
            })
            params.append("group_id", JSON.stringify(checkedId))
        }

        if (this.vatCertificateNoFile) {
            params.append("customer_vat_url", this.vatCertificateNoFile)
        }
        if (this.companyCRNoFile) {
            params.append("customer_cr_url", this.companyCRNoFile)
        }
        if (this.nationalAddressNoFile) {
            params.append("national_address_url", this.nationalAddressNoFile)
        }


        this.isLoading = true;

        this.subscription.add(this.dataService.put(URLS.updateCustomerDetails, params).subscribe(data => {
            if (data['code'] == 200) {
                this.commonService.setEvent('detailsUpdate', null);
                this.modalService.dismissModal(data['data']);
            } else {
                this.toastService.displayToast(data['message'], null, 'danger');
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.toastService.displayToast(error['message'], null, 'danger');
        })
        )


    }

    initializeRegisterFromGroup() {
        const token = this.commonService.localStorageGet('accessToken');

        return this.formBuilder.group({
            name: new FormControl(null, {
                validators: Validators.compose([Validators.required]),
            }),
            companyCRNoName: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            vatCertificateNoName: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            nationalAddressFile: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            vatNumber: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.minLength(15), CustomValidator.noSpaceValidator, CustomValidator.onlyNumberValidator]),
            }),
            crNumber: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.minLength(10), CustomValidator.noSpaceValidator, CustomValidator.onlyNumberValidator]),
            }),
            // selectIntrestList: new FormControl(null, {
            //     validators: Validators.compose([Validators.required]),
            // }),
        })
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    remove(event, fieldname) {
        if (fieldname == 'company-cr-number') {
            this.companyCRNoFile = null;
            this.companyCRNoName = null;
            this.registerFromGroup.patchValue({
                companyCRNoName: null
            })
        } else if (fieldname == 'national-address-file') {
            this.nationalAddressNoFile = null;
            this.nationalAddressFile = null;
            this.registerFromGroup.patchValue({
                nationalAddressFile: null
            })
            // this.registerFromGroup.controls['nationalAddressFile'].setValue(null);
        } else {
            this.vatCertificateNoFile = null;
            this.vatCertificateNoName = null;
            this.registerFromGroup.patchValue({
                vatCertificateNoName: null
            })
        }
    }

    locationSelected(event) {
        if (event.target.value) {
            this.isLocationSelected = true;
            this.city_id = event.target.value;
        }
    }

    onMobilePhoneFocus() {
        this.countryCodeLabel = true;
    }

    onMobilePhoneBlur() {
        if (!this.f1.mobilephone.value) {
            this.countryCodeLabel = false;
        } else {
            this.countryCodeLabel = true;
        }
    }


    async getProductCategories() {
        this.subscription.add(
            await this.dataService.get(URLS.getProductCategories).subscribe(data => {
                if (data['code'] == 200) {
                    this.isLoading = false;
                    let listData = data['data']['result'];;
                    listData.forEach(element => {
                        let obj = {
                            label: this.commonService.domDirectionLTR ? element.group_name_en : element.group_name_ar,
                            value: element.group_id,
                            // isChecked: false
                        }
                        this.registrationCheckbox.push(obj);
                    });
                    this.selectedValueGet().then((data: any) => {
                        this.registrationCheckbox = data;
                    })


                } else {
                    this.isLoading = false;
                }
            }, error => {
                this.isLoading = false;
            })
        )
    }


    selectCheckbox(item, i, event) {
        event.preventDefault();
        event.stopPropagation();
        item.isChecked = event.detail.checked;
    }

    closeModal() {
        this.modalService.dismissModal();
    }



    upload(files, fieldname) {
        files.preventDefault();
        files.stopPropagation();

        this.files = files['target']['files'][0].name;
        if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {
            const max_size = 2097152;
            if (files['target']['files'][0].size > max_size) {
                this.message =
                    'Maximum size allowed is ' + max_size / 1048576 + 'Mb';
                this.files = null;
                this.fileToUpload = null;
                files.target.value = null;
                this.toastService.displayToast(this.commonService.domDirectionLTR ? this.message : 'الحجم الأقصى المسموح به هو 2 ميغا بايت', null, 'warning');
                return false;
            }

            if (fieldname == 'company-cr-number') {
                this.isCompanyCRNoFile = false;
                setTimeout(() => {
                    const setTooltipWidth = this.document.getElementsByClassName(
                        'company-cr-no-tooltip',
                    ) as HTMLCollectionOf<HTMLElement>;
                    if (this.companyCrNoElem) {
                        const companyCrDivWidth = this.companyCrNoElem.nativeElement.offsetWidth;
                        if (setTooltipWidth[0]) {
                            setTooltipWidth[0].style.width = companyCrDivWidth + "px";
                        }
                    }
                }, 500);
            }
            if (fieldname == 'vat-certificate-no') {
                this.isVatCertificateNoFile = false;
                setTimeout(() => {
                    const setTooltipWidth = this.document.getElementsByClassName(
                        'vat-certificate-no-tooltip',
                    ) as HTMLCollectionOf<HTMLElement>;
                    if (this.vatCertificateNoElem) {
                        const companyVatDivWidth = this.vatCertificateNoElem.nativeElement.offsetWidth;
                        if (setTooltipWidth[0]) {
                            setTooltipWidth[0].style.width = companyVatDivWidth + "px";
                        }
                    }
                }, 500);
            }
            if (fieldname == 'national-address-file') {
                this.isNationalAddressFile = false;
                setTimeout(() => {
                    const setTooltipWidth = this.document.getElementsByClassName(
                        'national-address-file-tooltip',
                    ) as HTMLCollectionOf<HTMLElement>;
                    if (this.nationalAddressFileElem) {
                        const companyVatDivWidth = this.nationalAddressFileElem.nativeElement.offsetWidth;
                        if (setTooltipWidth[0]) {
                            setTooltipWidth[0].style.width = companyVatDivWidth + "px";
                        }
                    }
                }, 500);
            }

            if (fieldname == 'company-cr-number' || fieldname == 'vat-certificate-no' || fieldname == 'national-address-file') {

                let extention = files['target']['files'][0].name.toLowerCase()

                if ((extention).includes(".pdf") || (extention).includes("application/pdf") || (extention).includes(".docx") || (extention).includes("application/docx")) {
                    this.message = null;
                    if (fieldname == 'company-cr-number') {
                        this.companyCRNoFile = files['target']['files'][0];
                        this.companyCRNoName = files['target']['files'][0].name;
                        this.registerFromGroup.patchValue({
                            companyCRNoName: this.companyCRNoName
                        })
                    } else if (fieldname == 'national-address-file') {
                        this.nationalAddressNoFile = files['target']['files'][0];
                        this.nationalAddressFile = files['target']['files'][0].name;
                        this.registerFromGroup.patchValue({
                            nationalAddressFile: this.nationalAddressFile
                        })
                    }
                    else {
                        this.vatCertificateNoFile = files['target']['files'][0];
                        this.vatCertificateNoName = files['target']['files'][0].name;
                        this.registerFromGroup.patchValue({
                            vatCertificateNoName: this.vatCertificateNoName
                        })

                    }
                }
                else {
                    this.message = 'Only PDF and DOCX files are allowed';
                    this.fileToUpload = null
                    this.files = null;
                    files.target.value = null;
                    this.toastService.displayToast(this.commonService.domDirectionLTR ? 'Only PDF and DOCX files are allowed' : 'يسمح فقط بملفات PDF و docX', null, 'warning');
                    return false;
                }

            }
        }
        files.target.value = '';
    }


}
