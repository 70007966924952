<ion-header class="mobile-visibility" *ngIf="!getPlatformService.isDesktop">
    <ion-toolbar mode="md">
        <div class="title-block">
            <img src="assets/icon/leftArrow.svg" class="back-icon" alt="Juman" (click)="goBack()">
            <ion-title>{{'otherMenuComponent.termsOfUse' | translate}}</ion-title>
        </div>
    </ion-toolbar>
</ion-header>
<div class="home-page app-header" *ngIf="!fromRegisters && getPlatformService.isDesktop">
    <app-header></app-header>
</div>
<div class="add-review-container" *ngIf="fromRegisters && getPlatformService.isDesktop">
    <div class="modal-close text-right" (click)="goBack()">
        <img src="assets/icon/close.svg" alt="Close" height="100%" width="100%" alt="juman" />
    </div>
    <div class="add-review-header">
        {{'otherMenuComponent.termsOfUse' | translate}}
    </div>
</div>
<ion-content>
    <div class="min-height-content" [ngClass]="!fromRegisters ? 'detail-page' : null">
        <div class="cms-wrapper">
            <div (click)="elementClicked($event)"
                *ngIf="pageData && pageData.content_en && commonService.domDirectionLTR"
                [innerHTML]="pageData.content_en | safe :'html'"></div>
            <div (click)="elementClicked($event)"
                *ngIf="pageData && pageData.content_ar && !commonService.domDirectionLTR"
                [innerHTML]="pageData.content_ar | safe :'html'"></div>
        </div>
    </div>

    <div class="wrapper-block home-page" *ngIf="!fromRegisters && getPlatformService.isDesktop">
        <div class="app-footer">
            <app-footer></app-footer>
        </div>
    </div>
</ion-content>